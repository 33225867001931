import React, { Component } from 'react';

import {
    Row, Col
} from "reactstrap";

import LoadingPage from "../../../components/LoadingPage";

import CartItem from "../../Cart/CartItem";

import apiMyAccount from "../../../api/MyAccount";
import apiResult from "../../../api/Result";


import utils from "../../../utils";
import moment from "moment";
import { ResultKenoCard, ResultMax3DCard, ResultPower655Card, ResultMega645Card ,ResultDienToan,Result6x36, ResultThantai, Result123,
    ResultKtmnCard
} from "../../Results/MainResult";

class CompareResult extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            results: [],
            termSelected: "",
        }
        
    }

    async componentDidMount() {
        let search = window.location.search
            .slice(1)
            .split('&')
            .map(p => p.split('='))
            .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});
        this.ticketId = search.id;
        if (!utils.isEmpty(this.ticketId)) {
            apiMyAccount.getTicketById(this.ticketId,async (err, result) => {
                if (err) {

                } else {
                    this.setState({
                        data: result, termSelected: result.termArray[0]
                    });
                    let params = result;
                    let termArray = params.termArray;
                    let productId = params.productId;
                    this.propductId = productId;
                    if(productId==14){
                        let [err,result]= await apiResult.getResultKtmn(moment( termArray[0],'DD/MM/YYYY').format("DD-MM-YYYY"))
                            if (err) {
                              alert(err)
                            } else {
                                console.log('result',result)
                                this.setState({results: result}, () => {
                                    this.setState({isLoaded: true});
                                });
                            }
                            return;
                        
                    }
                    if(productId<7)
                        termArray.sort();
                    apiResult.getResult(productId, [termArray[0], termArray[termArray.length - 1]], (err, result) => {
                        if (err) {

                        } else {
                            this.setState({ results: result }, () => {
                                this.setState({ isLoaded: true });
                            });
                        }
                    });
                }
            })
        }
    }

    getMoneyReward() {
        let totalMoney = 0;
        this.state.data.value.forEach(dt => {
            let winningNumber = dt.winningNumber;
            if (!utils.isEmpty(winningNumber)) {
                Object.keys(winningNumber).forEach(key => {
                    totalMoney += winningNumber[key].profit;
                });
            }
        });
        return totalMoney;
    }
    getWinNumber=()=>{
        let res=[];
        this.state.data.value.forEach(dt => {
            let winningNumber = dt.winningNumber;
            if (!utils.isEmpty(winningNumber)) {
                Object.keys(winningNumber).forEach(key => {
                    res.push( winningNumber[key].number)
                });
            }
        });
        return res;
    }
    renderResultCard(result) {
        switch (this.propductId) {
            case 1:
                return <ResultMega645Card result={result} />;
            case 2:
                return <ResultPower655Card result={result} />;
            case 3:
                return <ResultMax3DCard result={result} />;
            case 4:
            case 5:
                return <ResultMax3DCard result={result} />;
            case 6:
                return <ResultKenoCard result={result} />;
            case 8:
            case 7:
            return <ResultDienToan result={result} showUp={this.getWinNumber()} productId={this.propductId} />;
            case 9:
                return <Result6x36  result={result}/>;
            case 10:
                return <ResultThantai  result={result}/>;
                case 11:
                    return <Result123  result={result}/>;
                    case 14:
                        return <ResultKtmnCard dataResult={result} />;
            default:
                break;
        }
    }

    renderResult(result) {
        return (
            <Row className="p-2" style={{ borderRadius: "5px", border: "solid 1px lightblue" }}>
                <Col>
                    <Row>
                        <span className="font-weight-bold">Kết quả quay số mở thưởng kỳ:&nbsp;</span>
                        <span>#{this.state.termSelected}</span>
                    </Row>
                    <Row className="mt-2 pb-1">
                        {
                            result ?
                                this.renderResultCard(result)
                                :
                                <span>Kỳ quay số mở thưởng chưa được diễn ra</span>
                        }
                    </Row>
                </Col>
            </Row>
        );
    }

    render() {
        if (!this.state.isLoaded) {
            return <LoadingPage />
        }
        let result=null;
        if(this.propductId==14)
            result=this.state.results.length>0?this.state.results:null;
        else
        result = this.state.results.find(
          (dt) =>
            dt.id === this.state.termSelected ||
            dt.termDate == this.state.termSelected
        );
        let totalReward = this.getMoneyReward() * 1000;
        return (
            <div className="bootstrap-reset">
                <Row className="p-2">
                    <Col>
                        {
                            this.renderResult(result)
                        }
                        <Row className="w-100">
                            <CartItem
                                data={this.state.data}
                                noTrash={true}
                                noDelelte
                                onSelectTerm={(termSelected) => this.setState({ termSelected: termSelected })}
                                termSelected={this.state.termSelected}
                                result={[1, 2, 6, 9,10].includes(this.propductId) ? result : null}
                            />
                        </Row>
                        <Row className="p-2" style={{ borderRadius: "5px", border: "solid 1px lightblue" }}>
                            <Col>
                                <Row>
                                    <span className="font-weight-bold">Tổng thưởng:&nbsp;</span>
                                    <span className="text-danger">{totalReward.getMoneyFormat()} đ</span>
                                </Row>
                                {
                                    result &&
                                    <Row>
                                        {
                                            totalReward > 0 ? "Chúc mừng bạn đã trúng thưởng ^^" : "Một chút nữa thôi là trúng rồi >_<"
                                        }
                                    </Row>
                                }
                            </Col>

                        </Row>

                    </Col>
                </Row>
            </div>
        );
    }
}

export default CompareResult;
