import React, {Component} from 'react';

import {
    Row, Col
} from "reactstrap";

import LoadingPage from "../../../components/LoadingPage";

import apiMyAccount from "../../../api/MyAccount";
import utils from "../../../utils";

/* eslint eqeqeq: "off" */

const fluctuationTypeId = {
    "1": "Mua vé",
    "2": "Rút tiền tại quầy",
    "3": "VNPAY",
    "4": "Nạp chuyển khoản", // Chỉ có đã hoàn thành
    "5": "Nạp tại cửa hàng",
    "6": "Trúng thưởng",
    "7":"Hoàn vé",
    "8": "Nạp chuyển khoản", // chỉ có bị hủy hoặc đang xử lý,
    "9": "Rút tiền về tài khoản ngân hàng",
    "10": "Rút tiền về ví Momo",
    "41":"Nạp tiền Momo",
    "11":"Nạp tiền Momo",
      "13":"Rút về ví tài khoản",
    "51": "Nạp qua thẻ ATM"
};

class PaymentDetail extends Component {

    constructor(props) {
        super(props);
        this.paymentId = null;
        this.state = {
            "createdDate": "",
            "amount": 0,
            "status": "",
            "fluctuationTypeId": "",
            "image": "",
            "id": 0,
            "isLoaded": false
        }
    }

    componentDidMount() {
        let search = window.location.search
            .slice(1)
            .split('&')
            .map(p => p.split('='))
            .reduce((obj, [key, value]) => ({...obj, [key]: value}), {});
        this.paymentId = search.id;
        this.fluctuationTypeId = search.fluctuationTypeId;

        let query = {};
        if (!utils.isEmpty(this.paymentId) && !utils.isEmpty(this.fluctuationTypeId)) {
            if (["2", "9", "10"].includes(this.fluctuationTypeId.toString())) {
                query = {requestId: this.paymentId};
            } else {
                query = {id: this.paymentId};
            }
        } else {
            window.location.replace("/payment-history");
            return;
        }

        apiMyAccount.getTransactionById(query, (err, result) => {
            if (err) {

            } else {
                this.setState({
                    ...result, isLoaded: true
                });
            }
        })
    }

    statusText = (status) => {
        switch (status) {
            case "0":
                return "Đang chờ";
            case "1":
                return "Thành công";
            default:
                return "Đã hủy";
        }
    };

    render() {

        if (!this.state.isLoaded) {
            return <LoadingPage/>
        }

        return (
            <div className="bootstrap-reset">
                <Row className="p-3">
                    <Col>
                        <Row>
                            <span className="font-weight-bold">Thời gian:</span>&nbsp;
                            <span>{this.state.createdDate}</span>
                        </Row>
                        <Row>
                            <span className="font-weight-bold">Số tiền:&nbsp;</span>
                            <span>{(this.state.amount * 1000).getMoneyFormat()} ₫</span>
                        </Row>
                        <Row>
                            <span className="font-weight-bold">Nguồn:&nbsp;</span>
                            <span>{fluctuationTypeId[this.state.fluctuationTypeId]}</span>
                        </Row>
                        <Row>
                            <span className="font-weight-bold">Trạng thái:&nbsp;</span>
                            <span>{this.statusText(this.state.status)}</span>
                        </Row>
                        <Row>
                            {/* <span className="font-weight-bold">Hóa đơn:&nbsp;</span> */}
                            {/*{*/}
                            {/*    (this.state.fluctuationTypeId == 3 || this.state.fluctuationTypeId == 6) &&*/}
                            {/*    "Không có hóa đơn để hiển thị"*/}
                            {/*}*/}
                        </Row>
                        <Row className="mt-3">
                            {
                                (!["1", "3", "6"].includes(this.state.fluctuationTypeId)) ?
                                <img
                                    // src={utils.safeUrlImage(this.state.image)}
                                    src={"https://iloto.com.vn/img/logo/logo-full.png"}
                                    alt={"Hóa đơn 1"}
                                    style={{width: "100%", height: "100%"}}
                                />
                                :
                                    <img
                                        src={"https://iloto.com.vn/img/logo/logo-full.png"}
                                        alt={"Hóa đơn"}
                                        style={{width: "100%", height: "100%"}}
                                    />
                            }
                        </Row>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default PaymentDetail;
