import {isEmpty} from "./index";

/* eslint-disable */

Array.copyState = function (state) {
    return JSON.parse(JSON.stringify(state));
};

Number.prototype.getMoneyFormat = function(n, x) {
    let re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')';
    return this.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$&.');
};

Array.prototype.numEmpty = function () {
    let num = 0;
    this.forEach(dt => {
        if(isEmpty(dt))
            num++;
    });
    return num;
};

Array.prototype.sortBy = function(p) {
    return this.slice(0).sort(function(a,b) {
        return (a[p] > b[p]) ? 1 : (a[p] < b[p]) ? -1 : 0;
    });
};

Array.prototype.groupBy = function(keyGetter) {
    const map = new Map();
    this.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
};

/* eslint-enable */
