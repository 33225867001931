import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {
    Row, Col
} from "reactstrap";
import utils from "../../utils";
import {Phone, Mail, Facebook} from "react-feather";
import {Helmet} from "react-helmet";
import colors from "../../utils/colors";
const logo = require("../../assets/images/logo/dien-toan.png");
const content = `<!DOCTYPE html><html><head><meta name="viewport" content="width=device-width, initial-scale=1.0"></head><body>
<div class="main-body bg-white">
<div style="padding: 10px;">
<div style="font-weight: bold; text-align: center;">Hướng dẫn cách chơi xổ số kiến thiết Miền Bắc</div>
 <div style="font-weight: bold;">I. Giới thiệu xổ số kiến thiết Miền Bắc</div>
  <div><span style="font-size: 2.3rem;"></span>- Xổ số kiến thiết Miền Bắc là loại hình xổ số có in sẵn trước giá vé, các chữ số, chữ cái để khách hàng lựa chọn tham gia dự thưởng. Số lượng các chữ số, chữ cái được giới hạn trong phạm vi vé số phát hành và việc xác định kết quả trúng thưởng được thực hiện sau thời điểm phát hành vé số.</div> <div><span style="font-size: 2.3rem;"></span>- Giờ đặt vé trên ứng dụng: 0h00 – 16h00 và 19h00 – 24h00 (Đóng hệ thống từ 16h00 – 19h00)</div> 
  <div style="font-weight: bold;">II. Lịch mở thưởng </div>
   <div><span style="font-size: 2.3rem;"></span> •	Tất cả các ngày trong tuần từ thứ 2 đến chủ nhật.</div> 
   <div><span style="font-size: 2.3rem;"></span> •	Thời gian quay số: (18h15 – 18h30) các ngày trong tuần.</div> 
   <div style="font-weight: bold;">III. Hướng dẫn cách chơi </div> <div style="font-weight: bold;">1. Cách chơi</div> 
   <div><span style="font-size: 2.3rem;"></span>Người chơi có thể chọn 1 hoặc nhiều bộ số, trong đó:</div>
    <div><span style="font-size: 2.3rem;"></span> -	Giá trị cho một (01) bộ số dự thưởng là 10.000 đồng (mười nghìn đồng).</div> 
    <div><span style="font-size: 2.3rem;"></span> -	Người tham gia dự thưởng có thể chọn tối đa 6 bộ số để dự thưởng.</div> 
    <div><span style="font-size: 2.3rem;"></span> -	Trường hợp người tham gia dự thưởng chọn một bộ số để tham gia dự thưởng nhiều lần trong cùng một (01) kỳ quay số mở thưởng và/hoặc tham gia dự thưởng nhiều lần trong nhiều kỳ quay số mở thưởng và/hoặc chọn nhiều bộ số để tham gia dự thưởng thì tổng giá trị tham gia dự thưởng là bội số của 10.000 đồng.</div> <div style="font-weight: bold;">2. Cách thức tham gia dự thưởng</div> <div><span style="font-size: 2.3rem;"></span>- Người tham gia dự thưởng chọn kỳ quay số mở thưởng (Ngày quay số mở thưởng)</div> <div><span style="font-size: 2.3rem;"></span>- Người tham gia dự thưởng lựa chọn bộ số tham gia dự thưởng. </div> <div><span style="font-size: 2.3rem;"></span>- Người tham gia dự thưởng trực tiếp lựa chọn các bộ số tham gia dự thưởng, được quyền chọn tối đa 06 bộ số trên một đơn hàng để tham gia dự thưởng. </div> <div><span style="font-size: 2.3rem;"></span>- Người tham gia dự thưởng thanh toán tiền mua vé. </div> <div><span style="font-size: 2.3rem;"></span>- Người tham gia dự thưởng nhận vé dự thưởng dưới dạng chứng chỉ. Vé dự thưởng bao gồm những thông tin sau:</div> <div style="margin-left: 1rem;">➤ Tên sản phẩm. </div> 
    <div style="margin-left: 1rem;">➤ Bộ số dự thưởng. </div>
     <div style="margin-left: 1rem;">➤ Ngày, giờ phát hành vé. </div>
      <div style="margin-left: 1rem;">➤ Giá trị tham gia dự thưởng. </div> 
      <div style="margin-left: 1rem;">➤ Ngày, kỳ quay số mở thưởng. </div> 
      <div style="margin-left: 1rem;">➤ Số ký hiệu, bảng mã nhận dạng và các tính năng bảo mật khác để chống làm giả vé dự thưởng.  </div> 
      <div style="margin-left: 1rem;">➤ Người tham gia dự thưởng có trách nhiệm kiểm tra các thông tin trên vé tham gia dự thưởng. </div> 
      <div style="margin-left: 1rem;">➤ Người tham gia dự thưởng không có quyền hủy vé tham gia dự thưởng đã mua. </div>
       <div style="font-weight: bold;">IV. Cơ cấu giải thưởng </div> <div>- Cơ cấu giải thưởng cụ thể như sau: </div>
        <div><span style="font-size: 2.3rem;"></span>•	Giải đặc biệt: Có 6 giải mỗi kỳ quay. Mỗi giải có trị giá là 500.000.000 vnđ Giải này gồm có 5 số và 6 ký hiệu. </div> <div><span style="font-size: 2.3rem;"></span>•	Giải phụ đặc biệt: có 9 giải. Mỗi giải có trị giá 25.000.000 vnđ. Căn cứ tính giải là vé số trùng cả 5 số so với giải đặc biệt  nhưng khác ký hiệu. </div> 
        <div><span style="font-size: 2.3rem;"></span>•	Giải khuyến khích: Có 15.000 giải với trị giá là 40.000 vnđ mỗi giải. Giải này được tính cho những tấm vé số có 2 số cuối trùng với 2 số cuối cùng của giải đặc biệt. </div> <div><span style="font-size: 2.3rem;"></span>•	Giải Nhất: Có 15 giải nhất mỗi kỳ, mỗi giải trị giá 10.000.000 vnđ.  </div> <div><span style="font-size: 2.3rem;"></span>•	Giải Nhì: Có 30 giải nhì với trị giá 5.000.000 vnđ mỗi giải.</div> <div><span style="font-size: 2.3rem;"></span>•	Giải Ba: Có 90 giải ba với trị giá số tiền là 1.000.000 vnđ mỗi giải. </div> <div><span style="font-size: 2.3rem;"></span>•	Giải Tư: Có tổng số 600 giải mỗi giải nhận được 400.000 vnđ.</div> <div><span style="font-size: 2.3rem;"></span>•	Giải 5: Mỗi kỳ có 900 giải năm với trị giá là 200.000 vnđ mỗi giải.</div> 
        <div><span style="font-size: 2.3rem;"></span>•	Giải 6: Có tới 4.500 giải với trị giá 100.000 vnđ mỗi giải. </div> <div><span style="font-size: 2.3rem;"></span>•	Giải 7: Có tới 60.000 giải với trị giá 40.000 vnđ mỗi giải. </div> 
        <div><img src="https://www.vesomienbac.vn/_nuxt/img/cocaugiaithuongxsktmb.4e4db8c.jpg" alt="ocaugiaithuongxsdt" class="w-100 img-fluid"></div> 
        <div class="text-center"></div></div></div></body></html>`

class KIENTHIET extends Component {
    constructor(props)
    {
        super(props);
        this.state={seo:{}}
    }
    async componentDidMount(){            
    }
    render() {
        return (
            <div className='p2'>
                <div dangerouslySetInnerHTML={{__html: content}} />
        
            </div>
        );
    }
}

export default KIENTHIET;
