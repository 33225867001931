import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {
    Row, Col
} from "reactstrap";
import utils from "../../utils";
import {Phone, Mail, Facebook} from "react-feather";
import {Helmet} from "react-helmet";
import colors from "../../utils/colors";
const logo = {    
  'mega645': require('../../assets/images/logo/mega645.png'),
  'power655': require('../../assets/images/logo/power655.png'),
  'max4d': require('../../assets/images/logo/max4d.png'),
  'max3d': require('../../assets/images/logo/max3d.png'),
  'max3d_plus': require('../../assets/images/logo/max3d_plus.png'),
  'max3d_pro': require('../../assets/images/logo/logomax3dpro.png'),
  'keno': require('../../assets/images/logo/keno.png'),
  '235': require("../../assets/images/logo/dien-toan.png"),
  'kenobao':require('../../assets/images/logo/keno.png'),
  '235': require("../../assets/images/logo/dien-toan.png")
};
const menuCardData = [
  
  {
    productId: 7,
    lottery: "235",
    title:'HƯỚNG DẪN CÁCH CHƠI KIẾN THIẾT',
    title_2:'2 số',
    title_3:'Giá trị tối đa ',
    title_3_1:'x71 lần',
    name:'Hàng ngày - Lô tô 2,3,5 số',
    color: colors.facebookColor,
    link:'/huong-dan/kienthiet'
},
    {
        productId: 7,
        lottery: "235",
        title:'HƯỚNG DẪN CÁCH CHƠI ĐIỆN TOÁN 235 SỐ',
        title_2:'2 số',
        title_3:'Giá trị tối đa ',
        title_3_1:'x71 lần',
        name:'Hàng ngày - Lô tô 2,3,5 số',
        color: colors.facebookColor,
        link:'/huong-dan/235'
    },


    {
        productId: 8,
        lottery: '235',
        title:'HƯỚNG DẪN CÁCH CHƠI ĐIỆN TOÁN 234 CẶP SỐ',
        title_2:'2 cặp số',
        title_3:'Giá trị tối đa ',
        title_3_1:'x15 lần',
        name:'Hàng ngày',
        color: colors.facebookColor,
        link:'/huong-dan/234'
    },
    
    
   
    {
        productId: 9,
        lottery: '235',
        title:'HƯỚNG DẪN CÁCH CHƠI ĐIỆN TOÁN 636',
        title_2:'6x36',
        title_3:'Giá trị tối đa ',
        title_3_1:'6 tỷ đồng',
        name:'Thứ tư, thứ bảy hàng tuần',
        color: colors.facebookColor,
        link:'/huong-dan/636'
    },
    {
        productId: 10,
        lottery: '235',
        title:'HƯỚNG DẪN CÁCH CHƠI ĐIỆN TOÁN Thần tài',
        title_2:'Thần Tài',       
        title_3:'Giá trị tối đa ',
        title_3_1:'x1220 lần',
        name:'Hàng ngày',
        color: colors.facebookColor,
        link:'/huong-dan/than-tai'
    },
    {
        productId: 11,
        lottery: '235',
        title:'HƯỚNG DẪN CÁCH CHƠI ĐIỆN TOÁN 123',
        title_2:'123',
        title_3:'Giá trị tối đa ',
        title_3_1:'x40000 lần',       
        name:'Hàng ngày',
        color: colors.facebookColor,
        link:'/huong-dan/123'
    },
    {
      productId: 6,
      lottery: "keno",
      title:'HƯỚNG DẪN CÁCH CHƠI keno',
      title_2:'2 số',
      title_3:'Giá trị tối đa ',
      title_3_1:'x71 lần',
      name:'Hàng ngày - Lô tô 2,3,5 số',
      color: colors.facebookColor,
      link:'/huong-dan/keno'
  },
    {
      productId: 1,
      lottery: "mega645",
      title:'HƯỚNG DẪN CÁCH CHƠI MEGA 645',
      title_2:'2 số',
      title_3:'Giá trị tối đa ',
      title_3_1:'x71 lần',
      name:'Hàng ngày - Lô tô 2,3,5 số',
      color: colors.facebookColor,
      link:'/huong-dan/mega'
  },
    {
      productId: 2,
      lottery: "power655",
      title:'HƯỚNG DẪN CÁCH CHƠI POWER 655',
      title_2:'2 số',
      title_3:'Giá trị tối đa ',
      title_3_1:'x71 lần',
      name:'Hàng ngày - Lô tô 2,3,5 số',
      color: colors.facebookColor,
      link:'/huong-dan/power'
  },
    {
      productId: 4,
      lottery: "max3d",
      title:'HƯỚNG DẪN CÁCH CHƠI MAX 3D',
      title_2:'2 số',
      title_3:'Giá trị tối đa ',
      title_3_1:'x71 lần',
      name:'Hàng ngày - Lô tô 2,3,5 số',
      color: colors.facebookColor,
      link:'/huong-dan/max3d'
  },  {
    productId: 4,
    lottery: "max3d_plus",
    title:'HƯỚNG DẪN CÁCH CHƠI MAX 3D Plus',
    title_2:'2 số',
    title_3:'Giá trị tối đa ',
    title_3_1:'x71 lần',
    name:'Hàng ngày - Lô tô 2,3,5 số',
    color: colors.facebookColor,
    link:'/huong-dan/max3dplus'
  },
  {
    productId: 4,
    lottery: "max3d_pro",
    title:'HƯỚNG DẪN CÁCH CHƠI MAX 3D Pro',
    title_2:'2 số',
    title_3:'Giá trị tối đa ',
    title_3_1:'x71 lần',
    name:'Hàng ngày - Lô tô 2,3,5 số',
    color: colors.facebookColor,
    link:'/huong-dan/max3dpro'
  },
];

class MenuCard extends React.Component {
    constructor(props) {
      super(props);
     
    }
    render() {
      return (
        <Link
          to={this.props.link}
          // className="link-unset"
        >
          <div className="menu-card link-eunset cursor-pointer">
            <div className="menu-card__img py-1">
              <img
                src={logo[this.props.lottery]}
                className="p-2"
                style={{ width: "100%" }}
                alt="icon"
              />              
            </div>
            <div className="menu-card__content py-1">
         
              <div
                style={{ color: this.props.color, fontSize: "1.2rem" }}
                className="font-weight-bold"
              >
                {this.props.title}
              </div>

             
            </div>
            <div
              className="menu-card__caret"
              style={{ backgroundColor: this.props.color }}
            >
              {">"}
            </div>
          </div>
        </Link>
      );
    }
  }
class Guides extends Component {
    constructor(props)
    {
        super(props);
        this.state={seo:{}}
    }
    async componentDidMount(){            
    }
    render() {
        return (
            <div className='home-page' style={{overflow:'scroll'}}>
            { 
                menuCardData.map(e=>(
                    <MenuCard
                        title={e.title}
                        lottery={e.lottery}
                        productId={e.productId}
                        title_2={e.title_2}
                        title_3={e.title_3}
                        title_3_1={e.title_3_1}
                        
                        color={e.color}
                        link={e.link}
                    />
                ))
            }
        
            </div>
        );
    }
}

export default Guides;
