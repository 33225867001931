import React from "react";
import {Modal, ModalBody, ModalHeader, ModalFooter, Row, Col, Button} from "reactstrap";
import utils from "../../utils";
import NumberCircle from "./NumberCircle";

import {connect} from "react-redux";
import {lotteryChange} from "../../redux/actions/lotterySelectorAction";

import {getRandomValue, checkValidValue} from './LotterySelector';

import {
    XCircle, CheckCircle
} from "react-feather"

/* eslint eqeqeq: "off" */

/**/
class TableSelector extends React.Component {

    // shouldComponentUpdate(nextProps: Readonly<P>, nextState: Readonly<S>, nextContext: any): boolean {
    //     return (
    //         !utils.deepCompareObj(this.props.selected, nextProps.selected)  ||
    //         this.state.interactionsComplete !== nextState.interactionsComplete
    //     );
    // }

    handleSelect(number) {
        this.props.onChange(utils.formatValue(number));
    }


    render() {
        return (
            <div className="d-flex flex-wrap justify-content-center">
                {
                    this.props.productId===8?
                    Array(this.props.max+1).fill(0).map((d, i) => (                       
                        <NumberCircle
                            key={i}
                            title={utils.formatValue(i)}
                            isBackground={this.props.selected.number.includes(utils.formatValue(i))}
                            className="mt-1 mr-1"
                            onClick={this.handleSelect.bind(this, i)}
                        />                       
                    )):
                    Array(this.props.max).fill(0).map((d, i) => (
                        
                        <NumberCircle
                            key={i}
                            title={utils.formatValue(i + 1)}
                            isBackground={this.props.selected.number.includes(utils.formatValue(i + 1))}
                            className="mt-1 mr-1"
                            onClick={this.handleSelect.bind(this, i + 1)}
                        />
                    ))
                }
            </div>
        );
    }
}
class TableSelector1 extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            interactionsComplete: false,
            selected: this.props.selected,
            number:this.fillNumber(this.props.selected.number),
            div: this.fillNumber(this.props.selected.number),           
        };
        console.log(this.props.selected.number);
    }

    getLength=()=>{
        switch(this.props.productOptionId){
            case 83:
                return 2;
            case 84: 
                return 3;
            case 85:
                return 5;
            case 90:
                return 4;
        }
    }

    fillNumber(number) {

        let lengthValue = this.getLength();
        if (number.length>=0) {
            return number;
        } else {
            var s=Array(lengthValue).fill(null);
            return s;
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.selected !== this.props.selected) {
            this.setState({
                selected: nextProps.selected,              
            });
        }
    }

    handleSelect(number, col) {
        let oldArr=this.state.number;
        oldArr[col]=number.toString();

       this.setState({number:oldArr})
       if(oldArr.numEmpty()===0){
       this.props.onChange(oldArr);
        }
    }

    render() {
        let div = this.state.div;
    
        const length=this.getLength();
        return (
            <React.Fragment>
                <Row>
                    <Col className="col-8">
                        {
                            Array(10).fill(0).map((l, row) => (
                                <div className="d-flex justify-content-center" key={`row-${row}`}>
                                    {
                                        Array(length).fill(0).map((l, col) => (
                                            <div key={row.toString() + '-' + col.toString()}>
                                                {
                                                    <NumberCircle
                                                        title={row}
                                                        isBackground={this.props.selected.number[col]?.toString()===row.toString()}
                                                        className="mr-1"
                                                        onClick={this.handleSelect.bind(this, row, col)}
                                                    />
                                                }
                                            </div>
                                        ))
                                    }                                                                        
                                </div>
                            ))
                        }
                    </Col>
                    <Col className="col-4">
                        {
                            this.props.priceArray.map((l, i) => (
                                <div key={i.toString()}>
                                    <NumberCircle
                                        price={l.id + "K"}
                                        isBackground={this.props.selected.price == l.id}
                                        onClick={() => this.props.onChangePrice(l.id)}
                                    />
                                </div>
                            ))
                        }
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

class TableSelectorDT123 extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            interactionsComplete: false,
            selected: this.props.selected,
            selectP:this.formatSelect(this.props.selected),
            number:this.fillNumber(this.props.selected.number),
            div: this.fillNumber(this.props.selected.number),           
        };
        console.log(this.props.selected.number);
    }

    getLength=()=>{
        switch(this.props.productOptionId){
            case 83:
                return 2;
            case 84: 
                return 3;
            case 85:
                return 5;
            case 90:
                return 4;
        }
    }

    fillNumber(number) {

        let lengthValue = this.getLength();
        if (number.length>=0) {
            return number;
        } else {
            var s=Array(lengthValue).fill(null);
            return s;
        }
    }
    formatSelect=(selected)=>{
        let selectP={};
        Object.assign(selectP,selected);
        let numArr=[]; 

        for(let e of selectP.number){
           numArr.push(...e.split(''));
        }
        selectP.number=numArr; 
        return selectP;
    }
    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.selected !== this.props.selected) {
            let selectP=this.formatSelect(nextProps.selected);
             

            this.setState({
                selected: nextProps.selected,    
                selectP:selectP          
            });
        }
    }

    handleSelect(number, col) {
        let oldArr=this.state.selectP;
        oldArr.number[col]=number.toString();

       this.setState({selectP:oldArr})
       let isComplete=true;
       for(let e of oldArr.number)
       {
           if(e===undefined)
            isComplete=false;
       }
       if(oldArr.number.length<6)
        isComplete=false;
       if(isComplete){
           let selectAr={};
           Object.assign(selectAr,oldArr);
           selectAr.number=[];
           selectAr.number[0]=oldArr.number[0];
           selectAr.number[1]=oldArr.number[1]+oldArr.number[2];
           selectAr.number[2]=oldArr.number[3]+oldArr.number[4]+oldArr.number[5];
       this.props.onChange(selectAr.number);
        }
    }

    render() {
        let div = this.state.div;
    
        const length=this.getLength();
        return (
            <React.Fragment>
                <Row>
                    <Col className="col-8">
                        {
                            Array(10).fill(0).map((l, row) => (
                                <div className="d-flex justify-content-center" key={`row-${row}`}>
                                    {
                                        <>                                      
                                            <div key={row.toString() + '-0'}>
                                                {
                                                    <NumberCircle
                                                        title={row}
                                                        isBackground={this.state.selectP.number[0]?.toString()===row.toString()}
                                                        className="mr-1"
                                                        onClick={this.handleSelect.bind(this, row, 0)}
                                                    />
                                                }
                                            </div>
                                            <div>&nbsp;&nbsp;</div>
                                            <div key={row.toString() + '-1'}>
                                                {
                                                    Array(2).fill(0).map((l, col) => (
                                                    <NumberCircle
                                                        title={row}
                                                        isBackground={this.state.selectP.number[col+1]?.toString()===row.toString()}
                                                        className="mr-1"
                                                        onClick={this.handleSelect.bind(this, row, col+1)}
                                                    />
                                                    ))
                                                }
                                            </div>
                                            <div>&nbsp;&nbsp;</div>
                                            <div key={row.toString() + '-2'}>
                                                {
                                                    Array(3).fill(0).map((l, col) => (
                                                    <NumberCircle
                                                        title={row}
                                                        isBackground={this.state.selectP.number[col+3]?.toString()===row.toString()}
                                                        className="mr-1"
                                                        onClick={this.handleSelect.bind(this, row, col+3)}
                                                    />
                                                    ))
                                                }
                                            </div>
                                        </>
                                    }                                                                        
                                </div>
                            ))
                        }
                    </Col>
                    <Col className="col-4">
                        {
                            this.props.priceArray.map((l, i) => (
                                <div key={i.toString()}>
                                    <NumberCircle
                                        price={l.id + "K"}
                                        isBackground={this.props.selected.price == l.id}
                                        onClick={() => this.props.onChangePrice(l.id)}
                                    />
                                </div>
                            ))
                        }
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

class TableSelectorMax3D extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            interactionsComplete: false,
            selected: this.props.selected,
            div: this.splitNumber(this.props.selected.number[0]),
            div2: this.splitNumber(this.props.selected.number[1]),
        };
    }

    splitNumber(number) {

        let lengthValue = 3;
        if (number !== undefined) {
            return number.split('');
        } else {
            return Array(lengthValue).fill(null);
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.selected !== this.props.selected) {
            this.setState({
                selected: nextProps.selected,
                div: this.splitNumber(nextProps.selected.number[0]),
                div2: this.splitNumber(nextProps.selected.number[1]),
            });
        }
    }

    handleSelect(number, i, plus) {
        let div = utils.copyState(this.state.div);
        let div2 = utils.copyState(this.state.div2);
        if (plus) {
            div2[i] = number.toString();

        } else {
            div[i] = number.toString();
        }
        // if(!checkValidValue(num, this.props.productId, this.props.productOptionId)) return;
        this.setState({div: div, div2: div2});
        if (this.props.productId === 4) { // Only max 3D
            if (div.numEmpty() === 0) { // OK
                let num = (div.toString().replace(/[^0-9]/g, ''));
                this.props.onChange([num]);
            }
        } else { // max3d+
            if (div.numEmpty() === 0 && div2.numEmpty() === 0) { // OK
                let num = (div.toString().replace(/[^0-9]/g, ''));
                let num2 = (div2.toString().replace(/[^0-9]/g, ''));

                this.props.onChange([num, num2]);
            }
        }

    }

    render() {
        let div = this.state.div;
        let div2 = this.state.div2;

        return (
            <React.Fragment>
                <Row>
                    <Col className="col-8">
                        {
                            Array(10).fill(0).map((l, row) => (
                                <div className="d-flex justify-content-center" key={`row-${row}`}>
                                    {
                                        Array(3).fill(0).map((l, col) => (
                                            <div key={row.toString() + '-' + col.toString()}>
                                                {
                                                    <NumberCircle
                                                        title={row}
                                                        isBackground={div[col] === row.toString()}
                                                        className="mr-1"
                                                        onClick={this.handleSelect.bind(this, row, col, false)}
                                                    />
                                                }
                                            </div>
                                        ))
                                    }
                                    {
                                        (this.props.productId === 5 ||this.props.productId === 12) &&
                                        <div style={{width: "10px"}}>

                                        </div>
                                    }
                                    {
                                        (this.props.productId === 5 ||this.props.productId === 12) &&
                                        Array(3).fill(0).map((l, col) => (
                                            <div key={row.toString() + '-' + col.toString()}>
                                                {
                                                    <NumberCircle
                                                        title={row}
                                                        isBackground={div2[col] === row.toString()}
                                                        className="mr-1"
                                                        onClick={this.handleSelect.bind(this, row, col, true)}
                                                    />
                                                }
                                            </div>
                                        ))
                                    }
                                </div>
                            ))
                        }
                    </Col>
                    <Col className="col-4">
                        {
                            this.props.priceArray.map((l, i) => (
                                <div key={i.toString()}>
                                    <NumberCircle
                                        price={l.id + "K"}
                                        isBackground={this.props.selected.price == l.id}
                                        onClick={() => this.props.onChangePrice(l.id)}
                                    />
                                </div>
                            ))
                        }
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

class TableSelectorMax4D extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            interactionsComplete: false,
            selected: this.props.selected,
            div: this.splitNumber(this.props.selected.number[0]),
        };
    }

    splitNumber(number) {

        let lengthValue = [43, 44].includes(this.props.productOptionId) ? 3 : 4;
        if (number !== undefined) {
            return number.split('');
        } else {
            return Array(lengthValue).fill(undefined);
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.selected !== this.props.selected) {
            this.setState({
                selected: nextProps.selected,
                div: this.splitNumber(nextProps.selected.number[0]),
            });
        }
    }

    handleSelect(number, i) {
        let div = utils.copyState(this.state.div);
        div[i] = number.toString();
        let num = (div.toString().replace(/[^0-9]/g, ''));
        if (!checkValidValue(num, 3, this.props.productOptionId)) {
            return;
        }
        this.setState({div: div});
        if (div.numEmpty() === 0) { // OK
            this.props.onChange([num]);
        }
    }

    render() {
        let div = this.state.div;
        return (
            <React.Fragment>
                <Row>
                    <Col className="col-8">
                        {
                            Array(10).fill(0).map((l, row) => (
                                <div className="d-flex justify-content-center" key={`row-${row}`}>
                                    {
                                        this.props.productOptionId === 43 &&
                                        <NumberCircle
                                            title={'*'}
                                            className="mr-1"
                                        />
                                    }
                                    {
                                        Array([43, 44].includes(this.props.productOptionId) ? 3 : 4).fill(0).map((l, col) => (
                                            <div key={row.toString() + '-' + col.toString()}>
                                                {
                                                    <NumberCircle
                                                        title={row}
                                                        size={32}
                                                        isBackground={div[col] === row.toString()}
                                                        onClick={this.handleSelect.bind(this, row, col)}
                                                        className="mr-1"
                                                    />
                                                }
                                            </div>
                                        ))
                                    }
                                    {
                                        this.props.productOptionId === 44 &&
                                        <NumberCircle
                                            title={'*'}
                                            size={32}
                                        />
                                    }
                                </div>
                            ))
                        }
                    </Col>
                    <Col className="col-4">
                        {
                            this.props.priceArray.map((l, i) => (
                                <div key={i.toString()}>
                                    <NumberCircle
                                        price={l.id + "K"}
                                        isBackground={this.props.selected.price == l.id}
                                        onClick={() => this.props.onChangePrice(l.id)}
                                    />
                                </div>
                            ))
                        }
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

class ModalSelector extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            indexSwiper: 0,
            selected: Array(6).fill(
                {
                    isDone: false,
                    number: [],
                    price: null,
                },
            ),
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.isOpen) {
            let selected = [];
            let value = utils.copyState(nextProps.lotterySelector.value);

            value.forEach(dt => {
                let dat = {
                    number: dt.number,
                    price: dt.price,
                    isDone: dt.number.length === nextProps.lotterySelector.numberSelect,
                };
                selected.push(dat);
            });

            this.setState({
                selected: selected,
            });
        }
        if (nextProps.index !== this.props.index) {
            this.setState({
                indexSwiper: nextProps.index,
            });
        }
    }

    handleChange(i, number) {
        let selected = utils.copyState(this.state.selected);
        let value = utils.copyState(this.props.lotterySelector.value);
        //
        if ([3, 4, 5,12].includes(this.props.lotterySelector.productId)) {
            selected[i].isDone = true;
            selected[i].number = number;
            value[i].number = selected[i].number;
            this.setState({
                selected: selected,
            });
            // this.props.lotteryChange({value: value});
            return;
        }
        if(this.props.lotterySelector.productId==7 || this.props.lotterySelector.productId==10 || this.props.lotterySelector.productId==11){
            selected[i].isDone = true;
            selected[i].number = number;
            value[i].number = selected[i].number;
            this.setState({
                selected: selected,
            });
            // this.props.lotteryChange({value: value});
            return;
        }
        //
        let index = selected[i].number.indexOf(number);
        if (index === -1) {
            if (selected[i].number.length === this.props.lotterySelector.numberSelect) {
                return;
            }
            selected[i].number.push(number);
            if (selected[i].number.length === this.props.lotterySelector.numberSelect) {
                selected[i].isDone = true;
                value[i].number = selected[i].number;
                // this.props.lotteryChange({value: value});
            }
        } else {
            selected[i].isDone = false;
            selected[i].number.splice(index, 1);
        }

        this.setState({
            selected: selected,
        });
    }

    handleRandom(id) {
        let changeValue = [];
        let change123=[];
        if(this.props.lotterySelector.productId!==11)
        for (let i = 0; i < this.props.lotterySelector.numberSelect; i++) {
            while (true) {
                let number = getRandomValue(this.props.lotterySelector.productId, this.props.lotterySelector.productOptionId);
                if (!changeValue.includes(number)) {
                    changeValue.push(number);
                    break;
                }
            }
        }
        else{
            for (let i = 0; i < 6; i++) {
                while (true) {
                    let number = getRandomValue(this.props.lotterySelector.productId, this.props.lotterySelector.productOptionId);
                    if (!changeValue.includes(number)) {
                        change123.push(number);
                        break;
                    }
                }
                changeValue[0]=change123[0];
                changeValue[1]=change123[1]+change123[2];
                changeValue[2]=change123[3]+change123[4]+change123[5];
            }

        }
        let selected = utils.copyState(this.state.selected);
        selected[id].number = changeValue;
        selected[id].isDone = true;

        let value = utils.copyState(this.props.lotterySelector.value);
        if(this.props.lotterySelector.productId<7 || this.props.lotterySelector.productId===9 || this.props.lotterySelector===8)
        changeValue.sort();
        value[id].number = changeValue;
        this.props.lotteryChange({value: value});

        this.setState({
            selected: selected,
        });
    }

    handleClear(id) {
        let selected = utils.copyState(this.state.selected);
        selected[id].number = [];
        selected[id].isDone = false;
        this.setState({
            selected: selected,
        });

        let value = utils.copyState(this.props.lotterySelector.value);
        value[id].number = [];
        this.props.lotteryChange({value: value});
    }

    handleClose() {
        if (!this.state.selected[this.state.indexSwiper].isDone) {
            this.handleClear(this.state.indexSwiper);
        }
        this.props.onClose();
        let selected = utils.copyState(this.state.selected);
        let value = utils.copyState(this.props.lotterySelector.value);
        for (let i = 0; i < 6; i++) {
            if(selected[i])
            if (selected[i].isDone) {
                if(this.props.lotterySelector.productId<7 || this.props.lotterySelector.productId===9 || this.props.lotterySelector.productId===9)
                    selected[i].number.sort();
                value[i].number = selected[i].number;
            }
        }
        this.props.lotteryChange({value: value})
    }

    handleChangePrice(i, price) {
        let value = utils.copyState(this.props.lotterySelector.value);
        value[i].price = parseInt(price);
        this.props.lotteryChange({value: value});
    }

    renderTableSelect=()=>{
        switch(this.props.lotterySelector.productId)
        {
            case 3:
                return <TableSelectorMax4D
                onChange={this.handleChange.bind(this, this.props.index)}
                onChangePrice={this.handleChangePrice.bind(this, this.props.index)}
                selected={this.state.selected[this.props.index]}
                priceArray={this.props.lotterySelector.priceArray}
                productId={this.props.lotterySelector.productId}
                productOptionId={this.props.lotterySelector.productOptionId}
            />
            
            case 4:
            case 5:
            case 12:
                return <TableSelectorMax3D
                onChange={this.handleChange.bind(this, this.props.index)}
                onChangePrice={this.handleChangePrice.bind(this, this.props.index)}
                selected={this.state.selected[this.props.index]}
                priceArray={this.props.lotterySelector.priceArray}
                productId={this.props.lotterySelector.productId}
                productOptionId={this.props.lotterySelector.productOptionId}
            />
            case 7:
                return <TableSelector1                
                onChange={this.handleChange.bind(this, this.props.index)}
                selected={this.state.selected[this.props.index]}                                  
                productId={this.props.lotterySelector.productId}
                onChangePrice={this.handleChangePrice.bind(this, this.props.index)}
                priceArray={this.props.lotterySelector.priceArray}
                productOptionId={this.props.lotterySelector.productOptionId}
                max={this.props.lotterySelector.endNumber > 100 ? 80 : this.props.lotterySelector.endNumber}
            />
           case 10:
               return <TableSelector1                
               onChange={this.handleChange.bind(this, this.props.index)}
               selected={this.state.selected[this.props.index]}                                  
               productId={this.props.lotterySelector.productId}
               onChangePrice={this.handleChangePrice.bind(this, this.props.index)}
               priceArray={this.props.lotterySelector.priceArray}
               productOptionId={this.props.lotterySelector.productOptionId}
               max={this.props.lotterySelector.endNumber > 100 ? 80 : this.props.lotterySelector.endNumber}
           />
           case 11:
               return <TableSelectorDT123              
               onChange={this.handleChange.bind(this, this.props.index)}
               selected={this.state.selected[this.props.index]}                                  
               productId={this.props.lotterySelector.productId}
               onChangePrice={this.handleChangePrice.bind(this, this.props.index)}
               priceArray={this.props.lotterySelector.priceArray}
               productOptionId={this.props.lotterySelector.productOptionId}
               max={this.props.lotterySelector.endNumber > 100 ? 80 : this.props.lotterySelector.endNumber}
               />
        }
        return <TableSelector
        productId={this.props.lotterySelector.productId}
        onChange={this.handleChange.bind(this, this.props.index)}
        selected={this.state.selected[this.props.index]}
        max={this.props.lotterySelector.endNumber > 100 ? 80 : this.props.lotterySelector.endNumber}
        />
    }

    render() {
        return (
            <Modal isOpen={this.props.isOpen} centered toggle={this.handleClose.bind(this)} className="modal-selector">
                <ModalHeader
                    // toggle={this.handleClose.bind(this)}
                    style={this.state.selected[this.props.index].isDone ? {backgroundColor: 'red', color: "white"} : {backgroundColor: 'white', color: "black"}}
                >
                    <span className="mr-4">{String.fromCharCode(this.props.index + 65)}</span>
                    <span>{this.state.selected[this.props.index].isDone ? "ĐÃ ĐỦ BỘ SỐ" : "CHƯA ĐỦ BỘ SỐ"}</span>
                </ModalHeader>
                <ModalBody className="bg-white">
                    {this.renderTableSelect()}
                        {/* this.props.lotterySelector.productId === 3 ?
                            <TableSelectorMax4D
                                onChange={this.handleChange.bind(this, this.props.index)}
                                onChangePrice={this.handleChangePrice.bind(this, this.props.index)}
                                selected={this.state.selected[this.props.index]}
                                priceArray={this.props.lotterySelector.priceArray}
                                productId={this.props.lotterySelector.productId}
                                productOptionId={this.props.lotterySelector.productOptionId}
                            />
                            :
                            this.props.lotterySelector.productId === 4 || this.props.lotterySelector.productId === 5 ?
                                <TableSelectorMax3D
                                    onChange={this.handleChange.bind(this, this.props.index)}
                                    onChangePrice={this.handleChangePrice.bind(this, this.props.index)}
                                    selected={this.state.selected[this.props.index]}
                                    priceArray={this.props.lotterySelector.priceArray}
                                    productId={this.props.lotterySelector.productId}
                                    productOptionId={this.props.lotterySelector.productOptionId}
                                />
                                :
                                this.props.lotterySelector.productId >6 ?
                                <TableSelector1
                                    onChange={this.handleChange.bind(this, this.props.index)}
                                    selected={this.state.selected[this.props.index]}                                  
                                    productId={this.props.lotterySelector.productId}
                                    onChangePrice={this.handleChangePrice.bind(this, this.props.index)}
                                    priceArray={this.props.lotterySelector.priceArray}
                                    productOptionId={this.props.lotterySelector.productOptionId}
                                    max={this.props.lotterySelector.endNumber > 100 ? 80 : this.props.lotterySelector.endNumber}
                                />:                                
                                <TableSelector
                                    onChange={this.handleChange.bind(this, this.props.index)}
                                    selected={this.state.selected[this.props.index]}
                                    max={this.props.lotterySelector.endNumber > 100 ? 80 : this.props.lotterySelector.endNumber}
                                /> */}
                                
                    

                    <Row className="mt-4">
                        <Col>
                            <Button
                                className="w-100"
                                color="secondary"
                                onClick={this.handleRandom.bind(this, this.props.index)}
                            >
                                Ngẫu nhiên
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                className="w-100"
                                color="primary"
                                onClick={this.handleClear.bind(this, this.props.index)}
                            >
                                Hủy bỏ
                            </Button>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter className="border-0 py-4">
                    <Col>
                        <Row className="justify-content-center">
                            {
                                this.state.selected[this.props.index].isDone ?
                                    <CheckCircle color="lightgreen" size={40} onClick={this.handleClose.bind(this)}/> :
                                    <XCircle color="white" size={40} onClick={this.handleClose.bind(this)}/>
                            }
                        </Row>
                    </Col>
                </ModalFooter>
            </Modal>
        )
    }
}

function mapStateToProps(state) {
    return {
        lotterySelector: state.lotterySelector
    }
}

export default connect(mapStateToProps, {lotteryChange})(ModalSelector)
