import React, {Component} from 'react';

class NumberCircle extends Component {

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return this.props.isBackground !== nextProps.isBackground || this.props.title !== nextProps.title || this.props.icon !== nextProps.icon || this.props.price !== nextProps.price;
    }

    render() {
        if (this.props.title !== undefined)
            return (
                <span
                    style={this.props.isBackground ? {backgroundColor: "red", color: "white"} : {}}
                    className={`number-circle ` + (this.props.className ? this.props.className : "")}
                    onClick={this.props.onClick}
                >
                    {this.props.title==="" ? " " : this.props.title}
                </span>
            );
        else if (this.props.icon !== undefined) {
            return (
                <span
                    className={`number-circle ` + (this.props.className ? this.props.className : "")}
                    onClick={this.props.onClick}
                >
                    {this.props.icon}
                </span>
            )
        } else {
            return (
                <span
                    style={this.props.isBackground ? {backgroundColor: "red", color: "white"} : {}}
                    className={`number-circle number-circle-2 ` + (this.props.className ? this.props.className : "")}
                    onClick={this.props.onClick}
                >
                    {this.props.price}
                </span>
            )
        }

    }
}

export default NumberCircle;
