import React from "react";

import Wrapper from "../components/Wrapper";
// import Sidebar from "../components/Sidebar";
import Sidebar2 from "../components/Sidebar2";
import Main from "../components/Main";
import Navbar from "../components/Navbar";
import Content from "../components/Content";
// import Footer from "../components/Footer";

const Dashboard = ({children, options}) => (
    <React.Fragment>
        <Wrapper>
            <Sidebar2/>
            <Main>
                <Navbar/>
                <Content>{children}</Content>
                {/*<Footer/>*/}
            </Main>
        </Wrapper>
    </React.Fragment>
);


export default Dashboard;
