import * as firebase from "firebase/app";
import "firebase/messaging";

// const log = require("loglevel");

firebase.initializeApp({
    // Project Settings => Add Firebase to your web app    
    apiKey: "AIzaSyDse5osahdaTNhXFhZpes-dfjoqaqY1-pE",
    authDomain: "vesoviet-de798.firebaseapp.com",
    projectId: "vesoviet-de798",
    storageBucket: "vesoviet-de798.appspot.com",
    messagingSenderId: "779323570757",
    appId: "1:779323570757:web:ee470600cc8eb5efebc12c",
    measurementId: "G-5EMZF918PX"
});

let messaging;

if (firebase.messaging.isSupported()) {
    messaging = firebase.messaging();
    messaging.usePublicVapidKey("BBK5CC29Ad2gkXQsvTyD4jKQX6rQTXx4inM6iZrySiulnMKOYkb6OIK7261joJWZUboP_irInI1agkHPsoQpm2M");
    messaging.requestPermission()
    // if (fcmToken === "" || fcmToken === null || fcmToken === undefined) {
    messaging.getToken()
        .then(fcmToken => {
            // log.info("new fcmToken: " + fcmToken);
            // console.log(fcmToken)
            localStorage.setItem("fcmToken", fcmToken);
        })
    // } else {
    //     log.info("current fcmToken: " + fcmToken); 
    // }
}

export {messaging}
