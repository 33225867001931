const colors = {
    lightGreen: "#01ff14",
    primaryOrangeColor: "#ff550a",
    whiteColor: 'white',
    primaryBlackColor: "#2a2a2a",
    secondaryBlackColor: '#3c3c3c',
    primaryGreenColor: '#1DB20D',
    lightRedColor: '#e40923',
    primaryRedColor: '#fe0000',
    darkRedColor: '#880000',
    darkRedColor2: '#bd0007',
    fontBlackColor: '#212121',
    fontGreyColor: '#949393',
    placeholderTextColor: '#d7d6d6',
    greenColor: '#11b000',
    brownishColor: '#e9e9af',
    orangeColor: '#ff8852',
    darkOrangeColor: '#a45a31',
    greyLinesColor: '#e1e1e1',
    lightGrayColor: '#fafafa',
    redColor: '#e51c23',
    goldColor: '#eeb43e',
    goldBrownColor: '#c69435',
    purpleColor: '#9649b5',
    blackColor: '#000',
    blueColor: '#0024c0',
    facebookColor: '#32689d',
    darkmagentaColor: '#890b3a',
    pinkColor: '#7f35c0',
    topTabInactiveTintColor: '#8c8c8c',
    topTabBackgroundColor: '#CACACA',
    successStatus: '#4caf50',
    pendingStatus: '#f0ad4e',
    errorStatus: '#c9302c',
    backgroundGrey: '#fafafa'
};

export default colors;
