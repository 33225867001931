import React, { Component } from 'react';

import {
    Row, Col, Spinner
} from "reactstrap";

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy } from '@fortawesome/free-solid-svg-icons';

import { Link } from "react-router-dom";

import ListItem from "../../components/Vietluck/ListItem";

import LoadingPage from "../../components/LoadingPage";

import apiMyAccount from "../../api/MyAccount";

import { connect } from "react-redux";
import { userChange } from "../../redux/actions/userAction";
import { withRouter } from "react-router-dom";

import utils from "../../utils";
// import ButtonLoading from "../../components/FworkTag/ButtonLoading";

// import $ from "jquery";

const getProductName = require('../../utils/productData').getProductName;

const images = {
    mega645: require('../../assets/images/icon/mega645.png'),
    power655: require('../../assets/images/icon/power655.png'),
    keno: require('../../assets/images/icon/keno.png'),
    max3d: require('../../assets/images/icon/max3d.png'),
    max4d: require('../../assets/images/icon/max4d.png'),
    vietluck: require('../../assets/images/logo/logo.png'),
    dientoan: require('../../assets/images/logo/dien-toan.png'),
    max3d_pro: require('../../assets/images/logo/logomax3dpro.png'),
      ktmn:"/images/logo/ktmn.png"
};

const productSample = [
    "",
    "mega645",
    "power655",
    "max4d",
    "max3d",
    "max3d",
    "keno",
    "dientoan",
    "dientoan",
    "dientoan",
    "dientoan",
    "dientoan",
    "max3d_pro",
    "dientoan",
    'ktmn'
];

function searchToObject() {
    return window.location.search
        .slice(1)
        .split('&')
        .map(p => p.split('='))
        .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});
}

// function convertHashPage(hash) {
//     try {
//         hash = hash.replace(/#/g, '');
//         hash = parseInt(hash);
//         if (!Number.isNaN(hash)) {
//             return hash;
//         }
//         return 1;
//     } catch (e) {
//         return 1;
//     }
// }

class PaymentHistory extends Component {

    constructor(props) {
        super(props);
        this.ticketStatusId = searchToObject().status === "2" ? 2 : searchToObject().status === "3" ? 3 : 4;
        // this.page = convertHashPage(window.location.hash);
        this.page = 1;
        this.state = {
            ticketArray: [],
            isLoading: false,
            isLoading_next: false,
            isLoading_prev: false,
            isLast: false
        }
    }

    componentDidMount() {
        this.handleRefresh("isLoading");
        document.querySelector(".content").addEventListener("scroll", (e) => {
            if (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight - 5) {
                if (this.state.isLoading_next || this.state.isLast) {
                    return;
                } else {
                    this.handleNextPage();
                }
            }
            // console.log(e.target.scrollTop + " " + e.target.clientHeight + " " + e.target.scrollHeight)
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        // console.log(this.props.history.action)
        // if (nextProps.history.action === "POP") {
        //     nextProps.history.replace("/", null);
        // }
    }

    componentWillUnmount() {
        document.querySelector(".content").removeEventListener("scroll", () => { });
    }

    handleRefresh(options) {
        if (this.state.isLoading || this.state.isLoading_next || this.state.isLoading_prev) {
            return;
        }

        if (utils.isEmpty(this.props.user.authToken) || !this.props.user.isActive) {
            utils.confirm("Vui lòng đăng nhập", (check) => {
                window.location.replace("/auth/sign-in");
            });
            return;
        }
        this.setState({ [options]: true });
        apiMyAccount.getShopHistory(this.page, this.ticketStatusId, this.props.user.orderShoppingCartId, (err, result) => {
            if (err) {
                this.setState({ [options]: false });
            } else {
                this.setState({ [options]: false });
                if (result.records === 0) {
                    if (options === "isLoading") {
                        this.setState({
                            ticketArray: [],
                            isLast: true,
                        });
                        return;
                    }
                    this.setState({
                        isLast: true,
                    });
                    return;
                }

                let oldData = utils.copyState(this.state.ticketArray);
                let newData = utils.copyState(result.result);
                if (oldData.length) {
                    if (oldData[oldData.length - 1].title === newData[0].title) {
                        oldData[oldData.length - 1].data = oldData[oldData.length - 1].data.concat(utils.copyState(newData[0].data));
                        newData.splice(0, 1);
                    }
                }

                newData = oldData.concat(newData);

                if (options === "isLoading") {
                    this.setState({
                        ticketArray: result.result,
                        isLast: false,
                    })
                } else {
                    this.setState({
                        ticketArray: newData,
                        isLast: false,
                    });
                }
            }
        });
    };

    handleNextPage() {
        this.page = this.page + 1;
        // window.location.hash = this.page;
        // this.props.history.push({
        //     pathname: "/shopping-history",
        //     search: this.props.history.location.search,
        //     hash: "#" + this.page
        // });
        this.handleRefresh("isLoading_next");

    }

    // handlePrevPage() {
    //     this.page = this.page - 1;
    //     // window.location.hash = this.page;
    //     this.props.history.push({
    //         pathname: "/shopping-history",
    //         search: this.props.history.location.search,
    //         hash: "#" + this.page
    //     });
    //     this.handleRefresh("isLoading_prev");
    // }

    handleSelectTab(tabIndex) {
        tabIndex = parseInt(tabIndex);
        switch (tabIndex) {
            case 0:
                this.ticketStatusId = 2;
                break;
            case 1:
                this.ticketStatusId = 4;
                break;
            case 2:
                this.ticketStatusId = 3;
                break;
            default:
                break;
        }
        // window.location.hash = this.ticketStatusId;
        this.props.history.push(`/shopping-history?status=${this.ticketStatusId}#1`);
        this.handleRefresh("isLoading");
    }

    renderItem() {
        if (this.state.ticketArray.length === 0) {
            return (
                <span>Không có dữ liệu</span>
            )
        }
        return (
            this.state.ticketArray.map(({ title, data }, index) => (
                <Col key={index}>
                    <Row className="bg-primary p-1">{title}</Row>
                    <Row>
                        <Col>
                            {
                                data.map(({ id, productId, totalPrice, time, winningPrice }) => (
                                    <Link key={id} to={`/shopping-history/detail?id=${id}`} className="link-unset">
                                        <ListItem
                                            avatar={images[productSample[productId]]}
                                            title={getProductName(productId)}
                                            subtitle={(totalPrice * 1000).getMoneyFormat() + ' ₫'}
                                            rightTitle={time}
                                            rightSubTitle={
                                                this.ticketStatusId === 3 ? "Đã hủy " :
                                                    winningPrice === -1 ? 'Chưa quay' : winningPrice === 0 ? 'Không trúng' : <>
                                                        <FontAwesomeIcon icon={faTrophy} /> Trúng thưởng
                                                    </>
                                            }
                                            rightSubTitleStyle={{
                                                color: winningPrice > 0 ? "red" : "black"
                                            }}
                                        />
                                    </Link>
                                ))
                            }
                        </Col>
                    </Row>
                </Col>
            ))
        )
    }

    render() {
        if (this.state.isLoading) {
            return <LoadingPage />
        }
        return (
            <div>
                <Tabs
                    selectedIndex={this.ticketStatusId === 4 ? 1 : this.ticketStatusId === 3 ? 2 : 0}
                    onSelect={this.handleSelectTab.bind(this)}
                >
                    <TabList>
                        <Tab>Đang chờ</Tab>
                        <Tab>Đã thành công</Tab>
                        <Tab>Đã hủy</Tab>
                    </TabList>

                    <TabPanel>
                        {this.renderItem()}
                    </TabPanel>
                    <TabPanel>
                        {this.renderItem()}
                    </TabPanel>
                    <TabPanel>
                        {this.renderItem()}
                    </TabPanel>
                    {
                        (this.state.isLoading_next && !this.state.isLast) &&
                        <Row className="bootstrap-reset m-0 p-0 bottom-loading d-flex justify-content-center align-items-center" style={{ height: "50px" }}>
                            <Spinner
                                color="info"
                            />
                        </Row>
                    }

                    {/* <Row className="bootstrap-reset m-0 p-0">
                        <Col className="text-center p-1">
                            <ButtonLoading
                                title={"Trang trước"}
                                disabled={this.page <= 1}
                                className="w-100"
                                loading={this.state.isLoading_prev}
                                onClick={this.handlePrevPage.bind(this)}
                            />
                        </Col>
                        <Col className="text-center p-1">
                            <ButtonLoading
                                title={"Trang sau"}
                                disabled={this.state.isLast}
                                className="w-100"
                                loading={this.state.isLoading_next}
                                onClick={this.handleNextPage.bind(this)}
                            />
                        </Col>
                    </Row> */}
                </Tabs>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user
    }
}

export default withRouter(connect(mapStateToProps, { userChange })(PaymentHistory));
