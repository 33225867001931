import rootApi from "./rootApi";
import ErrorMessage from "../utils/ErrorMessage";

const path = require("../config").path;

function checkPrice(data,callback){
    rootApi.post(path.order.checkPrice,data).then(res => {
        return callback(null, res.data);
    })
    .catch(error => {
        ErrorMessage(error);
        return callback(error);
    })
}
function createOrder(data, callback) {

    rootApi.post(path.order.createOrder, data)
        .then(res => {
            return callback(null, res.data);
        })
        .catch(error => {
            ErrorMessage(error);
            return callback(error);
        })
}

function getConfig(callback) {
    rootApi.get(path.config.config, {})
        .then(res => {
            return callback(null, res.data);
        })
        .catch(error => {
            ErrorMessage(error);
            return callback(error, null)
        })
}
function getConfigVSMB(callback) {
    rootApi.get(path.config.configVSMB, {})
        .then(res => {
            return callback(null, res.data);
        })
        .catch(error => {
            ErrorMessage(error);
            return callback(error, null)
        })
}

function getKienThietDraw(callback){
   
    rootApi.get(path.kienThiet.getDraw)
        .then(res => {
            return callback(null, res.data);
        })
        .catch(error => {
            return callback(error);
        })
      
}
function getKienthietDetail(kID,callback){
   
            rootApi.get(path.kienThiet.detail+'?kId='+kID)
                .then(res => {
                    return callback(null, res.data);
                })
                .catch(error => {
                    return callback(error);
                })
       
}
function getKienthietAvailable(openTime,callback){
  
            rootApi.get(path.kienThiet.getAll+'?date='+openTime)
                .then(res => {
                    return callback(null, res.data);
                })
                .catch(error => {
                    return callback(error);
                })
       
}
function checkKienthietPrice(id,numberOfTicket,callback){
  
            rootApi.get(path.kienThiet.checkPrice+`?id=${id}&number=${numberOfTicket}`)
                .then(res => {
                    return callback(null, res.data);
                })
                .catch(error => {
                    return callback(error);
                })
       
}
function buyKienthiet(data,callback){
   
    rootApi.post(path.kienThiet.buy,data)
                .then(res => {
                    return callback(null, res.data);
                })
                .catch(error => {
                    console.log(error)
                    return callback(error);
                })
       
}


function getKTMNAvailDate(callback){
    rootApi.get(path.kienThiet.getAllKTMNDraw)
        .then(res => {
            return callback(null, res.data);
        })
        .catch(error => {
            return callback(error);
        })
}
function getKTMNAvaiTicket(date,productOptionId,callback){
    rootApi.get(path.kienThiet.getAllKTMN+`?date=${date||''}&productOptionId=${productOptionId||''}`)
        .then(res => {
            return callback(null, res.data);
        })
        .catch(error => {
            return callback(error);
        })
}
function checkKTMNPrice(data,callback){
    rootApi.post(path.kienThiet.checkPriceKTMN,data)
        .then(res => {
            return callback(null, res.data);
        })
        .catch(error => {
            return callback(error);
        })

}
function buyKTMN(data,callback){

    rootApi.post(path.kienThiet.buyKTMN,data)
    .then(res => {
        return callback(null, res.data);
    })
    .catch(error => {
        return callback(error);
    })
}
function getDetailKTMN(id,callback){
    rootApi.get(path.kienThiet.getKTMNDetail+'?idTicket='+id)
        .then(res => {
            return callback(null, res.data);
        })
        .catch(error => {
            console.log(error)
            return callback(error);
        })
 
}


export default {
    createOrder,
    getConfig,
    checkPrice,
    getConfigVSMB,
    getKienthietAvailable,
    buyKienthiet,
    checkKienthietPrice,
    getKienThietDraw,
    getKienthietDetail,
    getKTMNAvaiTicket,
    getKTMNAvailDate,
    buyKTMN,
    checkKTMNPrice,
    getDetailKTMN
};
