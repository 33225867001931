import React, { useEffect, useState, useRef } from "react";

import PropTypes, { number } from 'prop-types';
import {Modal, ModalBody, ModalHeader, ModalFooter, Row, Col, Button} from "reactstrap";

import styles from './modalKienThiet.styles';

const ModalKienThiet = ({ modalVisible, setModalVisible, setValueNumber, maxNumber, ticket,selectCode,selectedCodes,finishModal }) => {
  const chosenNumber = number => {
    setValueNumber({first: number});
  }

  return (
    
      <Modal
        animationType="slide"
        transparent={true}
        isOpen={modalVisible}
        onShow={() => {}}
        centered
        className="modal-selector"
      >
        <ModalBody className="bg-white" style={styles.centeredViewContent}>
          <div style={styles.modalView}>
            <div>
              <div>
                <div style={styles.modalText}>Vé Số: {ticket ? ticket.kTicket : ''}</div>
                <div style={styles.modalText}>Quý khách muốn mua bao nhiêu vé?</div>
                <div style={{flexDirection:'row',justifyContent:'center',display:'flex'}}>
                  {
                    maxNumber.map((item,index)=>(
                      <a key={index} style={styles.flatListCenter} onClick={() => chosenNumber(item.key)}>
                          <div style={styles.textNumberEnable}>{item.key}</div>
                        </a>))
                  }
                 
                </div>
                <div>
                <div style={{flexWrap:'wrap',flexDirection:'row',justifyContent:'center',display:'flex'}}>
                  {
                   ticket&&
                    ticket.kTickets.map((e,i)=>{
                      let styleBtn={
                        padding:5,borderWidth:1,borderRadius:5,margin:5,minWidth:50,borderStyle:'solid'
                      };
                      let styleBtnActive={ padding:5,borderWidth:1,borderRadius:5,margin:5,minWidth:50,borderStyle:'solid',backgroundColor:'red'};
                      let styleTxt={textAlign:'center'}
                      let styleTxtActive={color:'#fff',textAlign:'center'}
                     return <a key={'idx'+i} onClick={()=>selectCode(e)} style={selectedCodes.indexOf(e)==-1?styleBtn:styleBtnActive}>
                          <span style={selectedCodes.indexOf(e)==-1?styleTxt:styleTxtActive}>{e.split('-')[1]}</span>
                        </a>})
                    
                  }
                </div>
                </div>
              </div>
            </div>
            <div>
              <div style={styles.listButton}>
                <a
                  style={{...styles.openButton,backgroundColor:'gray'}}
                  onClick={() => {
                    setModalVisible(!modalVisible);
                  }}
                >
                  <span style={styles.textStyle}>Đóng</span>
                </a>
               {<a
                  style={styles.openButton}
                  onClick={() => {
                    finishModal();
                  }}
                >
                  <span style={styles.textStyle}>Hoàn thành</span>
                </a>
                } 
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    
  );
};

ModalKienThiet.propTypes = {
  modalVisible: PropTypes.bool,
  setModalVisible: PropTypes.func,
  setValueNumber: PropTypes.func,
  maxNumber: PropTypes.array,
  ticket: PropTypes.any,
}

ModalKienThiet.defaultProps = {
  modalVisible: false,
  setModalVisible: () => {},
  setValueNumber: () => {},
  maxNumber: [],
  ticket: null,
}

export default ModalKienThiet;