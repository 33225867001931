import React, {Component} from 'react';

import {
    Row, Col
} from "reactstrap";
import utils from "../../utils";
import {Phone, Mail, Facebook} from "react-feather";
import {Helmet} from "react-helmet";
const logo = require("../../assets/images/logo/logo.png");

class Help extends Component {
    constructor(props)
    {
        super(props);
        this.state={seo:{}}
    }
    async componentDidMount(){       
        var seo= await utils.fetchSeo('contact.json');
        this.setState({seo:seo});
    }
    render() {
        return (
            <div className="bootstrap-reset p-3 background-logo">
            {/* <Helmet>
                    <meta charSet="utf-8" />
                    <title>{this.state.seo.title}</title>
                    {
                        this.state.seo.canonical===''?null: <link rel="canonical" href={this.state.seo.canonical} />
                    }
                   
                    <meta
                        name="keywords"
                        content={this.state.seo.keyword}
                    />
                    <meta
                    name="description"
                    content={this.state.seo.description}
                    />
                    <meta property="og:url" content={this.state.seo.url}/>                    
                    <meta property="og:type" content="website"/>
                    <meta property="og:title" content={this.state.seo.title}/>
                    <meta property="og:description" content={this.state.seo.description}/>                    
                    <meta property="og:image" content={this.state.seo.thumb}/>

                </Helmet> */}
                <Row className="justify-content-center">
                    <img src={logo} alt={"avt"} width="100px" height="80px"/>
                </Row>
                <Row className="mt-2 justify-content-center font-weight-bold">CÔNG TY TNHH DỊCH VỤ iLOTO</Row>
                {/* <Row className="justify-content-center text-center mt-1">
                    GPĐKKD: số 0109372133 do Sở KH&ĐT TP.Hà Nội cấp lần đầu ngày 09/10/2020
                </Row> */}
                <Row className="mt-3">
                    <Col>
                        <div className="font-weight-bold">Địa chỉ</div>
                        <div>
                        148 Lương Đình Của - Hà Nội
                        </div>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        <div className="font-weight-bold">Hotline</div>
                        <div>0835.556.556</div>
                    </Col>
                    <Col className="col-1">
                        <Row className="h-100 justify-content-center align-items-center">
                            <a href="tel:0835556556" target="_blank"  rel="noopener noreferrer">
                                <Phone
                                    color={"red"}
                                    className="hover-pointer hover-animation-scale"
                                />
                            </a>
                        </Row>
                    </Col>
                </Row>
                <Row className="mt-3">
                    {/* <Col>
                        <div className="font-weight-bold">Email</div>
                        <div>
                        Vesoviet@gmail.com
                        </div>
                    </Col>
                    <Col className="col-1">
                        <Row className="h-100 justify-content-center align-items-center">
                            <a href="mailto:vesoviet@gmail.com" target="_blank" rel="noopener noreferrer">
                                <Mail
                                    color={"red"}
                                    className="hover-pointer hover-animation-scale"
                                />
                            </a>
                        </Row>
                    </Col> */}
                </Row>
                {/* <Row className="mt-3">
                    <Col>
                        <div className="font-weight-bold">Facebook</div>
                        <div>iLOTO - Đặt Mua Vé Số Online</div>
                    </Col>
                    <Col className="col-1">
                        <Row className="h-100 justify-content-center align-items-center">
                            <a href="https://www.facebook.com/VesoVietcomvn-%C4%90%E1%BA%B7t-Mua-V%C3%A9-S%E1%BB%91-Online-106163128088778" target="_blank" rel="noopener noreferrer">
                                <Facebook
                                    color={"red"}
                                    className="hover-pointer hover-animation-scale"
                                />
                            </a>
                        </Row>
                    </Col>
                </Row> */}
            </div>
        );
    }
}

export default Help;
