import React,{useState,useEffect} from "react";
import {Modal, ModalBody, ModalHeader, ModalFooter, Row, Col, Button} from "reactstrap";
import utils from "../../utils";
import NumberCircle from "./NumberCircle";

import {connect} from "react-redux";
import {lotteryChange} from "../../redux/actions/lotterySelectorAction";
import styles from  "../../styles/_page_ktmn.module.css";
import {getRandomValue, checkValidValue} from './LotterySelector';

import {
    XCircle, CheckCircle
} from "react-feather"
import {root} from '../../config/index.js'

const ModalKTMN = ({ticket,finished,modalVisible,objTicketReady}) => {
    const uri=root+'/media/'+ticket.image;
    const [imageRatio,setImageRatio]=useState(0);
    const [selectedNum,setSelectedNum]=useState(0);
    useEffect(()=>{
        let ticketReady=objTicketReady.find(e=>e.idTicket==ticket.id);
        if(ticketReady){
            setSelectedNum(ticketReady.numOfTicket)
        }
    },[]);
    return (
        <Modal
        isOpen={modalVisible} 
        centered 
        className="modal-selector"
        >
      
        <ModalBody className="bg-white">
        <span className="mr-4">Vé Số: {ticket ? ticket.ticket : ''}</span>
        <div className={styles.modalText}>Quý khách muốn mua bao nhiêu vé?</div>
            <img
                src={uri}
                style={{width:'100%',borderRadius:5,margin:5}}
            />
            <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-start',marginTop:5,flexWrap:'wrap'}}>
                  {
                     Array(ticket.maxTicket-ticket.ticketBuyed).fill('0').map((item,index)=>(
                      <a key={index} className={styles.flatListCenter} onClick={() => setSelectedNum((index+1)!=selectedNum?(index+1):0)}>
                          <div 
                          className={`${styles.textNumberEnable} ${selectedNum==(index+1)&&styles.textNumberEnableActive}`}
                          >{index+1}</div>
                        </a>))
                  }
                 
                </div>
            <div style={{flexDirection:'row',display:'flex',justifyContent:'center'}}>
                <a
                className={styles.btnConfirm}
                onClick={() => {
                    finished(selectedNum);
                }}
                >
                <div style={{textAlign:'center',color:'#fff'}}>Xác nhận</div>
            </a>
            </div>    
        </ModalBody>
    </Modal>
    )
}

export default ModalKTMN