import React, { Component,useState,useEffect } from 'react';
import NumberCircle from "../../components/Vietluck/NumberCircle";
import { Col, Row } from "reactstrap";

import LoadingPage from "../../components/LoadingPage";
import ButtonLoading from "../../components/FworkTag/ButtonLoading";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs"

import apiResult from "../../api/Result";
import { Link } from "react-router-dom";
import { Grid, HardDrive, Book ,Clock} from "react-feather";
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import moment from "moment";
import { productDataset } from '../../utils/productData';
import styles from '../../styles/_result_ktmn.module.css';
const parseProvinceName=(sl)=>{
    return productDataset.find(dt => dt.id === 14).productOptionArray.find(s=>s.slug==sl).name
  }
export class ResultPower655Card extends React.Component {
    render() {
        return (
            <div className="d-flex flex-wrap justify-content-center w-100">
                {
                    this.props.result.result.map((num, index) => (
                        <NumberCircle
                            key={index}
                            title={num}
                            className="mr-1"
                            isBackground={index === 6}
                        />
                    ))
                }
            </div>
        )
    }
}

export class ResultMega645Card extends React.Component {
    render() {
        return (
            <div className="d-flex flex-wrap justify-content-center w-100">
                {
                    this.props.result.result.map((num, index) => (
                        <NumberCircle
                            key={index}
                            title={num}
                            className="mr-1"
                        />
                    ))
                }
            </div>
        )
    }
}

export class ResultMax4DCard extends React.Component {
    render() {
        return (
            <React.Fragment>

            </React.Fragment>
        )
    }
}

export class ResultMax3DCard extends React.Component {
    render() {
        const result = this.props.result.result;
        const firstPrize = result.find(dt => dt.type === 'giainhat');
        const secondPrize = result.find(dt => dt.type === 'giainhi');
        const thirdPrize = result.find(dt => dt.type === 'giaiba');
        const fourPrize = result.find(dt => dt.type === 'giaikhuyenkhich');
        return (
            <Row className="w-100 justify-content-center">
                <Col className="text-center">
                    <Row className="justify-content-center font-weight-bold">
                        <span>Giải nhất</span>
                    </Row>
                    <Row className="justify-content-center">
                        {
                            firstPrize.result.map((number, index) => (
                                <>
                                    {
                                        number.split('').map((num, index2) => (
                                            <NumberCircle
                                                title={num}
                                                key={`${index}-${index2}`}
                                                className="number-circle-sm"
                                            />
                                        ))
                                    }
                                    {
                                        index !== (firstPrize.result.length - 1) &&
                                        <div style={{ width: 10 }}>

                                        </div>
                                    }
                                </>
                            ))
                        }
                    </Row>
                    <Row className="justify-content-center mt-2 font-weight-bold">
                        <span>Giải nhì</span>
                    </Row>
                    <Row className="justify-content-center">
                        {
                            secondPrize.result.map((number, index) => (
                                <>
                                    {
                                        number.split('').map((num, index2) => (
                                            <NumberCircle
                                                title={num}
                                                key={`${index}-${index2}`}
                                                className="number-circle-sm"
                                            />
                                        ))
                                    }
                                    {
                                        index !== (secondPrize.result.length - 1) &&
                                        <div style={{ width: 10 }}>

                                        </div>
                                    }
                                </>
                            ))
                        }
                    </Row>
                    <Row className="justify-content-center mt-2 font-weight-bold">
                        <span>Giải ba</span>
                    </Row>
                    <Row className="justify-content-center">
                        {
                            thirdPrize.result.map((number, index) => (
                                (index < 3) &&
                                <>
                                    {
                                        number.split('').map((num, index2) => (
                                            <NumberCircle
                                                title={num}
                                                key={`${index}-${index2}`}
                                                className="number-circle-sm"
                                            />
                                        ))
                                    }
                                    {
                                        // index !== (thirdPrize.result.length - 1) &&
                                        <div style={{ width: 10 }}>

                                        </div>
                                    }
                                </>
                            ))
                        }
                    </Row>
                    <Row className="justify-content-center mt-1">
                        {
                            thirdPrize.result.map((number, index) => (
                                (index >= 3) &&
                                <>
                                    {
                                        number.split('').map((num, index2) => (
                                            <NumberCircle
                                                title={num}
                                                key={`${index}-${index2}`}
                                                className="number-circle-sm"
                                            />
                                        ))
                                    }
                                    {
                                        <div style={{ width: 10 }}>

                                        </div>
                                    }
                                </>
                            ))
                        }
                    </Row>
                    <Row className="justify-content-center mt-2 font-weight-bold">
                        <span>Giải khuyến khích</span>
                    </Row>
                    <Row className="justify-content-center">
                        {
                            fourPrize.result.map((number, index) => (
                                (index < 4) &&
                                <>
                                    {
                                        number.split('').map((num, index2) => (
                                            <NumberCircle
                                                title={num}
                                                key={`${index}-${index2}`}
                                                className="number-circle-sm"
                                            />
                                        ))
                                    }
                                    {
                                        <div style={{ width: 10 }}>

                                        </div>
                                    }
                                </>
                            ))
                        }
                    </Row>
                    <Row className="justify-content-center mt-1">
                        {
                            fourPrize.result.map((number, index) => (
                                (index >= 4) &&
                                <>
                                    {
                                        number.split('').map((num, index2) => (
                                            <NumberCircle
                                                title={num}
                                                key={`${index}-${index2}`}
                                                className="number-circle-sm"
                                            />
                                        ))
                                    }
                                    {
                                        <div style={{ width: 10 }}>

                                        </div>
                                    }
                                </>
                            ))
                        }
                    </Row>
                </Col>
            </Row>
        )
    }
}

export class ResultKenoCard extends React.Component {
    render() {
        let result = this.props.result.result;
        let val = [
            result.filter(dt => dt % 2 === 0).length,
            result.filter(dt => dt % 2 !== 0).length,
            result.filter(dt => dt > 40).length,
            result.filter(dt => dt <= 40).length,
        ];
        return (
            <div className="result-keno-card">
                <div className="d-flex flex-wrap justify-content-center w-100">
                    {
                        this.props.result.result.map((num, index) => (
                            <NumberCircle
                                key={index}
                                title={num}
                                className="mr-1 mt-1"
                            />
                        ))
                    }
                </div>
                <Row className="mt-2 keno-2">
                    <Col className="text-center">
                        <span
                            className="left-container"
                            style={
                                val[0] >= 13 ? {
                                    backgroundColor: 'blue',
                                    color: 'white',
                                } : {}
                            }
                        >
                            Chẵn ({val[0]})
                        </span>
                        <span
                            className="right-container"
                            style={
                                val[1] >= 13 ? {
                                    backgroundColor: 'blue',
                                    color: 'white',
                                } : {}
                            }
                        >
                            Lẻ ({val[1]})
                        </span>
                    </Col>
                    <Col className="text-center">
                        <span
                            className="left-container"
                            style={
                                val[2] >= 13 ? {
                                    backgroundColor: 'blue',
                                    color: 'white',
                                } : {}
                            }
                        >
                            Lớn ({val[2]})
                        </span>
                        <span
                            className="right-container"
                            style={
                                val[3] >= 13 ? {
                                    backgroundColor: 'blue',
                                    color: 'white',
                                } : {}
                            }
                        >
                            Nhỏ ({val[3]})
                        </span>
                    </Col>
                </Row>
            </div>
        )
    }
}

export class ResultDienToan extends React.Component {
    renderNumbers = (num) => {
        if (this.props.showUp)
            if (this.props.showUp.length > 0) {
                for (let show of this.props.showUp) {
                    if (this.props.productId === 7) {

                        let lengthShowup = show.length;
                        if (num.length <= lengthShowup) {
                            if (num === show.slice(-num.length)) {
                                return (<span style={{ color: 'red' }}>{num}</span>);
                            }
                            return num;
                        }
                        else {
                            if (show.join('') === num.slice(-lengthShowup)) {
                                return (<><span>{num.slice(0, -lengthShowup)}</span><span style={{ color: 'red' }}>{num.slice(-lengthShowup)}</span></>)
                            }
                            return num;
                        }

                    }
                    if (this.props.productId === 8) {
                        if (show.includes(num.slice(-2))) {
                            return <><span>{num.slice(0, -2)}</span><span style={{ color: 'red' }}>{num.slice(-2)}</span></>
                        }
                        return num;
                    }
                    return num;
                }
            }
        return num;
    }
    render() {
        let result = this.props.result.result;
        let dacbiet = result.find(r => r.type == 'dacbiet').result;
        let giainhat = result.find(r => r.type == 'giainhat').result;
        let giainhi = result.find(r => r.type == 'giainhi').result;
        let giaiba = result.find(r => r.type == 'giaiba').result;
        let giaitu = result.find(r => r.type == 'giaitu').result;
        let giainam = result.find(r => r.type == 'giainam').result;
        let giaisau = result.find(r => r.type == 'giaisau').result;
        let giaibay = result.find(r => r.type == 'giaibay').result;
        return (
            <Row className="w-100">
                <Col className="text-center">
                    <Row className="result-dien-toan">
                        <table class="table_center table">
                            <tbody>
                                <tr >
                                    <td className="side font-weight-bold">Đặc biệt</td>
                                    <td colspan="13" className='res-dt-special'>{dacbiet[0]}</td>
                                </tr>
                                <tr>
                                    <td className="side font-weight-bold">Giải nhất</td>
                                    <td colspan="13" >{this.renderNumbers(giainhat[0])}</td>
                                </tr>
                                <tr>
                                    <td className="side font-weight-bold">Giải nhì</td>
                                    <td colspan="8" >{this.renderNumbers(giainhi[0])}</td>
                                    <td colspan="8" >{this.renderNumbers(giainhi[1])}</td>
                                </tr>

                                <tr >
                                    <td className="side font-weight-bold" rowSpan='2'>Giải ba</td>
                                    {
                                        giaiba.slice(0, 3).map(res => (
                                            <td colSpan='5' >{this.renderNumbers(res)}</td>)
                                        )
                                    }
                                </tr>
                                <tr >
                                    {
                                        giaiba.slice(3, 6).map(res => (
                                            <td colSpan='5' >{this.renderNumbers(res)}</td>)
                                        )
                                    }
                                </tr>

                                <tr>
                                    <td className="side font-weight-bold" >Giải tư</td>
                                    {
                                        giaitu.map(res => (
                                            <td colSpan='4' >{this.renderNumbers(res)}</td>)
                                        )
                                    }
                                </tr>
                                <tr>
                                    <td className="side font-weight-bold" rowSpan='2'>Giải năm</td>
                                    {
                                        giainam.slice(0, 3).map(res => (
                                            <td colSpan='5' >{this.renderNumbers(res)}</td>)
                                        )
                                    }
                                </tr>
                                <tr >
                                    {
                                        giainam.slice(3, 6).map(res => (
                                            <td colSpan='5' >{this.renderNumbers(res)}</td>)
                                        )
                                    }
                                </tr>
                                <tr>
                                    <td className="side font-weight-bold" >Giải sáu</td>
                                    {
                                        giaisau.map(res => (
                                            <td colSpan='5' >{this.renderNumbers(res)}</td>)
                                        )
                                    }
                                </tr>
                                <tr>
                                    <td className="side font-weight-bold" >Giải bảy</td>
                                    {
                                        giaibay.map(res => (
                                            <td colSpan='4' >{this.renderNumbers(res)}</td>)
                                        )
                                    }
                                </tr>
                            </tbody>
                        </table>
                    </Row>

                </Col>

            </Row>
        )
    }
}
export class Result6x36 extends React.Component {
    render() {
        return (
            <div className="d-flex flex-wrap justify-content-center w-100">
                {
                    this.props.result.result.map((num, index) => (
                        <NumberCircle
                            key={index}
                            title={num}
                            className="mr-1"
                        />
                    ))
                }
            </div>
        )
    }
}
export class ResultThantai extends React.Component {
    render() {
        return (
            <div className="d-flex flex-wrap justify-content-center w-100">
                {
                    this.props.result.result.map((num, index) => (
                        <NumberCircle
                            key={index}
                            title={num}
                            className="mr-1"
                        />
                    ))
                }
            </div>
        )
    }
}
export class Result123 extends React.Component {
    render() {
        return (
            <div className="d-flex flex-wrap justify-content-center w-100">
                {
                    this.props.result.result.map((number, i) => (
                        i === 2
                            ?
                            <NumberCircle
                                key={i.toString()}
                                price={number}
                                className="hover-pointer"
                            /> :
                            i === 1 ? <NumberCircle
                                size={32}
                                key={i.toString()}
                                title={number}
                                className="mr-1 number-circle-3  hover-pointer"
                            />
                                :
                                <NumberCircle
                                    size={32}
                                    key={i.toString()}
                                    title={number}
                                    className="mr-1 hover-pointer"
                                />
                    ))
                }
            </div>
        )
    }
}
export class ResultMax3DProCard extends React.Component {
    render() {
        const result = this.props.result.result;
        const firstPrize = result.find((dt) => dt.type === "giaidacbiet");
        const secondPrize = result.find((dt) => dt.type === "giainhat");
        const thirdPrize = result.find((dt) => dt.type === "giainhi");
        const fourPrize = result.find((dt) => dt.type === "giaiba");
        return (
            <Row className="w-100 justify-content-center">
                <Col className="text-center">
                    <Row className="justify-content-center font-weight-bold">
                        <span>Giải đặc biệt</span>
                    </Row>
                    <Row className="justify-content-center">
                        {firstPrize.result.map((number, index) => (
                            <>
                                {number.split("").map((num, index2) => (
                                    <NumberCircle
                                        title={num}
                                        key={`${index}-${index2}`}
                                        className="number-circle-sm"
                                    />
                                ))}
                                {index !== firstPrize.result.length - 1 && (
                                    <div style={{ width: 10 }}></div>
                                )}
                            </>
                        ))}
                    </Row>
                    <Row className="justify-content-center mt-2 font-weight-bold">
                        <span>Giải nhất</span>
                    </Row>
                    <Row className="justify-content-center">
                        {secondPrize.result.map((number, index) => (
                            <>
                                {number.split("").map((num, index2) => (
                                    <NumberCircle
                                        title={num}
                                        key={`${index}-${index2}`}
                                        className="number-circle-sm"
                                    />
                                ))}
                                {index !== secondPrize.result.length - 1 && (
                                    <div style={{ width: 10 }}></div>
                                )}
                            </>
                        ))}
                    </Row>
                    <Row className="justify-content-center mt-2 font-weight-bold">
                        <span>Giải nhì</span>
                    </Row>
                    <Row className="justify-content-center">
                        {thirdPrize.result.map(
                            (number, index) =>
                                index < 3 && (
                                    <>
                                        {number.split("").map((num, index2) => (
                                            <NumberCircle
                                                title={num}
                                                key={`${index}-${index2}`}
                                                className="number-circle-sm"
                                            />
                                        ))}
                                        {
                                            // index !== (thirdPrize.result.length - 1) &&
                                            <div style={{ width: 10 }}></div>
                                        }
                                    </>
                                )
                        )}
                    </Row>
                    <Row className="justify-content-center mt-1">
                        {thirdPrize.result.map(
                            (number, index) =>
                                index >= 3 && (
                                    <>
                                        {number.split("").map((num, index2) => (
                                            <NumberCircle
                                                title={num}
                                                key={`${index}-${index2}`}
                                                className="number-circle-sm"
                                            />
                                        ))}
                                        {<div style={{ width: 10 }}></div>}
                                    </>
                                )
                        )}
                    </Row>
                    <Row className="justify-content-center mt-2 font-weight-bold">
                        <span>Giải ba</span>
                    </Row>
                    <Row className="justify-content-center">
                        {fourPrize.result.map(
                            (number, index) =>
                                index < 4 && (
                                    <>
                                        {number.split("").map((num, index2) => (
                                            <NumberCircle
                                                title={num}
                                                key={`${index}-${index2}`}
                                                className="number-circle-sm"
                                            />
                                        ))}
                                        {<div style={{ width: 10 }}></div>}
                                    </>
                                )
                        )}
                    </Row>
                    <Row className="justify-content-center mt-1">
                        {fourPrize.result.map(
                            (number, index) =>
                                index >= 4 && (
                                    <>
                                        {number.split("").map((num, index2) => (
                                            <NumberCircle
                                                title={num}
                                                key={`${index}-${index2}`}
                                                className="number-circle-sm"
                                            />
                                        ))}
                                        {<div style={{ width: 10 }}></div>}
                                    </>
                                )
                        )}
                    </Row>
                </Col>
            </Row>
        );
    }
}
export const ResultKtmnCard = ({ dataResult }) => {
    try{
    return (
      <div className={styles.boardResult}>
        {/* Tên tỉnh */}
        <div className={styles.row}>
          <div className={styles.column} />
          {dataResult.map((item, index) => {
            return (
              <div className={styles.listProvince}>
                <div key={index} className={styles.province}>
                  {parseProvinceName(item.area)}
                </div>
              </div>
            );
          })}
        </div>
        {/* G8 */}
        <div className={styles.row} style={{backgroundColor:'#efefef'}}>
          <div className={styles.column}>
            <div className={styles.namePrize}>G8</div>
            <div style={{color:'red',textAlign:'center',fontWeight:'bold'}}>
                1 trăm
            </div>
          </div>
          {dataResult.map((item, index) => {
            return (
              <div style={{flex: 1,borderLeftWidth: 1, borderColor:'gray',}}>
              <div key={index} className={styles.number}>
                {item.result.find(e=>e.type=='giaitam').result[0]}
              </div>
              </div>
            );
          })}
        </div>
        {/* G7 */}
        <div className={styles.row}>
          <div className={styles.column}>
            <div className={styles.namePrize}>G7</div>
            <div style={{color:'red',textAlign:'center',fontWeight:'bold'}}>
                2 trăm
            </div>
          </div>
          {dataResult.map((item, index) => {
            return (
              <div style={{flex: 1,borderLeftWidth: 1, borderColor:'gray',}}>
              <div key={index} className={styles.number}>
              {item.result.find(e=>e.type=='giaibay').result[0]}
              </div>
              </div>
            );
          })}
        </div>
        {/* G6 */}
        <div className={styles.row} style={{backgroundColor:'#efefef'}}>
          <div className={styles.column}>
            <div className={styles.namePrize}>G6</div>
            <div style={{color:'red',textAlign:'center',fontWeight:'bold'}}>
                4 trăm
            </div>
          </div>
          {dataResult.map((item, index) => {
            return (
              <div key={index} className={styles.number} style={{borderLeftWidth: 1, borderColor:'gray'}}>
                {item.result.find(e=>e.type=='giaisau').result.map((data, index) => {
                  return (
                    <div className={styles.colPrizeSix} key={index}>
                      {data}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
        {/* G5 */}
        <div className={styles.row}>
          <div className={styles.column}>
            <div className={styles.namePrize}>G5</div>
            <div style={{color:'red',textAlign:'center',fontWeight:'bold'}}>
                1 triệu
            </div>
          </div>
          {dataResult.map((item, index) => {
           
            return (
              <div style={{flex: 1,borderLeftWidth: 1, borderColor:'gray',}}>
              <div key={index} className={styles.number}>
              {item.result.find(e=>e.type=='giainam').result[0]}
              </div></div>
            );
          })}
        </div>
        {/* G4 */}
        <div className={styles.row} style={{backgroundColor:'#efefef'}}>
          <div className={styles.column}>
            <div className={styles.namePrize}>G4</div>
            <div style={{color:'red',textAlign:'center',fontWeight:'bold'}}>
                3 triệu
            </div>
          </div>
          {dataResult.map((item, index) => {
            
            return (
              <div key={index} className={styles.number} style={{borderLeftWidth: 1, borderColor:'gray',}}>
               
                {item.result.find(e=>e.type=='giaitu').result.map((data, index) => {
                  return (
                    <div className={styles.colPrizeSix} key={index}>
                      {data}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
        {/* G3 */}
        <div className={styles.row}>
          <div className={styles.column}>
            <div className={styles.namePrize}>G3</div>
            <div style={{color:'red',textAlign:'center',fontWeight:'bold'}}>
                10 triệu
            </div>
          </div>
          {dataResult.map((item, index) => {
            return (
              <div key={index} className={styles.number} style={{borderLeftWidth: 1, borderColor:'gray'}}>
                {item.result.find(e=>e.type=='giaiba').result.map((data, index) => {
                  return (
                    <div className={styles.colPrizeSix} key={index}>
                      {data}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
        {/* G2 */}
        <div className={styles.row} style={{backgroundColor:'#efefef'}}>
          <div className={styles.column}>
            <div className={styles.namePrize}>G2</div>
            <div style={{color:'red',textAlign:'center',fontWeight:'bold'}}>
                15 triệu
            </div>
          </div>
          {dataResult.map((item, index) => {
           
            return (
              <div style={{flex: 1,borderLeftWidth: 1, borderColor:'gray',}}>
              <div key={index} className={styles.number}>
                {item.result.find(e=>e.type=='giainhi').result[0]}
              </div>
              </div>
            );
          })}
        </div>
  
        {/* G1 */}
        <div className={styles.row}>
          <div className={styles.column}>
            <div className={styles.namePrize}>G1</div>
            <div style={{color:'red',textAlign:'center',fontWeight:'bold'}}>
                30 triệu
            </div>
          </div>
          {dataResult.map((item, index) => {
           
            return (
              <div style={{flex: 1,borderLeftWidth: 1, borderColor:'gray',}}>
              <div key={index} className={styles.number}>
                {item.result.find(e=>e.type=='giainhat').result[0]}
              </div>
              </div>
            );
          })}
        </div>
        {/* DB */}
        <div className={styles.row} style={{backgroundColor:'#efefef'}}>
          <div className={styles.column}>
            <div className={styles.namePrizeDB}>ĐB</div>
            <div style={{color:'red',textAlign:'center',fontWeight:'bold'}}>
                2 tỷ
            </div>
          </div>
          {dataResult.map((item, index) => {
            return (
              <div style={{flex: 1,borderLeftWidth: 1, borderColor:'gray',}}>
              <div key={index} className={styles.numberDb}>
              {item.result.find(e=>e.type=='dacbiet').result[0]}
              </div>
              </div>
            );
          })}
        </div>
      </div>
    );
    }
    catch(e){
      console.log(e);
    }
  };
  const ResultKTMN=()=>{
    const [dateTime, setDateTime] = useState(moment().format('DD-MM-YYYY')); // Khởi tạo giá trị mặc định là thời gian hiện tại
    const [dataResult, setDataResult] = useState([])
    const [isOpen, setIsOpen] = useState(false);
    const fetchDataKtmn = async (date) => {
      try {
        const [err,resultData] = await apiResult.getResultKtmn(date);
        const firstResult = resultData;
        setDateTime(date);
        setDataResult(firstResult);
      } catch (error) {
        console.error(error);
      }
    };
    const handleDateTimeChange = (selectDateTime) => {
      const formattedDateTime = moment(selectDateTime).format('DD-MM-YYYY');
      setIsOpen(false); // Tắt lịch sau khi chọn ngày
      fetchDataKtmn(formattedDateTime)
    };
    
    useEffect(() => {
      let displayDate=moment().format('DD-MM-YYYY');
        if( moment('16:15', 'HH:mm').isAfter(moment()))
        {
          displayDate=moment().add(-1,'days').format('DD-MM-YYYY');
        }
  
      fetchDataKtmn(displayDate);
    }, []);
    
    return (
      <div className="table-ktmn" >
      <a role='button' onClick={() => setIsOpen(!isOpen)} className="btn-select-ktmn-draw"
    
      >
        
       <span> 
         <Clock
            style={{marginRight:10}}
          /> 
          {dateTime}</span>
        </a>
      {isOpen && (
        <Datetime 
          value={dateTime}
          onChange={handleDateTimeChange}
          open={isOpen} // Điều khiển hiển thị lịch
        />
      )}
        <div>
          {
            dataResult.length>0?<ResultKtmnCard dataResult={dataResult}/>:<></>
          }
        </div>
      </div>
    )
  
  }
class MainResult extends Component {

    constructor(props) {
        super(props);
        this.productId = 6;
        this.lastPeriodId = 0;
        this.state = {
            isLoaded: false,
            isLoading: false
        }
    }

    componentDidMount() {
        this.handleRefresh()
    }

    handleSelectTab(index) {
        this.lastPeriodId = 0;
        if (index === 0) index = 6;
        else if (index === 3) index = 12;
        else if (index === 5) index = 7;
        else if (index === 6) index = 14;
        else if (index === 7) index = 9;
        else if (index === 8) index = 10;
        else if (index === 5) index = 11;
        this.productId = index;
        console.log(index)
        this.handleRefresh();
    }

    handleRefresh() {
        if(this.productId==14){

            this.setState({...this.state});return;
        }
        this.setState({ isLoaded: false });
        apiResult.getLastPeriod(this.productId, (err, result) => {
            if (err) {
                console.log(err)
            } else {
                let id = result.id;
                this.lastPeriodId = id;
                apiResult.getResult(this.productId, [id - 9, id], (err, result) => {
                    if (err) {
                        console.log(err)
                    } else {
                        this.lastPeriodId = id - 10;
                        if (this.productId < 7 || this.productId == 12)
                            result.reverse();
                        this.setState({ result: result, isLoaded: true });
                    }
                })
            }
        })
    }

    handleLoadMore() {
        if (this.state.isLoading) {
            return
        }
        this.setState({ isLoading: true });
        apiResult.getResult(this.productId, [this.lastPeriodId - 9, this.lastPeriodId], (err, result) => {
            if (err) {
                this.setState({ isLoading: false })
            } else {
                if (this.productId < 7 || this.productId == 12)
                    result.reverse()
                let last_result = JSON.parse(JSON.stringify(this.state.result));
                last_result = last_result.concat(result);
                this.setState({
                    isLoading: false,
                    result: last_result
                })
                this.lastPeriodId -= 10;
            }
        })
    }

    renderResultCard(result, key) {
        switch (this.productId) {
            case 1:
                return <ResultMega645Card result={result} key={key} />;
            case 2:
                return <ResultPower655Card result={result} key={key} />;
            case 3:
                return <ResultMax3DCard result={result} key={key} />;
            case 4:
            case 5:
                return <ResultMax3DCard result={result} key={key} />;
            case 6:
                return <ResultKenoCard result={result} key={key} />;
            case 7:
                return <ResultDienToan result={result} key={key} />;
            case 9:
                return <Result6x36 result={result} key={key} />;
            case 10:
                return <ResultThantai result={result} key={key} />;
            case 11:
                return <Result123 result={result} key={key} />;
            case 12:
                return <ResultMax3DProCard result={result} key={key} />;
            case 14: 
            return <ResultKTMN result={result} key={key} />;
            default:
                break;
        }
    }

    generateContent() {
        if(this.productId==14){
            return <ResultKTMN  />;
          }
        return (
            <>
                {
                    this.state.result.map((result, index) => (
                        <Col key={index} >
                            <Row className="p-2 border-bottom">
                                <Col>
                                    <Row className="mb-1">
                                        <Col className="col-3">
                                            Kỳ: #{result.id}
                                        </Col>
                                        <Col className="text-right">
                                            Ngày: #{result.date}
                                        </Col>
                                    </Row>
                                    {
                                        this.renderResultCard(result, index)
                                    }
                                </Col>
                            </Row>
                        </Col>
                    ))
                }
                <ButtonLoading
                    className="w-100"
                    loading={this.state.isLoading}
                    title="Xem thêm"
                    onClick={this.handleLoadMore.bind(this)}
                />
            </>
        )
    }
    getSelected = () => {
        switch (this.productId) {
            case 6:
                return 0;
            case 7:
                return 5;
            case 9:
                return 6;
            case 10:
                return 7;
            case 11:
                return 8;
            case 12:
                return 3;
            case 14:
                return 6;
        }
        return this.productId;
    };
    render() {
        return (
            <div className="bootstrap-reset h-100">
                <div className="home-page bg-white">
                    <div className="h-100 overflow-auto">
                        {this.state.isLoaded ? (
                            <Tabs
                                selectedIndex={this.getSelected()}
                                onSelect={this.handleSelectTab.bind(this)}
                            >
                                <TabList>
                                    <Tab>Keno</Tab>
                                    <Tab>Mega</Tab>
                                    <Tab>Power</Tab>
                                    <Tab>Max3D Pro</Tab>
                                    <Tab>Max3D</Tab>
                                    <Tab>XSMB</Tab>
                                    <Tab>XSMN</Tab>
                                    <Tab>6x36</Tab>
                                    <Tab>Thần tài</Tab>
                                    <Tab>Điện toán 123</Tab>
                                </TabList>
                                <TabPanel>{this.generateContent()}</TabPanel>
                                <TabPanel>{this.generateContent()}</TabPanel>
                                <TabPanel>{this.generateContent()}</TabPanel>
                                <TabPanel>{this.generateContent()}</TabPanel>
                                <TabPanel>{this.generateContent()}</TabPanel>
                                <TabPanel>{this.generateContent()}</TabPanel>
                                <TabPanel>{this.generateContent()}</TabPanel>
                                <TabPanel>{this.generateContent()}</TabPanel>
                                <TabPanel>{this.generateContent()}</TabPanel>
                                <TabPanel>{this.generateContent()}</TabPanel>
                            </Tabs>
                        ) : (
                            <LoadingPage />
                        )}
                    </div>
                    <div className="home-page__footer py-2 d-flex bgNav">
                        <div className="w-50 text-center">
                            <Link to={"/"} className="link-unset">
                                <div>
                                    <Grid color={window.location.pathname === "/" ? "#fff" : "#eacad3"} />
                                </div>
                                <div>
                                    <span
                                        style={{
                                            fontSize: "0.875rem",
                                            color: window.location.pathname === "/" ? "#fff" : "#eacad3"
                                        }}
                                    >Đặt vé</span>
                                </div>
                            </Link>
                        </div>
                        <div className="w-50 text-center">
                            <a href="/huong-dan" className="link-unset" target={'_blank'}>
                                <div>
                                    <Book color={window.location.pathname === "/huong-dan" ? "#fff" : "#eacad3"} />
                                </div>
                                <div>
                                    <span
                                        style={{
                                            fontSize: "0.875rem",
                                            color: window.location.pathname === "/huong-dan" ? "#fff" : "#eacad3"
                                        }}
                                    >Hướng dẫn</span>
                                </div>
                            </a>
                        </div>
                        {/* <div className="w-50 text-center">
                        <Link to={"/truc-tiep-keno"} className="link-unset">
                            <div>
                            <div class="livenow">
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>                  
                            </div>
                            </div>
                            <div>
                                <span
                                    style={{
                                        fontSize: "0.875rem",
                                        color: window.location.pathname === "/truc-tiep-keno" ? "#fff" : "#eacad3"
                                    }}
                                >Trực tiếp keno</span>
                            </div>
                        </Link>
                    </div> */}
                        <div className="w-50 text-center">
                            <Link to={"/result"} className="link-unset">
                                <div>
                                    <HardDrive color={window.location.pathname === "/result" ? "#fff" : "#eacad3"} />
                                </div>
                                <div>
                                    <span
                                        style={{
                                            fontSize: "0.875rem",
                                            color: window.location.pathname === "/result" ? "#fff" : "#eacad3"
                                        }}
                                    >Kết quả</span>
                                </div>
                            </Link>
                        </div>



                    </div>

                </div>
            </div>
        );
    }
}

export default MainResult;
