




export default ({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    display:'flex'
  },
  centeredViewContent: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#00000070',
    display:'flex'
  },
  modalView: {
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 10,
    alignItems: 'center',
    shadowColor: '#000',
    display:'flex',
    flexDirection:'column'
    //height: height / 2,
  },
  openButton: {
    backgroundColor: 'purple',
    borderRadius: 20,
    padding: 10,
    width: 150,
    elevation: 2,
    margin:10,
    textAlign:'center'
  },
  submitButton: {
    backgroundColor: 'red',
    borderRadius: 20,
    padding: 10,
    width: 100,
    elevation: 2,
  },
  submitButtonInactve: {
    backgroundColor: 'gray',
    borderRadius: 20,
    padding: 10,
    borderStyle:'solid',
    width: 100,
    elevation: 2,
  },
  textStyle: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  modalText: {
    marginBottom: 15,
    textAlign: 'center',
  },
  textNumberEnable: {
    fontSize: 20,
    color: 'red',
    height: 30,
    width: 30,
    textAlign: 'center',
    borderRadius: 30/2,
    borderColor: 'red',
    borderWidth: 1,
    borderStyle:'solid',
    marginRight: 10,
    marginRight: 8,
    marginBottom: 10,
  },
  textNumberActive: {
    fontSize: 20,
    color: '#fff',
    height: 30,
    width: 30,
    textAlign: 'center',
    borderRadius: 50,
    borderColor: "red",
    backgroundColor: 'red',
    borderWidth: 1,
    marginLeft: 5,
    marginTop: 3,
    marginBottom: 8,
  },
  flatListCenter: {    
    textAlign: 'center',
    justifyContent: 'center',
    
    paddingLeft: 10,
  },
  listNumber: {
    display: 'flex',
    alignItems: 'center',
  },
  listButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection:'row'
  },
  buttonSlectRange: {
   // marginLeft: width / 10,
   // marginBottom: height / 60,
    alignItems: 'center',
  },
  buttonSlectRange2: {
   // marginLeft: width / 10,
    alignItems: 'center',
  },
});
