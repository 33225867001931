import React, {Component} from 'react';

import {
    Row
} from "reactstrap";

import ListItem from "../../components/Vietluck/ListItem";

import {Link} from "react-router-dom";

const logo = {
    "vnpay": require("../../assets/images/icon/vnpay.png"),
    "bank": require("../../assets/images/icon/otherbank.png"),
    "momo": require("../../assets/images/icon/momo.png"),
    "viettel": require("../../assets/images/icon/viettelpay.png"),
    "zalo": require("../../assets/images/icon/zalopay.png"),
};

class Recharge extends Component {
    render() {
        return (
            <div className="p-2 bootstrap-reset">
                <Row className="mb-3">iLOTO hỗ trợ người chơi nạp tiền vào tài khoản thông qua các hình thức
                                      sau:</Row>
                {/* <Link to={"/recharge-items#1"} className="link-unset">
                    <ListItem
                        avatar={logo["vnpay"]}
                        title="Nạp tiền qua VNPAY"
                        subtitle={"Nhận tiền ngay"}
                    />
                </Link> */}
                <Link to={"/recharge-items#2"} className="link-unset">
                    <ListItem
                        avatar={logo["bank"]}
                        title="Chuyển tiền qua tài khoản ngân hàng"
                        subtitle={"Xử lý trong 1 phút"}
                    />
                </Link>
                {/* <Link to={"/recharge-items#3"} className="link-unset">
                    <ListItem
                        avatar={logo["momo"]}
                        title="Nạp tiền qua Momo"
                        subtitle={"Xử lý trong 15 phút"}
                    />
                </Link>  */}
               {/* <Link to={"/recharge-items#4"} className="link-unset">
                    <ListItem
                        avatar={logo["viettel"]}
                        title="Nạp tiền chuyển khoản qua ViettelPay"
                        subtitle={"Xử lý trong 15 phút"}
                    />
                </Link>
                <Link to={"/recharge-items#5"} className="link-unset">
                    <ListItem
                        avatar={logo["zalo"]}
                        title="Nạp tiền chuyển khoản qua ZaloPay"
                        subtitle={"Xử lý trong 15 phút"}
                    />
                </Link> */}
            </div>
        );
    }
}

export default Recharge;
