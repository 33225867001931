import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {
    Row, Col
} from "reactstrap";
import utils from "../../utils";
import {Phone, Mail, Facebook} from "react-feather";
import {Helmet} from "react-helmet";
import colors from "../../utils/colors";
const logo = require("../../assets/images/logo/dien-toan.png");
const content = `<div style="padding:12px"><style>
table{
    border: #F15922 1px solid;
    width:100%
}
table th{
background-color: #F15922;
    color: #ffffff;
    border-color: #f7a182;
    text-align: center;
    padding: 7px;
    border-width: 1px;
    border-style: solid;
}
    table td{
    border-color: #F15922;
    border-right-color: #f7a182;
        text-align: center;
    padding: 7px;
    border-width: 1px;
    border-style: solid;
}
</style><h4>Vé số MAX 3D Pro</h4>
<h3>Giới thiệu</h3>
<ul>
<li>Chỉ từ<span>&nbsp;</span><strong>10.000đ</strong>, chọn<span>&nbsp;</span><strong>2 số có 3 chữ số</strong><span>&nbsp;</span>từ 000-999 để có cơ hội trúng thưởng nhiều giải thưởng khác nhau.</li>

</ul>
<h3>Luật chơi</h3>
<p><strong>MAX 3D PRO CƠ BẢN</strong></p>
<p><span>Chọn 2 bộ số có 3 chữ số từ 000 đến 999 để tham gia dự thưởng </span><span><br></span><span>Ví dụ: 888 - 999</span></p>
<p><strong>MAX 3D PRO BAO BỘ SỐ</strong></p>
<p><span>Chọn cách chơi bao bộ số</span></p>
<p><span>Sau đó chọn 2 bộ số có 3 chữ số từ 000 đến 999 để tham gia dự thưởng.</span></p>
<p><span>Hệ thống sẽ đảo các con số trong số đầu và số sau để tạo ra tất cả các bộ số&nbsp;</span></p>
<p><strong>CÁCH TẠO RA BỘ SỐ</strong></p>
<p><span>Ví dụ bạn chọn bộ số 234 - 567 máy tính sẽ tạo cho bạn 36 bộ số dự thưởng như sau:&nbsp;</span></p>
<table style="border-collapse: collapse; margin-left: auto; margin-right: auto;">
<tbody>
<tr>
<td>
<p><span>234 - 567</span></p>
</td>
<td>
<p><span>243 - 567</span></p>
</td>
<td>
<p><span>324 - 567</span></p>
</td>
<td>
<p><span>342 - 567</span></p>
</td>
<td>
<p><span>423 - 567</span></p>
</td>
<td>
<p><span>432 - 567</span></p>
</td>
</tr>
<tr>
<td>
<p><span>234 - 576</span></p>
</td>
<td>
<p><span>243 - 576</span></p>
</td>
<td>
<p><span>324 - 576</span></p>
</td>
<td>
<p><span>342 - 576</span></p>
</td>
<td>
<p><span>423 - 576</span></p>
</td>
<td>
<p><span>432 - 576</span></p>
</td>
</tr>
<tr>
<td>
<p><span>234 - 657</span></p>
</td>
<td>
<p><span>243 - 657</span></p>
</td>
<td>
<p><span>324 - 657</span></p>
</td>
<td>
<p><span>342 - 657</span></p>
</td>
<td>
<p><span>423 - 657</span></p>
</td>
<td>
<p><span>432 - 657</span></p>
</td>
</tr>
<tr>
<td>
<p><span>234 - 675</span></p>
</td>
<td>
<p><span>243 - 675</span></p>
</td>
<td>
<p><span>324 - 675</span></p>
</td>
<td>
<p><span>342 - 675</span></p>
</td>
<td>
<p><span>423 - 675</span></p>
</td>
<td>
<p><span>432 - 675</span></p>
</td>
</tr>
<tr>
<td>
<p><span>234 - 756</span></p>
</td>
<td>
<p><span>243 - 756</span></p>
</td>
<td>
<p><span>324 - 756</span></p>
</td>
<td>
<p><span>342 - 756</span></p>
</td>
<td>
<p><span>423 - 756</span></p>
</td>
<td>
<p><span>432 - 756</span></p>
</td>
</tr>
<tr>
<td>
<p><span>234 - 765</span></p>
</td>
<td>
<p><span>243 - 765</span></p>
</td>
<td>
<p><span>324 - 765</span></p>
</td>
<td>
<p><span>342 - 765</span></p>
</td>
<td>
<p><span>423 - 765</span></p>
</td>
<td>
<p><span>432 - 765</span></p>
</td>
</tr>
</tbody>
</table>
<p>&nbsp;</p>
<p><strong>ƯU ĐIỂM KHI MUA VÉ BAO</strong></p>
<p><span>- Có thể mua 1 lúc nhiều bộ số khác nhau từ 2 bộ số đã chọn ban đầu.</span></p>
<p><span>- Tăng tỷ lệ trúng thưởng khi mua vé.</span></p>
<p><strong>MAX 3D PRO BAO NHIỀU SỐ</strong></p>
<p><span>Ví dụ bạn chọn </span><span>123</span><span> - </span><span>234</span><span> - 345 sẽ tạo thành 6 bộ số dự thưởng như sau.</span></p>
<table style="border-collapse: collapse; width: 48.0994%; height: 59px; margin-left: auto; margin-right: auto;" border="1">
<tbody>
<tr style="height: 49px;">
<td style="width: 31.2683%; text-align: center; height: 49px;"><strong>123 - 234</strong></td>
<td style="width: 31.2683%; text-align: center; height: 49px;"><strong>234 - 123</strong></td>
<td style="width: 31.2728%; text-align: center; height: 49px;"><strong>345 - 123</strong></td>
</tr>
<tr style="height: 10px;">
<td style="width: 31.2683%; text-align: center; height: 10px;">
<p><strong>123 - 345</strong></p>
</td>
<td style="width: 31.2683%; text-align: center; height: 10px;"><strong>234 - 345</strong></td>
<td style="width: 31.2728%; text-align: center; height: 10px;"><strong>345 - 234</strong></td>
</tr>
</tbody>
</table>

<table style="border-collapse: collapse; margin-left: auto; margin-right: auto;">
<tbody>
<tr>
<td>
<p><strong>SỐ LƯỢNG SỐ CHỌN</strong></p>
</td>
<td>
<p><strong>SỐ LƯỢNG BỘ SỐ TẠO RA</strong></p>
</td>
<td>
<p><strong>GIÁ TRỊ DỰ THƯỞNG</strong></p>
</td>
</tr>
<tr>
<td style="text-align: center;">
<p><span>3</span></p>
</td>
<td style="text-align: center;">
<p><span>6</span></p>
</td>
<td style="text-align: center;">
<p><span>60.000 đồng</span></p>
</td>
</tr>
<tr>
<td style="text-align: center;">
<p><span>4</span></p>
</td>
<td style="text-align: center;">
<p><span>12</span></p>
</td>
<td style="text-align: center;">
<p><span>120.000 đồng</span></p>
</td>
</tr>
<tr>
<td style="text-align: center;">
<p><span>5</span></p>
</td>
<td style="text-align: center;">
<p><span>20</span></p>
</td>
<td style="text-align: center;">
<p><span>200.000 đồng</span></p>
</td>
</tr>
<tr>
<td style="text-align: center;">
<p><span>6</span></p>
</td>
<td style="text-align: center;">
<p><span>30</span></p>
</td>
<td style="text-align: center;">
<p><span>300.000 đồng</span></p>
</td>
</tr>
<tr>
<td style="text-align: center;">
<p><span>7</span></p>
</td>
<td style="text-align: center;">
<p><span>42</span></p>
</td>
<td style="text-align: center;">
<p><span>420.000 đồng</span></p>
</td>
</tr>
<tr>
<td style="text-align: center;">
<p><span>8</span></p>
</td>
<td style="text-align: center;">
<p><span>56</span></p>
</td>
<td style="text-align: center;">
<p><span>560.000 đồng</span></p>
</td>
</tr>
<tr>
<td style="text-align: center;">
<p><span>9</span></p>
</td>
<td style="text-align: center;">
<p><span>72</span></p>
</td>
<td style="text-align: center;">
<p><span>720.000 đồng</span></p>
</td>
</tr>
<tr>
<td style="text-align: center;">
<p><span>10</span></p>
</td>
<td style="text-align: center;">
<p><span>90</span></p>
</td>
<td style="text-align: center;">
<p><span>900.000 đồng</span></p>
</td>
</tr>
<tr>
<td style="text-align: center;">
<p><span>11</span></p>
</td>
<td style="text-align: center;">
<p><span>140</span></p>
</td>
<td style="text-align: center;">
<p><span>1.400.000 đồng</span></p>
</td>
</tr>
</tbody>
</table>
<p>&nbsp;</p>

<p><strong>VÉ MAX 3D PRO ĐẢO SỐ.</strong></p>
<p><span>Bạn sẽ được chọn đảo bộ số thứ nhất hoặc bộ số thứ 2.</span></p>
<p><span>Ví dụ bạn chọn đảo bộ số thứ nhất bộ số đầu tiên mà bạn chọn sẽ được đảo thứ tự các số trong số thứ nhất số thứ hai giữ nguyên, bộ số bạn chọn 123 - 456&nbsp;</span></p>
<p><span>Máy tính sẽ tạo cho các bộ số sau.</span></p>
<table style="border-collapse: collapse; margin-left: auto; margin-right: auto;">
<tbody>
<tr>
<td>
<p><strong>123 - 456</strong></p>
</td>
<td>
<p><strong>132 - 456</strong></p>
</td>
<td>
<p><strong>213 - 456</strong></p>
</td>
</tr>
<tr>
<td>
<p><strong>231 - 456</strong></p>
</td>
<td>
<p><strong>321 - 456</strong></p>
</td>
<td>
<p><strong>312 - 456</strong></p>
</td>
</tr>
</tbody>
</table>
<p><span>Đảo số thứ hai cũng tương tự đảo số thứ nhất.</span></p>
<p><strong>VÉ MAX 3D PRO ÔM MỘT HOẶC HAI VỊ TRÍ.</strong></p>
<p><span>Bạn sẽ chọn 1 vị trí trong bộ số để cố định vị trí đó sau đó máy tính sẽ tiến hành thay số mà bạn cố định bằng các số từ 0-9&nbsp;</span></p>
<p><span>Ví dụ bạn chọn ôm 1 vị trí bộ số *23 - 456 các bộ số được tạo ra.</span></p>
<p>&nbsp;</p>
<table style="border-collapse: collapse; margin-left: auto; margin-right: auto;">
<tbody>
<tr>
<td>
<p><strong>023 - 456</strong></p>
</td>
<td>
<p><strong>523 - 456</strong></p>
</td>
</tr>
<tr>
<td>
<p><strong>123 - 456</strong></p>
</td>
<td>
<p><strong>623 - 456</strong></p>
</td>
</tr>
<tr>
<td>
<p><strong>223 - 456</strong></p>
</td>
<td>
<p><strong>723 - 456</strong></p>
</td>
</tr>
<tr>
<td>
<p><strong>323 - 456</strong></p>
</td>
<td>
<p><strong>823 - 456</strong></p>
</td>
</tr>
<tr>
<td>
<p><strong>423 - 456</strong></p>
</td>
<td>
<p><strong>923 - 456</strong></p>
</td>
</tr>
</tbody>
</table>
<p>&nbsp;</p>
<p><span>Chọn ôm 2 vị trí sẽ tương tự như vậy.</span></p>
<p><strong>CƠ CẤU GIẢI THƯỞNG</strong></p>
<section class="prizes">
<table class="max3d table-striped table-hover">
<tbody>
<tr>
<th>Cơ cấu giải thưởng</th>
<th>Dãy số tham gia</th>
<th>Giá trị giải thưởng</th>
</tr>
<tr>
<td>Giải Đặc biệt</td>
<td>Trùng hai bộ ba số quay thưởng giải Đặc biệt theo đúng thứ tự quay</td>
<td>2.000.000.000đ</td>
</tr>
<tr>
<td>Giải phụ Đặc biệt</td>
<td>Trùng hai bộ ba số quay thưởng giải Đặc biết ngược thứ tự quay</td>
<td>400.000.000đ</td>
</tr>
<tr>
<td>Giải Nhất</td>
<td>Trùng bất kỳ 2 trong 4 bộ ba số quay thưởng giải Nhất</td>
<td>30.000.000đ</td>
</tr>
<tr>
<td>Giải Nhì</td>
<td>Trùng bất kỳ 2 trong 6 bộ ba số quay thưởng giải Nhì</td>
<td>10.000.000đ</td>
</tr>
<tr>
<td>Giải Ba</td>
<td>Trùng bất kỳ 2 trong 8 bộ ba số quay thưởng giải Ba</td>
<td>4.000.000đ</td>
</tr>
<tr>
<td>Giải Tư</td>
<td>Trùng bất kỳ 2 bộ ba số quay thưởng của giải Đặc biệt, Nhất, Nhì hoặc Ba</td>
<td>1.000.000đ</td>
</tr>
<tr>
<td>Giải Năm</td>
<td>Trùng 1 bộ ba số quay thưởng giải Đặc biệt bất kỳ</td>
<td>100.000đ</td>
</tr>
<tr>
<td>Giải Sáu</td>
<td>Trùng 1 bộ ba số quay thưởng giải Nhất, Nhì hoặc Ba bất kỳ</td>
<td>40.000đ</td>
</tr>
</tbody>
</table>
</section>
<div>
<h4>Lưu ý</h4>
<p><strong>Cơ cấu giải thưởng áp dụng với vé dự thưởng gồm hai số ba chữ số khác nhau.</strong></p>
<ul>
<li style="font-weight: 400;" aria-level="1"><span>Nếu người chơi chọn hai số ba số giống nhau, giá trị giải thưởng sẽ cao gấp đôi giá trị nếu ở bảng trên ( trừ giải Đặc Biệt và Giải Nhất ).</span></li>
<li style="font-weight: 400;" aria-level="1"><span>Trường hợp hai bộ số của người chơi trùng nhiều giải thưởng, người chơi sẽ được lãnh tất cả các giải thưởng.</span></li>
<li style="font-weight: 400;" aria-level="1"><span>Giá trị lĩnh thưởng theo số lần tham gia dự thưởng số trúng thưởng ( 01 lần tham gia dự thưởng mệnh giá 10.000 đồng ) nhân với giá trị thưởng tương ứng với 01 lần tham gia dự thưởng.</span></li>
<li style="font-weight: 400;" aria-level="1"><span>Giải thưởng ứng với 1 lần dự thưởng có mệnh giá&nbsp;<strong>10.000đ</strong>. Mua càng nhiều giải thưởng sẽ càng được nhân lên nhiều lần </span><span>( Bạn mua bộ số giá <strong>10.000</strong> đồng khi trúng giải Đặc Biệt bạn sẽ nhận được <strong>2.000.000.000</strong> đồng, bạn mua bộ số giá <strong>20.000</strong> đồng khi trúng giải Đặc Biệt bạn sẽ nhận được <strong>4.000.000.000</strong> đồng….)</span></li>
</ul>
<p>&nbsp;</p>

</div></div>`

class Max3dPro extends Component {
    constructor(props)
    {
        super(props);
        this.state={seo:{}}
    }
    async componentDidMount(){            
    }
    render() {
        return (
            <div className='p2'>
                <div dangerouslySetInnerHTML={{__html: content}} />
        
            </div>
        );
    }
}

export default Max3dPro;
