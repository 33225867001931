import React, { Component } from 'react';

import { connect } from "react-redux";


import {
  Row, Col,
  Button,
  Input
} from "reactstrap";
import ModalKienThiet from '../../../components/Vietluck/ModalKienThiet/ModalKienThiet';
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch, faDice
} from "@fortawesome/free-solid-svg-icons"
import { ModalImageLucky } from "../../../components/Vietluck/ModalVietluck";
import utils from "../../../utils";
import ErrorMessage from '../../../utils/ErrorMessage'
import moment from "moment";
import apiOrder from "../../../api/Order";
class KienThiet extends Component {
  constructor(props) {
    super(props);
    this.flatListRef = null;
    this.state = {
      kienthietListFilter: [],
      kienthietListGet: [],
      moneyTicket: 0,
      loading: false,
      numberMaxTicket: [],
      thantaiDraw: false,
      moneyTicket: 10,
      sumMoney: { pre: 0, keep: 0, sum: 0 },
      numberSelect: null,
      modalVisible: false,
      isShow_paymentOk: false,
      drawDate: '',
      allDraw: [],
      selectedCodes: []
    }
  }
  componentDidMount() {
    apiOrder.getKienThietDraw((err, res) => {
      if (!err) {
        let draw = [];
        res.map(e => {
          draw.push({
            id: moment(e.kOpenTime, 'DD-MM-YYYY HH:mm:ss').format('DD/MM/YYYY'),
            name: moment(e.kOpenTime, 'DD-MM-YYYY HH:mm:ss').format('DD/MM/YYYY')
          })
        });
        this.setState({ allDraw: draw, drawDate: draw[0].id });
        this.getTicket(moment(res[0].kOpenTime, 'DD-MM-YYYY HH:mm:ss').format('DD/MM/YYYY'));
      }
    })
  }
  getTicket(openTime) {
    if (this.state.loading) return;
    else this.setState({ isLoading: true });
    apiOrder.getKienthietAvailable(openTime, (err, res) => {
      if (err) {
        utils.alert(err);
      }
      else {
        let kienThietList = res;
        kienThietList.map(item => {
          item.TicketDefaultCanBuy = item.kNumberTicketNotBuy;
        })
        this.setState({
          kienthietListFilter: kienThietList,
          kienthietListGet: kienThietList
        })
        if (kienThietList[0])
          this.setState({ moneyTicket: kienThietList[0].kPrize })
      }
      this.setState({ loading: false })
    })
  }
  checkPrice() {
    apiOrder.checkKienthietPrice(this.state.numberSelect.kID, this.state.numberSelect.vesoChosen, (err, res) => {
      if (!err) {

        this.setState({
          sumMoney: {
            pre: res[0].totalPrice - res[0].servicePrice, keep: res[0].servicePrice, sum: res[0].totalPrice
          }
        })
      }
      else {
        utils.alert(err);
        this.getTicket();
        this.cancelAll();
      }
    })
  }
  openModal(item) {

    if (this.state.numberSelect) return;

    if (this.state.kienthietListGet.length > 0) {

      const NumberList = [];
      const minNumber = item.kMinBuyTicket ? item.kMinBuyTicket : 1
      for (let i = minNumber; i <= item.kNumberTicketNotBuy; i += 5) {
        NumberList.push({ key: i });
      }

      apiOrder.getKienthietDetail(item.kID, (err, res) => {
        if (!err) {

          this.setState({
            numberMaxTicket: NumberList,
            modalVisible: true,
            numberSelect: res
          })
        }
      })


    }
  }
  setNumberFromChosen = data => {
    let newCodes = this.state.numberSelect.kTickets.slice(0, data.first);
    this.setState({ selectedCodes: newCodes });
    return;


    // setRef.current = true;
  }
  countMoney = () => {
    let numberTicket = 0;
    this.state.kienthietListFilter.map(item => {
      if (item.vesoChosen) numberTicket += item.vesoChosen;
    })
    const sumMoneyPre = numberTicket * this.state.moneyTicket;
    this.setState({ sumMoney: { pre: sumMoneyPre, keep: sumMoneyPre * 0.4, sum: sumMoneyPre + sumMoneyPre * 0.4 } })

  };
  submitBuy() {
    this.buyTicket()
  }
  thanTaiLucky = () => {
    if (this.state.kienthietListGet.length == 0)
      return;
    //setThantaiDraw(true);
    let lengthTicket = this.state.kienthietListGet.length;
    let randomNumber = Math.floor(Math.random() * Math.floor(lengthTicket + 1));
    while (true) {
      if (this.state.kienthietListFilter[randomNumber - 1])
        if (this.state.kienthietListFilter[randomNumber - 1].NumberTicketNotBuy != 0) {
          break;
        }
      randomNumber = Math.floor(Math.random() * Math.floor(lengthTicket + 1));
    }
    // let index=0;
    // if(randomNumber>=this.flatListRef.numColumns)
    // index=this.flatListRef.numColumns

    // this.flatListRef.scrollToIndex({ index: index, viewPosition:0.5 });
    this.openModal(this.state.kienthietListFilter[randomNumber - 1]);

  }
  onchangeTextSearch = text => {
    text = text.target.value
    const filter = this.state.kienthietListGet.filter(item => item.kTicket.includes(text))
    this.setState({
      kienthietListFilter: filter
    })
  }
  buyTicket() {
    if (this.state.loading) return;
    else this.setState({ loading: true })
    let data = {
      kId: this.state.numberSelect.kID,
      numberOfTicket: this.state.numberSelect.vesoChosen,
      dataTicket: this.state.selectedCodes,
      shipment: {
        "type": 2,
        "fullName": this.props.user.fullName,
        "phoneNumber": this.props.user.phoneNumber,
        "idCardNumber": this.props.user.idCardNumber,
        "cityId": this.props.user.cityId,
        "districtId": this.props.user.districtId,
        "address": this.props.user.address
      }
    }
    console.log(data);
    //return;
    apiOrder.buyKienthiet(data, (err, res) => {
      if (err) {
        //ErrorMessage(err);
        // utils.alert(err);

      } else {
        this.setState({
          isShow_paymentOk: true
        })
      }
      this.setState({ loading: false })
    })

  }
  cancelAll = () => {
    this.setState({
      kienthietListFilter: this.state.kienthietListGet,
      numberSelect: null,
      sumMoney: { pre: 0, keep: 0, sum: 0 },
      selectedCodes: []
    })

  }
  selectDraw = (date) => {
    this.setState({ drawDate: date });
    this.getTicket(date)
    this.cancelAll()
  }
  selectCode(code) {
    let newCodes = this.state.selectedCodes;
    if (newCodes.indexOf(code) != -1) {
      newCodes = this.state.selectedCodes.filter(e => e != code);
    }
    else
      newCodes.push(code);
    this.setState({ selectedCodes: [].concat(newCodes) });
  }
  finishModal = () => {
    if (this.state.selectedCodes.length >= this.state.numberSelect.kMinBuyTicket || (this.state.numberSelect.kNumberTicketNotBuy < this.state.numberSelect.kMinBuyTicket && this.state.selectedCodes.length < this.state.numberSelect.kMinBuyTicket)) {

      let numberSelect = { ...this.state.numberSelect };
      numberSelect.vesoChosen = this.state.selectedCodes.length;
      numberSelect.kNumberTicketNotBuy = this.state.numberSelect.TicketDefaultCanBuy;
      numberSelect.kNumberTicketNotBuy = this.state.numberSelect.kNumberTicketNotBuy - this.state.selectedCodes.length;
      const indexTicket = this.state.kienthietListFilter.findIndex(item => item.kTicket == this.state.numberSelect.kTicket);
      let newKienThietList = [...this.state.kienthietListFilter]

      newKienThietList[indexTicket] = numberSelect
      console.log(indexTicket)
      //this.countMoney();
      console.log(numberSelect)
      this.setState({ modalVisible: false, kienthietListFilter: newKienThietList, numberSelect: numberSelect }, () => {
        this.checkPrice();
      })
    }
    else {
      utils.alert('Vé mua tối thiều là ' + this.state.numberSelect.kMinBuyTicket)
    }
  }
  render() {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <ModalImageLucky
          isOpen={this.state.isShow_paymentOk}
          toggle={() => {

            this.props.history.push("/")
          }}
        />
        <ModalKienThiet modalVisible={this.state.modalVisible}
          setModalVisible={e => this.setState({ modalVisible: e, numberSelect: null, selectedCodes: [] })}
          setValueNumber={e => this.setNumberFromChosen(e)}
          selectCode={e => this.selectCode(e)}
          selectedCodes={this.state.selectedCodes}
          maxNumber={this.state.numberMaxTicket} ticket={this.state.numberSelect}
          finishModal={() => this.finishModal()} />
        <div >
          <div style={styles.header}>
            <div style={styles.balancecontainer}>
              <span style={styles.balancetitle}>Số dư tài khoản: </span>
              <span style={styles.balancemoney}>{(this.props.user.availableBalance * 1000).getMoneyFormat()} ₫</span>
            </div>
          </div>
          <div style={{ ...styles.rowSpace, padding: 10 }}>
            <div style={{ ...styles.rowSpace, ...styles.searchContainer, width: '50%', marginRight: 10 }}>
              <FontAwesomeIcon
                icon={faSearch}
                style={{ marginRight: 2, marginLeft: 2 }}

              />
              <Input placeholder='Tìm nhanh số' onChange={(text) => this.onchangeTextSearch(text)} style={{ flex: 1, borderStyle: 'none' }} />
            </div>
            <a style={{ ...styles.rowSpace, borderRadius: 5, padding: 10, borderWidth: 1, borderColor: '#D8D8D8', borderStyle: 'solid' }} onClick={() => this.thanTaiLucky()}>
              <FontAwesomeIcon
                icon={faDice}
                style={{ marginRight: 2 }}
                color='#3a52bc'
              />
              <span style={styles.balancetitle}>Thần tài</span>
            </a>
          </div>
          <div style={{ paddingLeft: 10, paddingRight: 10 }}>
            {this.state.allDraw.length > 0 && <Input
              type="select"
              style={{ textAlign: 'center' }}
              onChange={(event) => {
                this.selectDraw(event.target.value)
              }}
              checked={this.state.drawDate}

            >
              {
                this.state.allDraw.map(({ id, name }) => (
                  <option key={id.toString()} value={id}>
                    {name}
                  </option>
                ))
              }
            </Input>}
          </div>
        </div>
        <div style={styles.mainContainer}>
          {
            this.state.kienthietListFilter.map((item, i) => {
              return <a style={styles.listKienThiet} onClick={() => this.openModal(item)}>
                <div style={item.vesoChosen ? styles.numberListActive : styles.numberList}>
                  <div style={item.vesoChosen ? styles.numberFirstActive : styles.numberFirst}>
                    <span style={item.vesoChosen ? styles.numberFirstTextActive : styles.numberFirstText}>{item.vesoChosen ? item.vesoChosen : ''}</span>
                  </div>
                  <div style={styles.numberSecond}>
                    <span style={item.kNumberTicketNotBuy > 0 ? styles.numberSecondText : styles.numberSecondTextNotBuy}>{item.kTicket}</span>
                  </div>
                  <div style={styles.numberThree}>
                    <span style={styles.numberThreeText}>{item.kNumberTicketNotBuy}</span>
                  </div>
                </div>
              </a>
            })
          }
        </div>
        {<div style={{ width: '100%' }}>
          <div style={{ alignItems: 'flex-end', marginRight: 15, display: 'flex', flexDirection: 'column' }}>
            <div>Tạm tính: {(this.state.sumMoney.pre * 1000).getMoneyFormat()}</div>
            <div>Giữ hộ: {(this.state.sumMoney.keep * 1000).getMoneyFormat()}</div>
            <div>Tổng: {(this.state.sumMoney.sum * 1000).getMoneyFormat()}</div>
          </div>

          <div style={styles.buttonSubmitList}>
            <a style={{ padding: 10, borderRadius: 5, justifyContent: 'center', flexDirection: 'row', backgroundColor: 'gray', flexGrow: 1, margin: 5, display: 'flex' }} full light onClick={() => this.cancelAll()} >
              <span style={{ color: '#000', fontSize: 17, fontWeight: 'bold' }}>Hủy</span>
            </a>
            <a style={{ padding: 10, borderRadius: 5, justifyContent: 'center', flexDirection: 'row', backgroundColor: 'red', margin: 5, flexGrow: 1, display: 'flex' }} full danger disabled={this.state.numberSelect == null} onClick={() => this.submitBuy()}>
              <span style={{ color: '#fff', fontSize: 17, fontWeight: 'bold' }}>Mua ngay</span>
            </a>


          </div>

        </div>}
      </div>
    )
  }
}
function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps, {})(KienThiet);
const styles = {
  mainContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    overflowY: 'scroll'

  },
  row: {
    flexDirection: 'row'
  },
  padIOS: {
    padding: 5
  },
  searchContainer: {
    // paddingBottom:5,
    borderColor: '#D8D8D8',
    borderBottomWidth: 1,
    borderRadius: 5,

    borderStyle: 'solid'
  },
  rowSpace: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex'
  },

  balancecontainer: {
    flexDirection: 'row',
    marginBottom: 5,
  },
  balancetitle: {
    fontSize: 15,
    color: '#000',

  },
  balancemoney: {
    fontSize: 15,
    color: 'red',
    fontWeight: 'bold',
  },

  header: {
    // backgroundColor: "blue",
    padding: 10,
  },
  listKienThiet: {
    width: '30%',
    height: 49,
    display: 'flex',
    textAlign: "center",
    justifyContent: 'center',
    marginLeft: 5,
    marginRight: 5
  },
  buttonSubmitList: {
    marginRight: 10,
    marginLeft: 10,
    flexDirection: 'row',
    display: 'flex'
  },
  numberList: {
    justifyContent: 'center',
    borderRadius: 5,
    width: '100%',
    textAlign: "center",
    letterSpacing: 1,
    margin: 5,
    fontSize: 17,
    borderWidth: 1,
    borderColor: '#afafaf',
    marginLeft: 5,
    flexDirection: 'row',
    borderStyle: 'solid',
    display: 'flex',
  },
  numberListActive: {
    justifyContent: 'center',
    borderRadius: 5,
    textAlign: "center",
    letterSpacing: 1,
    margin: 5,
    fontSize: 17,
    borderWidth: 1,
    display: 'flex',
    marginLeft: 5,
    flexDirection: 'row',
    backgroundColor: '#ffc200',
    borderStyle: 'solid'
  },
  numberFirst: {
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,

  },
  numberFirstActive: {
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    backgroundColor: 'red',
    borderStyle: 'solid'
  },
  numberFirstText: {
    textAlign: "center",
    justifyContent: 'center',
    margin: 3
  },
  numberFirstTextActive: {
    color: '#fff',
    textAlign: "center",
    justifyContent: 'center',
    margin: 3
  },
  numberSecond: {

    flexGrow: 1,
    paddingLeft: 5,
    paddingRight: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  numberSecondText: {
    //marginTop: 3,
    color: '#000',
    textAlign: "center",
    justifyContent: 'center',
  },
  numberSecondTextNotBuy: {
    //marginTop: 3,
    color: '#000',
    textAlign: "center",
    justifyContent: 'center',
    textDecorationLine: "line-through"
  },
  numberThree: {
    // position:'absolute',
    top: 1,
    right: 1,

  },
  numberThreeText: {
    fontSize: 13,
    color: '#000'
  },
  containerKeyboard: {
    flex: 1,
  },
  buttonCancel: {
    marginTop: 10
  },
  guideContainer: {


    justifyContent: 'center',
    marginVertical: 10,
    //marginBottom:70,

    borderRadius: 10,
    display: 'flex'
  },
  guideText: {
    color: 'red',
    fontSize: 17,

  },
}