import React, {Component} from 'react';

import {
    Row, Col
} from "reactstrap";

import {connect} from "react-redux";
import {lotteryChange} from "../../redux/actions/lotterySelectorAction";
import utils from "../../utils";

import ModalSelector from "./ModalSelector";
import NumberCircle from "./NumberCircle";
import {ModalSelect} from "./ModalVietluck";

import {
    RefreshCcw, Trash
} from "react-feather";
/* eslint-disable */
const productDataset = require('../../utils/productData').productDataset;

export function checkValidValue(value, productId, productOptionId) {
    if (productId === 3) {
        if ([42, 45].includes(productOptionId)) {
            return !(value[0] === value[1] && value[1] === value[2] && value[2] === value[3]);
        }
    }
    return true;
}

export function getRandomValue(productId, productOptionId) {
    if (productOptionId !== 82) {
        let lengthValue = 2;
        if (productId === 4 || productId === 5) {
            lengthValue = 3;
        }
        if (productId === 3) {
            switch (productOptionId) {
                case 43:
                case 44:
                    lengthValue = 3;
                    break;
                default:
                    lengthValue = 4;
                    break;
            }
        }
        if(productId===7||productId===10 || productId==11){
            lengthValue=1;
        }
        if(productId==8)
            lengthValue=2;
        let productData = productDataset.find(dt => dt.id == productId);
        let productOption = productData.productOptionArray.find(dt => dt.id == productOptionId);
        let number = Math.floor(Math.random() * productOption.endNumber) + 1;
        number = utils.formatValue(number, lengthValue);
        while (!checkValidValue(number, productId, productOptionId)) {
            number = Math.floor(Math.random() * productOption.endNumber) + 1;
            number = utils.formatValue(number, lengthValue);
        }

        return number;
    } else { // Keno chẵn lẻ - lớn nhỏ
        let num = (Math.floor(Math.random() * 4) + 1).toString()
        return num;
    }
}

class LotterySelectorV2 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen_selectPrice: false,
            isOpen: false,
            indexSelector: 0
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.isRandom === true) {
            let value = utils.copyState(this.props.lotterySelector.value);
            for (let i = 0; i < this.getNumTicket(); i++) {
                value[i].number = this.handleRandom(i, true);
            }
            this.props.lotteryChange({value: value});
            this.props.doneRandom();
        }
    }

    handleRandom(id, options) {
        let changeValue = [];
        let change123=[];
        if(this.props.lotterySelector.productId!==11)
        for (let i = 0; i < this.props.lotterySelector.numberSelect; i++) {
            while (true) {
                let number = getRandomValue(this.props.lotterySelector.productId, this.props.lotterySelector.productOptionId);
                if (!changeValue.includes(number)) {
                    changeValue.push(number);
                    break;
                }
            }
        }
        else{
            for (let i = 0; i < 6; i++) {
                while (true) {
                    let number = getRandomValue(this.props.lotterySelector.productId, this.props.lotterySelector.productOptionId);
                    if (!changeValue.includes(number)) {
                        change123.push(number);
                        break;
                    }
                }
                changeValue[0]=change123[0];
                changeValue[1]=change123[1]+change123[2];
                changeValue[2]=change123[3]+change123[4]+change123[5];
            }

        }
        if(this.props.lotterySelector.productId<7 || this.props.lotterySelector.productId===9 || this.props.lotterySelector.productId===8)
        changeValue.sort();
        if (options) {
            return changeValue;
        }
        let value = utils.copyState(this.props.lotterySelector.value);
        value[id].number = changeValue;
        this.props.lotteryChange({value: value});
    }

    handleRemove(id) {
        let value = utils.copyState(this.props.lotterySelector.value);
        value[id].number = [];
        this.props.lotteryChange({value: value});
    }

    handleChangePrice(price) {
        let value = utils.copyState(this.props.lotterySelector.value);
        value[this.state.indexSelector].price = parseInt(price);
        this.props.lotteryChange({value: value});
        this.toggle('isOpen_selectPrice');
    }

    handleSelectNumberKeno2(rowIndex, number) {
        let value = utils.copyState(this.props.lotterySelector.value);
        value[rowIndex].number = [number.toString()];
        this.props.lotteryChange({value: value});
    }

    getNumTicket() {
        return this.props.lotterySelector.value.length;
    }

    toggle(component) {
        this.setState({
            [component]: !this.state[component]
        });
    }
    render123=()=>{
        
    }
    render() {
        const numTicket = this.getNumTicket();
        return (
            <Col>
                <ModalSelector
                    isOpen={this.state.isOpen}
                    onClose={this.toggle.bind(this, "isOpen")}
                    index={this.state.indexSelector}
                />
                <ModalSelect
                    title="Chọn mệnh giá"
                    isOpen={this.state.isOpen_selectPrice}
                    toggle={this.toggle.bind(this, "isOpen_selectPrice")}
                    checked={this.props.lotterySelector.value[this.state.indexSelector].price}
                    data={this.props.lotterySelector.priceArray}
                    keyId={"id"}
                    keyLabel={"price"}
                    onChange={this.handleChangePrice.bind(this)}
                />
                {
                    Array(numTicket).fill(0).map((row, rowIndex) => (
                        <Row className="w-100 align-items-center my-2" key={`rowIndex-${rowIndex}`}>
                            <div className="text-left" style={{width: "10%"}}>
                                <NumberCircle title={String.fromCharCode(rowIndex + 65)}/>
                            </div>
                            <div
                                className="text-center"
                                onClick={() => {
                                    if(this.props.lotterySelector.productOptionId !== 82) {
                                        this.setState({indexSelector: rowIndex, isOpen: true})
                                    }
                                }}
                                style={{width: [7,8,10,11].includes(this.props.lotterySelector.productId) ? "60%" : "80%"}}
                            >
                                {
                                    this.props.lotterySelector.productOptionId !== 91 ?
                                        <React.Fragment>
                                            {
                                                this.props.lotterySelector.value[rowIndex].number.map((number, i) => (
                                                    [3, 4, 5].includes(this.props.lotterySelector.productId)
                                                        ?
                                                        <NumberCircle
                                                            key={i.toString()}
                                                            price={number}
                                                            className="hover-pointer"
                                                        />
                                                        :
                                                        <NumberCircle
                                                            size={32}
                                                            key={i.toString()}
                                                            title={number}
                                                            className="mr-1 hover-pointer"
                                                        />
                                                ))
                                            }
                                            {
                                                Array(this.props.lotterySelector.numberSelect - this.props.lotterySelector.value[rowIndex].number.length).fill('').map((l, i) => (
                                                    [3, 4, 5].includes(this.props.lotterySelector.productId)
                                                        ?
                                                        <NumberCircle
                                                            key={i.toString()}
                                                            price={l}
                                                            className="hover-pointer"
                                                        />
                                                        :
                                                        <NumberCircle
                                                            key={i.toString()}
                                                            title={l}
                                                            className="mr-1 hover-pointer"
                                                        />
                                                ))
                                            }
                                        </React.Fragment>
                                        :
                                        <React.Fragment>

                                          {
                                               this.props.lotterySelector.value[rowIndex].number.map((number, i) => (
                                                i===2
                                                        ?
                                                        <NumberCircle
                                                            key={i.toString()}
                                                            price={number}
                                                            className="hover-pointer"
                                                        />:
                                                i===1? <NumberCircle
                                                            size={32}
                                                            key={i.toString()}
                                                            title={number}
                                                            className="mr-1 number-circle-3  hover-pointer"
                                                        />
                                                        :
                                                        <NumberCircle
                                                            size={32}
                                                            key={i.toString()}
                                                            title={number}
                                                            className="mr-1 hover-pointer"
                                                        />
                                                ))
                                        }
                                        {
                                            Array(this.props.lotterySelector.numberSelect - this.props.lotterySelector.value[rowIndex].number.length).fill('').map((l, i) => (
                                               i===2
                                                        ?
                                                        <NumberCircle
                                                            key={i.toString()}
                                                            price={l}
                                                            className="hover-pointer"
                                                        />:
                                                i===1? <NumberCircle
                                                            size={32}
                                                            key={i.toString()}
                                                            title={l}
                                                            className="mr-1 number-circle-3  hover-pointer"
                                                        />
                                                        :
                                                        <NumberCircle
                                                            size={32}
                                                            key={i.toString()}
                                                            title={l}
                                                            className="mr-1 hover-pointer"
                                                        />
                                                ))
                                        }
                                        </React.Fragment>
                                }
                            </div>
                            <div className="text-right">
                                {
                                    this.props.lotterySelector.value[rowIndex].number.length !== 0 ?
                                        <NumberCircle
                                            icon={<Trash size={15}/>}
                                            onClick={this.handleRemove.bind(this, rowIndex)}
                                            className="hover-pointer"
                                        />
                                        :
                                        <NumberCircle
                                            icon={<RefreshCcw size={15}/>}
                                            onClick={this.handleRandom.bind(this, rowIndex, false)}
                                            className="hover-pointer"
                                        />
                                }
                                {
                                    [7,8,10,11].includes(this.props.lotterySelector.productId) &&
                                    <NumberCircle
                                        price={this.props.lotterySelector.value[rowIndex].price.toString() + 'K'}
                                        className="ml-1 hover-pointer"
                                        onClick={() => {
                                            this.setState({indexSelector: rowIndex, isOpen_selectPrice: true})
                                        }}
                                    />
                                }
                            </div>
                        </Row>
                    ))
                }
            </Col>
        );
    }
}

function mapStateToProps(state) {
    return {
        lotterySelector: state.lotterySelector,
    };
}

export default connect(mapStateToProps, {lotteryChange})(LotterySelectorV2);
