import React, { Component } from "react";

import { Input, FormGroup, Row, Col, CustomInput } from "reactstrap";

import { Link, withRouter } from "react-router-dom";

import { Smartphone, Bell, Instagram, User } from "react-feather";

import ButtonLoading from "../../components/FworkTag/ButtonLoading";

import { connect } from "react-redux";
import { userChange } from "../../redux/actions/userAction";

import apiLogin from "../../api/Auth";

import ValidInput from "../../utils/ValidInput";
import utils from "../../utils";

import anime from "animejs";
import smalltalk from "smalltalk";
class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneNumber: "",
      password: "",
      confirmPassword: "",
      isLoading: false,
      isOpen: true,
      agree: false,
    };
  }

  componentDidMount() {
    const _this = this;
    this.closeDownload = anime({
      targets: ".app-download",
      scale: 0,
      duration: 500,
      easing: "linear",
      loop: false,
      autoplay: false,
      complete: function () {
        _this.toggle("isOpen");
      },
    });
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleRegister() {
    if (!this.state.agree) {
      utils.alert("Vui lòng đồng ý với điều khoản sử dụng của chúng tôi!");
      return;
    }

    if (this.state.isLoading) return;

    if (ValidInput.testPassword(this.state.password)) {
      return;
    }

    if (ValidInput.testPhoneNumber(this.state.phoneNumber)) {
      return;
    }

    if (this.state.password !== this.state.confirmPassword) {
      utils.alert("Xác nhận mật khẩu không chính xác!");
      return;
    }

    this.setState({ isLoading: true });

    apiLogin.register(
      {
        phoneNumber: this.state.phoneNumber,
        password: this.state.password,
      },
      (err, result) => {
        if (err) {
          this.setState({ isLoading: false });
        } else {
          apiLogin.login(
            {
              phoneNumber: this.state.phoneNumber,
              password: this.state.password,
            },
            (err, result) => {
              if (err) {
              } else {
                let user = utils.filterUserInfo(result);
                this.props.userChange(user);
                this.props.history.push("/");
                //this.props.history.push("/auth/otp");

              }
            }
          );
        }
      }
    );
  }

  toggle(component) {
    this.setState({
      [component]: !this.state[component],
    });
  }

  render() {
    return (
      <div className="d-flex flex-column h-100 bootstrap-reset overflow-hidden">
        <div className="h-100 overflow-auto">
          <Col className="px-4 py-2 h-100">
            <Row className="justify-content-center mb-2">
              {/*<img className={"logo"} src={"/img/logo/logo.png"} alt={"logo"} style={{width: "100px", height: "100px"}}/>*/}
              <Col>
                <Row className="justify-content-center py-1">
                Tải App mua Xổ số Online chính thống
                </Row>
                <Row className="pb-3 mt-1">
                  <Col col={6} className="px-3">
                    <a
                      href={
                        "https://play.google.com/store/apps/details?id=com.iloto"
                      }
                      target="_blank"
                    >
                      <img
                        src={"/img/logo/google-play.jpg"}
                        alt="Google Play"
                        className="w-100 hover-pointer"
                      />
                    </a>
                  </Col>
                  <Col col={6} className="px-3">
                    <a
                        href={
                        "https://apps.apple.com/vn/app/iloto/id1632846722?l=vi"
                      }
                      target="_blank"
                      rel="nofollow noopener noreferrer"
                    >
                      <img
                        src={"/img/logo/appstore.jpg"}
                        alt="App store"
                        className="w-100 hover-pointer"
                      />
                    </a>
                  </Col>
                </Row>
              </Col>
            </Row>
           
            <FormGroup>
              {/*<Label>Số điện thoại</Label>*/}
              <Input
                type="number"
                pattern="[0-9]*"
                name="phoneNumber"
                placeholder="Số điện thoại"
                onChange={this.handleChange.bind(this)}
              />
            </FormGroup>
            <FormGroup>
              {/*<Label>Mật khẩu</Label>*/}
              <Input
                type="password"
                name="password"
                placeholder="Mật khẩu"
                onChange={this.handleChange.bind(this)}
              />
            </FormGroup>
            <FormGroup>
              {/*<Label>Xác nhận mật khẩu</Label>*/}
              <Input
                type="password"
                name="confirmPassword"
                placeholder="Xác nhận mật khẩu"
                onChange={this.handleChange.bind(this)}
              />
            </FormGroup>
            <div className="d-flex">
              <div>
                <CustomInput
                  id={"agreeTerm"}
                  type={"checkbox"}
                  checked={this.state.agree}
                  onChange={this.toggle.bind(this, "agree")}
                />
              </div>
              <div style={{ fontSize: "0.875rem" }}>
                Tôi đủ 18 tuổi, đã xem và đồng ý với tất cả&nbsp;
                <a href="/term" target="_blank">
                  điều khoản
                </a>
                &nbsp;sử dụng
              </div>
            </div>
            <Row className="justify-content-center mt-2">
              {/* <ButtonLoading
                title={"Đăng ký"}
                loading={this.state.isLoading}
                onClick={this.handleRegister.bind(this)}
              /> */}
              <div className="d-flex feature-page-item-2 align-items-center">
              <div >
                <User color={"white"} fill="white" />
                <ButtonLoading
                  title={" Đăng ký"}
                  loading={this.state.isLoading}
                  onClick={this.handleRegister.bind(this)}
                />
              </div>
            </div>
            </Row>
            <Row className="mt-2 justify-content-center">
              <Link to="/auth/sign-in">Đã có tài khoản? Đăng nhập ngay?</Link>
            </Row>
            <Row className="my-2 w-100 justify-content-center">
              <div className="w-50 border-top"></div>
            </Row>
            <Row
              className="justify-content-center font-weight-bold"
              style={{ textDecoration: "underline" }}
            >
              Các tính năng nổi bật 
            </Row>
            <Row className="feature-page-container mt-1 pH ">
              <Col>
                <Row>
                  <Col>
                    <div className="d-flex feature-page-item align-items-center">
                      <div>
                        <Smartphone color={"white"} fill="red" />
                      </div>
                      <div>
                        <span>Đặt mua xổ số Online mọi lúc mọi nơi</span>
                      </div>
                    </div>
                  </Col>
                  {/*<Col>*/}
                  {/*    <div className="d-flex feature-page-item">*/}
                  {/*        <div>*/}
                  {/*            <Bell color={"red"} fill="red"/>*/}
                  {/*        </div>*/}
                  {/*        <div>*/}
                  {/*            <span>Tự động thông báo trúng thưởng</span>*/}
                  {/*        </div>*/}
                  {/*    </div>*/}
                  {/*</Col>*/}
                </Row>
                <Row>
                  <Col>
                    <div className="d-flex feature-page-item align-items-center">
                      <div>
                        <Bell color={"red"} fill="red" />
                      </div>
                      <div>
                        <span>Tự động thông báo trúng thưởng</span>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="d-flex feature-page-item align-items-center">
                      <div>
                        <User color={"red"} fill="red" />
                      </div>
                      <div>
                        <span>Thông tin khách hàng được in sau vé</span>
                      </div>
                    </div>
                  </Col>
                  {/*<Col>*/}
                  {/*    <div className="d-flex feature-page-item">*/}
                  {/*        <div>*/}
                  {/*            <Instagram color={"white"} fill="red"/>*/}
                  {/*        </div>*/}
                  {/*        <div>*/}
                  {/*            <span>Nhận hình ảnh thực của vé đã mua</span>*/}
                  {/*        </div>*/}
                  {/*    </div>*/}
                  {/*</Col>*/}
                </Row>
                <Row>
                  <Col>
                    <div className="d-flex feature-page-item align-items-center">
                      <div>
                        <Instagram color={"white"} fill="red" />
                      </div>
                      <div>
                        <span>Nhận hình ảnh thực của vé đã mua</span>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </div>
        {/*<div>*/}
        {/*    <Col>*/}
        {/*        <Row className={`app-download ${this.state.isOpen ? "" : "d-none"}`}>*/}
        {/*            <div className="px-3">*/}
        {/*                <Col>*/}
        {/*                    <Row className="justify-content-center py-1">*/}
        {/*                        Tải ứng dụng VietLuck*/}
        {/*                        <XCircle*/}
        {/*                            className="btn-close hover-pointer"*/}
        {/*                            onClick={() => this.closeDownload.play()}*/}
        {/*                        />*/}
        {/*                    </Row>*/}
        {/*                    <Row className="pb-3 mt-1">*/}
        {/*                        <Col col={6} className="px-3">*/}
        {/*                            <a href={"https://play.google.com/store/apps/details?id=com.vietluckvn&hl=en_US"}>*/}
        {/*                                <img*/}
        {/*                                    src={"/img/logo/google-play.jpg"}*/}
        {/*                                    alt="Google Play"*/}
        {/*                                    className="w-100 hover-pointer"*/}
        {/*                                />*/}
        {/*                            </a>*/}
        {/*                        </Col>*/}
        {/*                        <Col col={6} className="px-3">*/}
        {/*                            <a href={"https://tintuc.vietluck.vn/iphone-ios"} target="_blank">*/}
        {/*                                <img*/}
        {/*                                    src={"/img/logo/appstore.jpg"}*/}
        {/*                                    alt="App store"*/}
        {/*                                    className="w-100 hover-pointer"*/}
        {/*                                />*/}
        {/*                            </a>*/}
        {/*                        </Col>*/}
        {/*                    </Row>*/}
        {/*                </Col>*/}
        {/*            </div>*/}
        {/*        </Row>*/}
        {/*    </Col>*/}
        {/*</div>*/}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default withRouter(connect(mapStateToProps, { userChange })(SignIn));
