import React from "react";
import {Button, Spinner} from "reactstrap";

export default class ButtonLoading extends React.Component{
    render(){
        const props = this.props;
        return(
            <Button {...props}>
                {
                    this.props.loading
                        ?
                        <Spinner size={this.props.sprinnerSize ? this.props.sprinnerSize : "sm"}/>
                        :
                        this.props.title ? this.props.title : "Title"
                }
            </Button>
        )
    }
}
