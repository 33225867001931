import React, {Component} from 'react';

import {
    Row, Col, Input
} from "reactstrap";

import ButtonLoading from "../../components/FworkTag/ButtonLoading";

import utils from "../../utils";

import apiAuth from "../../api/Auth";

const logo = require("../../assets/images/logo/logo.png");

class OTP extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            otp: ["", "", "", "", "", ""]
        }
    }

    handleChange(event){
        let id = parseInt(event.target.name.split("_")[1]);
        let otp = JSON.parse(JSON.stringify(this.state.otp));
        otp[id - 1] = event.target.value;
        this.setState({otp: otp});
        if(event.target.value !== "") {
            if(id!==6)
                document.getElementById(`otp_${id + 1}`).focus()
        }
    }

    handleSubmit() {
        if(this.state.isLoading) return;
        if(this.state.otp.numEmpty() === 0) {
            let otp = "";
            this.state.otp.forEach(dt => {
                otp += dt.toString();
            });
            this.setState({isLoading: true});
            apiAuth.confirmOTP(otp, (err, result) => {
                if(err) {

                } else {
                    utils.confirm("Xác nhận OTP thành công!", (check) => {
                        window.location.replace("/profile");
                    })
                }
            })

        } else {
            utils.alert("Nhập OTP");
        }
    }

    render() {
        return (
            <div className="bootstrap-reset p-4">
                <Row className="justify-content-center mb-4">
                    <img src={logo} alt={"logo"} style={{width: "100px", height: "80px"}}/>
                </Row>
                <Row>
                    Mã OTP đã được gửi về số điện thoại của quý khách:
                </Row>
                <Row>
                    <Col className="col-2 text-center p-1">
                        <Input type={"text"} maxlength={1} className="input-otp" id="otp_1" name="otp_1" onChange={this.handleChange.bind(this)} value={this.state.otp[0]}/>
                    </Col>
                    <Col className="col-2 text-center p-1">
                        <Input type={"text"} maxlength={1} className="input-otp" id="otp_2" name="otp_2" onChange={this.handleChange.bind(this)} value={this.state.otp[1]}/>
                    </Col>
                    <Col className="col-2 text-center p-1">
                        <Input type={"text"} maxlength={1} className="input-otp" id="otp_3" name="otp_3" onChange={this.handleChange.bind(this)} value={this.state.otp[2]}/>
                    </Col>

                    <Col className="col-2 text-center p-1">
                        <Input type={"text"} maxlength={1} className="input-otp" id="otp_4" name="otp_4" onChange={this.handleChange.bind(this)} value={this.state.otp[3]}/>
                    </Col>
                    <Col className="col-2 text-center p-1">
                        <Input type={"text"} maxlength={1} className="input-otp" id="otp_5" name="otp_5" onChange={this.handleChange.bind(this)} value={this.state.otp[4]}/>
                    </Col>
                    <Col className="col-2 text-center p-1">
                        <Input type={"text"} maxlength={1} className="input-otp" id="otp_6" name="otp_6" onChange={this.handleChange.bind(this)} value={this.state.otp[5]}/>
                    </Col>
                </Row>
                <Row className="justify-content-center mt-4">
                    <ButtonLoading
                        loading={this.state.isLoading}
                        title={"Xác nhận"}
                        onClick={this.handleSubmit.bind(this)}
                    />
                </Row>
            </div>
        );
    }
}

export default OTP;
