import ErrorMessage from "../utils/ErrorMessage";

const path = require("../config").path;

const axios = require("axios");


function getListCity(callback){
    axios.get(path.address.city)
        .then(res => {
            let data = [];
            Object.keys(res.data).forEach(key => {
                data.push(res.data[key]);
            });
            data = data.sortBy("name");
            let result = [];
            data.forEach(dt => {
                result.push({
                    value: dt.code,
                    name: dt.name
                })
            });
            return callback(null, result);
        })
        .catch(error => {
            ErrorMessage(error);
            return callback(error);
        })
}

function getListDistrictByCity(city, callback){
    axios.get(path.address.city + "/" + city + "/district")
        .then(res => {
            let data = [];
            Object.keys(res.data).forEach(key => {
                data.push(res.data[key]);
            });
            data = data.sortBy("name");
            let result = [];
            data.forEach(dt => {
                result.push({
                    value: dt.code,
                    name: dt.name
                })
            });
            return callback(null, result);
        })
        .catch(error => {
            ErrorMessage(error);
            return callback(error);
        })
}

function getListWardByDistrict(district, callback){
    axios.get(path.address.path + "/district/" + district + "/ward")
        .then(res => {
            let data = [];
            Object.keys(res.data).forEach(key => {
                data.push(res.data[key].name);
            });
            return callback(null, data.sortBy("name"));
        })
        .catch(error => {
            ErrorMessage(error);
            return callback(error);
        })
}


export default {
    getListCity: getListCity,
    getListDistrictByCity: getListDistrictByCity,
    getListWardByDistrict: getListWardByDistrict
}
