import * as type from '../constants';

export const addToCart = (product) => ({
    type: type.ADD_TO_CART,
    product
});

export const removeFromCart = (id) => ({
    type: type.REMOVE_FROM_CART,
    id
});

export const removeAllCart = () => ({
    type: type.REMOVE_ALL_CART,
});

export const removeOtherCart = () => ({
    type: type.REMOVE_OTHER_CART
});

export const removeKenoCart = () => ({
    type: type.REMOVE_KENO_CART
});

export const loadCart = (cart) => ({
    type: type.LOAD_CART,
    cart
});
