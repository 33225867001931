import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {
    Row, Col
} from "reactstrap";
import utils from "../../utils";
import {Phone, Mail, Facebook} from "react-feather";
import {Helmet} from "react-helmet";
import colors from "../../utils/colors";
const logo = require("../../assets/images/logo/dien-toan.png");
const content = `<div style="padding:12px"><style>
table{
    border: #F15922 1px solid;
    width:100%
}
table th{
background-color: #F15922;
    color: #ffffff;
    border-color: #f7a182;
    text-align: center;
    padding: 7px;
    border-width: 1px;
    border-style: solid;
}
    table td{
    border-color: #F15922;
    border-right-color: #f7a182;
        text-align: center;
    padding: 7px;
    border-width: 1px;
    border-style: solid;
}
</style><h4>Vé số MAX 3D</h4>
<h3>Giới thiệu</h3>
<ul>
<li>Chỉ từ<span>&nbsp;</span><strong>10.000đ</strong>, chọn<span>&nbsp;</span><strong>3 chữ số từ 000-999</strong><span>&nbsp;</span>để có cơ hội trúng thưởng nhiều giải thưởng khác nhau.</li>
<li><strong>Max 3D</strong><span>&nbsp;</span>quay số mở thưởng vào<span>&nbsp;</span><strong>18h00</strong><span>&nbsp;</span>các<span>&nbsp;</span><strong>ngày thứ 2, thứ 4 và thứ 6 hàng tuần</strong>.</li>
</ul>
<h3>Cách chơi cơ bản.</h3>
<ul>
<li>Người chơi chọn<span>&nbsp;</span><strong>1 số</strong><span>&nbsp;</span>có 3 chữ số từ 000 - 999 để tạo thành dãy số dự thưởng.</li>
<li>Kết quả phải<span>&nbsp;</span><strong>trùng khớp theo đúng thứ tự</strong><span>&nbsp;</span>dãy số quay mở thưởng.</li>
<li>Có thể chơi Max 3D online qua dịch vụ mua hộ Vieltott của Vsmb.vn&nbsp;
<p><strong></strong></p>
<ul>
<li style="font-weight: 400;" aria-level="1"><span>Gia tăng tỉ lệ trúng thưởng.</span></li>
<li style="font-weight: 400;" aria-level="1"><span>Cộng gộp nhiều giải thưởng.</span></li>
<li style="font-weight: 400;" aria-level="1"><span>Tiết kiệm thời gian chọn số.</span></li>
<li style="font-weight: 400;" aria-level="1"><span>Giảm thiểu tỷ lệ rủi ro sót số mình muốn chọn.</span></li>
</ul>
<p><strong>VÉ MAX 3D ĐẢO SỐ.</strong></p>
<p><span>Bạn chọn Max 3D đảo số với số bạn chọn là: </span><strong>123</strong></p>
<p><span>Máy tính sẽ tạo cho các bộ số sau.</span></p>
<br>
<table style="border-collapse: collapse; width: 16.3743%; margin-left: auto; margin-right: auto;">
<tbody>
<tr>
<td style="width: 20.8594%;">
<p><span>123</span></p>
</td>
<td style="width: 20.8594%;">
<p><span>132</span></p>
</td>
<td style="width: 20.8594%;">
<p><span>213</span></p>
</td>
</tr>
<tr>
<td style="width: 20.8594%;">
<p><span>231</span></p>
</td>
<td style="width: 20.8594%;">
<p><span>321</span></p>
</td>
<td style="width: 20.8594%;">
<p><span>312</span></p>
</td>
</tr>
</tbody>
</table>
<br>
<p><strong>VÉ MAX 3D ÔM MỘT VỊ TRÍ</strong></p>
<p><span>Bạn sẽ chọn 1 vị trí trong bộ số để cố định vị trí đó sau đó máy tính sẽ tiến hành thay số mà bạn cố định bằng các số từ 0-9&nbsp;</span></p>
<p><span>Ví dụ bạn chọn ôm 1 vị trí bộ số <strong>*23</strong> các bộ số được tạo ra.</span></p>
<br>
<table style="border-collapse: collapse; margin-left: auto; margin-right: auto;">
<tbody>
<tr>
<td>
<p><span>023</span></p>
</td>
<td>
<p><span>523</span></p>
</td>
</tr>
<tr>
<td>
<p><span>123</span></p>
</td>
<td>
<p><span>623</span></p>
</td>
</tr>
<tr>
<td>
<p><span>223</span></p>
</td>
<td>
<p><span>723</span></p>
</td>
</tr>
<tr>
<td>
<p><span>323</span></p>
</td>
<td>
<p><span>823</span></p>
</td>
</tr>
<tr>
<td>
<p><span>423</span></p>
</td>
<td>
<p><span>923</span></p>
</td>
</tr>
</tbody>
</table>
<br>
<p><strong>Cơ cấu giải thưởng.</strong></p>
</li>
</ul>
<section class="prizes">
<table class="max3d table-striped table-hover">
<tbody>
<tr>
<th>Cơ cấu giải thưởng</th>
<th>Kết quả mở thưởng</th>
<th>Dãy số tham gia</th>
<th>Giá trị giải thưởng</th>
</tr>
<tr>
<td>Giải Nhất</td>
<td>Có 2 bộ số</td>
<td>Trùng khớp theo thứ tự của 1 trong 2 bộ số</td>
<td>1.000.000đ</td>
</tr>
<tr>
<td>Giải Nhì</td>
<td>Có 4 bộ số</td>
<td>Trùng khớp theo thứ tự của 1 trong 4 bộ số</td>
<td>350.000đ</td>
</tr>
<tr>
<td>Giải Ba</td>
<td>Có 6 bộ số</td>
<td>Trùng khớp theo thứ tự của 1 trong 6 bộ số</td>
<td>210.000đ</td>
</tr>
<tr>
<td>Giải Khuyến khích</td>
<td>Có 8 bộ số</td>
<td>Trùng khớp theo thứ tự của 1 trong 8 bộ số</td>
<td>100.000đ</td>
</tr>
</tbody>
</table>
</section>
<div class="alert-warning">
<h4>Lưu ý</h4>
<ul>
<li>
<p><strong>Cơ cấu giải thưởng áp dụng với vé dự thưởng gồm một số ba chữ số khác nhau.</strong></p>
<ul>
<li style="font-weight: 400;" aria-level="1"><span>Trường hợp hai bộ số của người chơi trùng nhiều giải thưởng, người chơi sẽ được lãnh tất cả các giải thưởng.</span></li>
<li style="font-weight: 400;" aria-level="1"><span>Giá trị lĩnh thưởng theo số lần tham gia dự thưởng số trúng thưởng ( 01 lần tham gia dự thưởng mệnh giá <strong>10.000 đồng</strong> ) nhân với giá trị thưởng tương ứng với 01 lần tham gia dự thưởng.</span></li>
<li style="font-weight: 400;" aria-level="1"><span>Giá trị giải thưởng sẽ ứng với giá trị bộ số bạn mua ( Bạn mua bộ số giá <strong>10.000 đồng</strong> khi trúng giải Đặc Biệt bạn sẽ nhận được <strong>1.000.000 đồng</strong>, bạn mua bộ số giá <strong>20.000 đồng</strong> khi trúng giải Đặc Biệt bạn sẽ nhận được <strong>2.000.000 đồng</strong>….)</span></li>
</ul>
<br>

</li>
</ul>
</div></div>`

class Max3d extends Component {
    constructor(props)
    {
        super(props);
        this.state={seo:{}}
    }
    async componentDidMount(){            
    }
    render() {
        return (
            <div className='p2'>
                <div dangerouslySetInnerHTML={{__html: content}} />
        
            </div>
        );
    }
}

export default Max3d;
