import React, {Component} from 'react';
import {
    Modal, ModalHeader, ModalBody, ModalFooter, CustomInput, FormGroup,
    Row, Col, Button
} from "reactstrap";

import {faCaretDown} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

/* eslint eqeqeq: "off" */

export class ModalMultiSelect extends Component {
    constructor(props) {
        super(props);
        this.data = this.props.data;
        this.state = {
            isOpen: false,
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextState.isOpen !== this.state.isOpen ||
            nextProps.checked !== this.props.checked;
    }

    toggle(component) {
        this.setState({
            [component]: !this.state[component]
        });
    }

    handleSelect(event) {
        this.props.onChange(event.target.id);
    }
    
    renderLabel=(keyId,keyLabel,termType)=>{

        if(termType=='dt'){
            switch(this.props.checked.length){
                case 1:
                    return this.data.find(dt => dt[keyId] === 1)[keyLabel]
                case 2:
                    return this.data.find(dt => dt[keyId] === 2)[keyLabel]
                case 5:
                    return this.data.find(dt => dt[keyId] === 5)[keyLabel]
                case 10:
                    return this.data.find(dt => dt[keyId] === 10)[keyLabel]
               
            }
        }
        else{
        if( this.props.checked.length > 1 )
        return `Đã chọn ${this.props.checked.length} kỳ` ;
        else
       return  this.data.find(dt => dt[keyId] === this.props.checked[0])[keyLabel]
    }
    }

    render() {
        const keyId = this.props.keyId ? this.props.keyId : 'id';
        const keyLabel = this.props.keyLabel ? this.props.keyLabel : 'label';
        const keySubLabel = this.props.keySubLabel ? this.props.keySubLabel : null;
        const termType=this.props.termType;
        return (
            <React.Fragment>
                <Modal isOpen={this.state.isOpen} centered toggle={this.toggle.bind(this, "isOpen")}>
                    <ModalHeader>
                        {this.props.title}
                    </ModalHeader>
                    <ModalBody className="overflow-50x">
                        <FormGroup>
                            <div>
                                {

                                    this.data.map((dt, index) => (
                                        termType=="dt"?
                                        <CustomInput
                                            key={`customInput-${index}`}
                                            id={dt[keyId]}
                                            type={"radio"}
                                            checked={this.props.checked.length==dt.value}
                                            label={dt[keyLabel] + (keySubLabel ? ` - ${dt[keySubLabel]}` : "")}
                                            className="my-1"
                                            onChange={this.handleSelect.bind(this)}
                                        />:
                                        <CustomInput
                                            key={`customInput-${index}`}
                                            id={dt[keyId]}
                                            type={termType=="dt"?"radio":"checkbox"}
                                            checked={this.props.checked.includes(dt[keyId])}
                                            label={dt[keyLabel] + (keySubLabel ? ` - ${dt[keySubLabel]}` : "")}
                                            className="my-1"
                                            onChange={this.handleSelect.bind(this)}
                                        />
                                    ))
                                }
                            </div>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={this.toggle.bind(this, "isOpen")}>OK</Button>
                    </ModalFooter>
                </Modal>
                <div className="w-100" onClick={this.toggle.bind(this, "isOpen")}>
                    <Row className="form-control align-items-center d-flex hover-pointer">
                        <Col className="col-11">
                            {                                                                                          
                                this.renderLabel(keyId,keyLabel,termType)
                            }
                        </Col>
                        <Col className="col-1">
                            <FontAwesomeIcon icon={faCaretDown}/>
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}

export class ModalSelect extends React.Component {
    constructor(props) {
        super(props);
        this.data = this.props.data;
        this.state = {}
    }

    handleSelect(event) {
        this.props.onChange(event.target.id);
    }

    getTitlePrice(data){
        if(this.props.keyLabel==='price'){
            if(data==='5000 K')
            return '5.000.000 ₫'
            return data.replace(' K','.000 ₫');
        }
        return data;
    }
    render() {
        const keyId = this.props.keyId ? this.props.keyId : 'id';
        const keyLabel = this.props.keyLabel ? this.props.keyLabel : 'label';
        // const keySubLabel = this.props.keySubLabel ? this.props.keySubLabel : null;

        return (
            <React.Fragment>
                <Modal isOpen={this.props.isOpen} centered toggle={this.props.toggle}>
                    <ModalHeader toggle={this.props.toggle}>
                        {this.props.title}
                    </ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <div>
                                {
                                    this.data.map((dt, index) => (
                                        <CustomInput
                                            key={`customInput-${index}`}
                                            id={dt[keyId]}
                                            type={"radio"}
                                            checked={this.props.checked == dt[keyId]}
                                            label={this.getTitlePrice(dt[keyLabel])}
                                            className="my-1"
                                            name="price"
                                            onChange={this.handleSelect.bind(this)}
                                        />
                                    ))
                                }
                            </div>
                        </FormGroup>
                    </ModalBody>
                </Modal>

            </React.Fragment>
        );
    }
}

export class ModalImageLucky extends React.Component {
    render() {
        return (
            <Modal isOpen={this.props.isOpen} centered toggle={this.props.toggle}>
                <ModalHeader>
                    Chúc quý khách may mắn
                </ModalHeader>
                <ModalBody className="text-center">
                    <img src={"/img/gif/ThanTaiNhayNhay.gif"} alt={"img"} style={{width: "50%"}}/>
                </ModalBody>
                <ModalFooter className="border-0">
                    <Button onClick={this.props.toggle}>OK</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

export class ModalImage extends React.Component {
    render() {
        return (
            <Modal isOpen={this.props.isOpen} centered toggle={this.props.toggle}>
                <ModalHeader toggle={this.props.toggle}>{this.props.title}</ModalHeader>
                <ModalBody className="text-center">
                    <img alt={this.props.title} src={this.props.image} style={{width: "90%"}}/>
                </ModalBody>

            </Modal>
        )
    }
}
