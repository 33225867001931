import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {
    Row, Col
} from "reactstrap";
import utils from "../../utils";
import {Phone, Mail, Facebook} from "react-feather";
import {Helmet} from "react-helmet";
import colors from "../../utils/colors";
const logo = require("../../assets/images/logo/dien-toan.png");
const content = `<div style="padding:12px"><style>
table{
    border: #F15922 1px solid;
    width:100%
}
table th{
background-color: #F15922;
    color: #ffffff;
    border-color: #f7a182;
    text-align: center;
    padding: 7px;
    border-width: 1px;
    border-style: solid;
}
    table td{
    border-color: #F15922;
    border-right-color: #f7a182;
        text-align: center;
    padding: 7px;
    border-width: 1px;
    border-style: solid;
}
</style><h4>Vé số KENO cơ bản bậc 1 - 10</h4>
<h3><span>Giới thiệu</span></h3>
<ul>
<li>Với<strong><span>&nbsp;</span>10.000đ</strong>, chọn<strong><span>&nbsp;</span>01 - 10 chữ</strong><span>&nbsp;</span>số từ<strong><span>&nbsp;</span>01 - 80<span>&nbsp;</span></strong>để có cơ hội nhận giải thưởng lớn nhất<strong><span>&nbsp;</span>2 tỷ đồng</strong>. Đặc biệt<strong><span>&nbsp;</span>không trùng kết quả vẫn có thưởng</strong>.</li>
<li><strong>Keno</strong><span>&nbsp;</span>quay số mở thưởng<span>&nbsp;</span><strong>10 phút/kỳ</strong><span>&nbsp;</span>bắt đầu<strong><span>&nbsp;</span>từ 06h00 đến 21h50 mỗi ngày</strong><span>&nbsp;</span>trong tuần.</li>

</ul>
<h3>Luật chơi cơ bản</h3>
<ul>
<li>Chọn dãy số dự thưởng từ 01 đến 80</li>
<li>Dãy số dự thưởng có thể có 01 đến 10 số&nbsp;</li>
<li>Kết quả mở thưởng là dãy số gồm 20 số được chọn từ 80 số&nbsp;</li>
</ul>
<p><strong>Cơ cấu giải thưởng cơ bản</strong></p>
<section class="prizes">
<p><strong>Bậc 1:</strong><span>&nbsp;</span>Người chơi chọn duy nhất 1 số để tham gia dự thưởng.</p>
<table class="keno" style="border-collapse: collapse; width: 100%; height: 58px; background-color: #ffffff; border-color: #000000;">
<tbody>
<tr style="height: 39px;">
<th style="width: 41.0714%; height: 39px;">Trùng khớp</th>
<th style="width: 43.8244%; height: 39px;">Tiền thưởng</th>
</tr>
<tr style="height: 19px;">
<td style="width: 41.0714%; height: 19px;">1 số</td>
<td style="width: 43.8244%; height: 19px;">20.000đ x n</td>
</tr>
</tbody>
</table>
<em>n = số lượng bộ số đã mua</em></section>
<section class="prizes">
<p><strong>Bậc 2:</strong><span>&nbsp;</span>Người chơi chọn 2 số để tham gia dự thưởng.</p>
<table class="keno" style="border-collapse: collapse; width: 100%;">
<tbody>
<tr>
<th style="width: 41.0714%;">Trùng khớp</th>
<th style="width: 43.8244%;">Tiền thưởng</th>
</tr>
<tr>
<td style="width: 41.0714%;">2 số</td>
<td style="width: 43.8244%;">90.000đ x n</td>
</tr>
</tbody>
</table>
<em>n = số lượng bộ số đã mua</em></section>
<section class="prizes">
<p><strong>Bậc 3:</strong><span>&nbsp;</span>Người chơi chọn 3 số để tham gia dự thưởng.</p>
<table class="keno" style="border-collapse: collapse; width: 100%;">
<tbody>
<tr>
<th>Trùng khớp</th>
<th>Tiền thưởng</th>
</tr>
<tr>
<td>3 số</td>
<td>200.000đ x n</td>
</tr>
<tr>
<td>2 số</td>
<td>20.000đ x n</td>
</tr>
</tbody>
</table>
<em>n = số lượng bộ số đã mua</em></section>
<section class="prizes">
<p><strong>Bậc 4:</strong><span>&nbsp;</span>Người chơi chọn 4 số để tham gia dự thưởng.</p>
<table class="keno" style="border-collapse: collapse; width: 100%; height: 76px;">
<tbody>
<tr style="height: 19px;">
<th style="width: 41.0714%; height: 19px;">Trùng khớp</th>
<th style="width: 43.8244%; height: 19px;">Tiền thưởng</th>
</tr>
<tr style="height: 19px;">
<td style="width: 41.0714%; height: 19px;">4 số</td>
<td style="width: 43.8244%; height: 19px;">400.000đ x n</td>
</tr>
<tr style="height: 19px;">
<td style="width: 41.0714%; height: 19px;">3 số</td>
<td style="width: 43.8244%; height: 19px;">50.000đ x n</td>
</tr>
<tr style="height: 19px;">
<td style="width: 41.0714%; height: 19px;">2 số</td>
<td style="width: 43.8244%; height: 19px;">10.000đ x n</td>
</tr>
</tbody>
</table>
<em>n = số lượng bộ số đã mua</em></section>
<section class="prizes">
<p><strong>Bậc 5:</strong><span>&nbsp;</span>Người chơi chọn 5 số để tham gia dự thưởng.</p>
<table class="keno" style="border-collapse: collapse; width: 100%;">
<tbody>
<tr>
<th>Trùng khớp</th>
<th>Tiền thưởng</th>
</tr>
<tr>
<td>5 số</td>
<td>4,4 Triệu x n</td>
</tr>
<tr>
<td>4 số</td>
<td>150.000đ x n</td>
</tr>
<tr>
<td>3 số</td>
<td>10.000đ x n</td>
</tr>
</tbody>
</table>
<em>n = số lượng bộ số đã mua</em></section>
<section class="prizes">
<p><strong>Bậc 6:</strong><span>&nbsp;</span>Người chơi chọn 6 số để tham gia dự thưởng.</p>
<table class="keno" style="border-collapse: collapse; width: 100%;">
<tbody>
<tr>
<th>Trùng khớp</th>
<th>Tiền thưởng</th>
</tr>
<tr>
<td>6 số</td>
<td>12,5 Triệu x n</td>
</tr>
<tr>
<td>5 số</td>
<td>450.000đ x n</td>
</tr>
<tr>
<td>4 số</td>
<td>40.000đ x n</td>
</tr>
<tr>
<td>3 số</td>
<td>10.000đ x n</td>
</tr>
</tbody>
</table>
<em>n = số lượng bộ số đã mua</em></section>
<section class="prizes">
<p><strong>Bậc 7:</strong><span>&nbsp;</span>Người chơi chọn 7 số để tham gia dự thưởng.</p>
<table class="keno" style="border-collapse: collapse; width: 100%;">
<tbody>
<tr>
<th style="width: 41.0714%;">Trùng khớp</th>
<th style="width: 43.8244%;">Tiền thưởng</th>
</tr>
<tr>
<td style="width: 41.0714%;">7 số</td>
<td style="width: 43.8244%;">40 Triệu x n</td>
</tr>
<tr>
<td style="width: 41.0714%;">6 số</td>
<td style="width: 43.8244%;">1,2 Triệu x n</td>
</tr>
<tr>
<td style="width: 41.0714%;">5 số</td>
<td style="width: 43.8244%;">100.000đ x n</td>
</tr>
<tr>
<td style="width: 41.0714%;">4 số</td>
<td style="width: 43.8244%;">20.000đ x n</td>
</tr>
<tr>
<td style="width: 41.0714%;">3 số</td>
<td style="width: 43.8244%;">10.000đ x n</td>
</tr>
</tbody>
</table>
<em>n = số lượng bộ số đã mua</em></section>
<section class="prizes">
<p><strong>Bậc 8:</strong><span>&nbsp;</span>Người chơi chọn 8 số để tham gia dự thưởng.</p>
<table class="keno" style="border-collapse: collapse; width: 100%;">
<tbody>
<tr>
<th>Trùng khớp</th>
<th>Tiền thưởng</th>
</tr>
<tr>
<td>8 số</td>
<td>200 Triệu x n</td>
</tr>
<tr>
<td>7 số</td>
<td>5 Triệu x n</td>
</tr>
<tr>
<td>6 số</td>
<td>500.000đ x n</td>
</tr>
<tr>
<td>5 số</td>
<td>50.000đ x n</td>
</tr>
<tr>
<td>4 số</td>
<td>10.000đ x n</td>
</tr>
<tr>
<td>0 số</td>
<td>10.000đ x n</td>
</tr>
</tbody>
</table>
<em>n = số lượng bộ số đã mua</em></section>
<section class="prizes">
<p><strong>Bậc 9:</strong><span>&nbsp;</span>Người chơi chọn 9 số để tham gia dự thưởng.</p>
<table class="keno" style="border-collapse: collapse; width: 100%;">
<tbody>
<tr>
<th style="width: 41.0714%;">Trùng khớp</th>
<th style="width: 43.8244%;">Tiền thưởng</th>
</tr>
<tr>
<td style="width: 41.0714%;">9 số</td>
<td style="width: 43.8244%;">800 Triệu x n</td>
</tr>
<tr>
<td style="width: 41.0714%;">8 số</td>
<td style="width: 43.8244%;">12 Triệu x n</td>
</tr>
<tr>
<td style="width: 41.0714%;">7 số</td>
<td style="width: 43.8244%;">1,5 Triệu x n</td>
</tr>
<tr>
<td style="width: 41.0714%;">6 số</td>
<td style="width: 43.8244%;">150.000đ x n</td>
</tr>
<tr>
<td style="width: 41.0714%;">5 số</td>
<td style="width: 43.8244%;">30.000đ x n</td>
</tr>
<tr>
<td style="width: 41.0714%;">4 số</td>
<td style="width: 43.8244%;">10.000đ x n</td>
</tr>
<tr>
<td style="width: 41.0714%;">0 số</td>
<td style="width: 43.8244%;">10.000đ x n</td>
</tr>
</tbody>
</table>
<em>n = số lượng bộ số đã mua</em></section>
<section class="prizes">
<p><strong>Bậc 10:</strong><span>&nbsp;</span>Người chơi chọn 10 số để tham gia dự thưởng.</p>
<table class="keno" style="border-collapse: collapse; width: 100%; height: 172px;">
<tbody>
<tr style="height: 39px;">
<th style="width: 41.0714%; height: 39px;">Trùng khớp</th>
<th style="width: 43.8244%; height: 39px;">Tiền thưởng</th>
</tr>
<tr style="height: 19px;">
<td style="width: 41.0714%; height: 19px;">10 số</td>
<td style="width: 43.8244%; height: 19px;">2 Tỷ x n</td>
</tr>
<tr style="height: 19px;">
<td style="width: 41.0714%; height: 19px;">9 số</td>
<td style="width: 43.8244%; height: 19px;">150 Triệu x n</td>
</tr>
<tr style="height: 19px;">
<td style="width: 41.0714%; height: 19px;">8 số</td>
<td style="width: 43.8244%; height: 19px;">8 Triệu x n</td>
</tr>
<tr style="height: 19px;">
<td style="width: 41.0714%; height: 19px;">7 số</td>
<td style="width: 43.8244%; height: 19px;">710.000đ x n</td>
</tr>
<tr style="height: 19px;">
<td style="width: 41.0714%; height: 19px;">6 số</td>
<td style="width: 43.8244%; height: 19px;">80.000đ x n</td>
</tr>
<tr style="height: 19px;">
<td style="width: 41.0714%; height: 19px;">5 số</td>
<td style="width: 43.8244%; height: 19px;">20.000đ x n</td>
</tr>
<tr style="height: 19px;">
<td style="width: 41.0714%; height: 19px;">0 số</td>
<td style="width: 43.8244%; height: 19px;">10.000đ x n</td>
</tr>
</tbody>
</table>
<em>n = số lượng bộ số đã mua</em></section>
<div class="alert-warning">
<h4>Lưu ý</h4>
<p>Keno không giới hạn số tiền đặt cược tuy nhiên có giới hạn số tiền trả thưởng cho bậc 8, 9 và 10 trong 1 kỳ quay. Cụ thể số tiền trả thưởng cho bậc<span>&nbsp;</span><strong>8 trùng 8 số, bậc 9 trùng 9 số, bậc 10 trùng 10</strong><span>&nbsp;</span>số cho mỗi kỳ sẽ<span>&nbsp;</span><strong>không vượt quá 10 tỷ đồng</strong>.</p>
<ul>
<li><strong>Đối với bậc 8:</strong>
<ul>
<li>Nếu trong một kỳ quay có<span>&nbsp;</span><strong>từ dưới 50 bộ bậc 8 trùng 8 số</strong><span>&nbsp;</span>thì giá trị nhận thưởng của mỗi bộ số là 200 triệu đồng</li>
<li>Nếu trong một kỳ quay có trên 50 bộ số bậc 8 trùng 8 số thì giá trị nhận thưởng của mỗi bộ số là<span>&nbsp;</span><strong>10 tỷ chia cho số lượng bộ số trúng</strong>.</li>
</ul>
</li>
<li><strong>Đối với bậc 9:</strong>
<ul>
<li>Nếu trong một kỳ quay có<span>&nbsp;</span><strong>từ dưới 12 bộ bậc 9 trùng 9 số</strong><span>&nbsp;</span>thì giá trị nhận thưởng của mỗi bộ số là 800 triệu đồng</li>
<li>Nếu trong một kỳ quay có<span>&nbsp;</span><strong>trên 12 bộ số bậc 9 trùng 9 số</strong><span>&nbsp;</span>thì giá trị nhận thưởng của mỗi bộ số là<span>&nbsp;</span><strong>10 tỷ chia cho số lượng bộ số trúng</strong>.</li>
</ul>
</li>
<li><strong>Đối với bậc 10:</strong>
<ul>
<li>Nếu trong một kỳ quay có<span>&nbsp;</span><strong>từ dưới 5 bộ bậc 10 trùng 10 số</strong><span>&nbsp;</span>thì giá trị nhận thưởng của mỗi bộ số là 02 tỷ đồng</li>
<li>Nếu trong một kỳ quay có<span>&nbsp;</span><strong>trên 5 bộ số bậc 10 trùng 10 số</strong><span>&nbsp;</span>thì giá trị nhận thưởng của mỗi bộ số là<span>&nbsp;</span><strong>10 tỷ chia cho số lượng bộ số trúng</strong>.</li>
</ul>
</li>
</ul>
</div></div>`

class Keno extends Component {
    constructor(props)
    {
        super(props);
        this.state={seo:{}}
    }
    async componentDidMount(){            
    }
    render() {
        return (
            <div className='p2'>
                <div dangerouslySetInnerHTML={{__html: content}} />
        
            </div>
        );
    }
}

export default Keno;
