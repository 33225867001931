import React from "react";

import {Spinner} from "reactstrap"

import apiMyAccount from "../../api/MyAccount";

let vnp_return = window.location.search
    .slice(1)
    .split('&')
    .map(p => p.split('='))
    .reduce((obj, [key, value]) => ({...obj, [key]: value}), {});

class MegaPay_Return extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            paymentStatus: "waitting"
        }
    }

    componentDidMount() {
        // if(vnp_return.vnp_Amount===undefined) {
        //     window.location.replace("/");
        //     return;
        // }
        apiMyAccount.megaPayCheckOrder(vnp_return, (err, result) => {
            if(err){
                this.setState({
                    paymentStatus: "failed"
                })
            } else {
                this.setState({
                    paymentStatus: "success"
                })
            }
        })
    }

    render() {
        return (
            <React.Fragment>
                <div className="login-register m-4">
                    <div className="container" style={{minHeight: "300px"}}>
                        <div className="row justify-content-center">
                        <img
                            src={require('../../assets/images/logo/logo_megapay.png')}
                            style={{
                                width: "200px",                    
                            }}
                            alt="megapay"
                            />
                        </div>
                        {
                            this.state.paymentStatus==="waitting"
                                ?
                                <div>
                                    <div className="row justify-content-center mt-70">
                                        Giao dịch của bạn đang được xử lý!
                                    </div>
                                    <div className="row justify-content-center mt-4">
                                        Vui lòng đợi trong giây lát . . .
                                    </div>
                                    <div className="row mt-3 justify-content-center">
                                        <Spinner/>
                                    </div>
                                </div>
                                : this.state.paymentStatus==="success"
                                ?
                                <div>
                                    <div className="row justify-content-center mt-70 content-title-bold">
                                        Giao dịch thành công
                                    </div>
                                    <div className="row mt-4 justify-content-center">
                                        <a href="/">Trang chủ</a>
                                    </div>
                                </div>
                                :
                                <div>
                                    <div className="row justify-content-center mt-70 content-title-bold">
                                        Giao dịch thất bại
                                    </div>
                                    <div className="row mt-4 justify-content-center">
                                        <a href="/">Vui lòng thử lại</a>
                                    </div>
                                </div>
                        }

                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default MegaPay_Return;
