import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {
    Row, Col
} from "reactstrap";
import utils from "../../utils";
import {Phone, Mail, Facebook} from "react-feather";
import {Helmet} from "react-helmet";
import colors from "../../utils/colors";
const logo = require("../../assets/images/logo/dien-toan.png");
const content = `<div style="padding:12px"><style>
table{
    border: #F15922 1px solid;
    width:100%
}
table th{
background-color: #F15922;
    color: #ffffff;
    border-color: #f7a182;
    text-align: center;
    padding: 7px;
    border-width: 1px;
    border-style: solid;
}
    table td{
    border-color: #F15922;
    border-right-color: #f7a182;
        text-align: center;
    padding: 7px;
    border-width: 1px;
    border-style: solid;
}
</style><h4>Vé số POWER 6/55</h4>
<h3>Giới thiệu</h3>
<ul>
<li>Chỉ từ<span>&nbsp;</span><strong>10.000đ</strong>, chọn<span>&nbsp;</span><strong>6 số từ 01 - 55</strong><span>&nbsp;</span>để có cơ hội trúng thưởng Jackpot 1 từ<span>&nbsp;</span><strong>30 tỷ đồng</strong>, Jackpot 2 từ<span>&nbsp;</span><strong>3 tỷ đồng</strong>.</li>
<li><strong>POWER 6/55</strong><span>&nbsp;</span>quay số mở thưởng vào<span>&nbsp;</span><strong>18h00</strong><span>&nbsp;</span>các ngày<span>&nbsp;</span><strong>thứ 3, thứ 5 và thứ 7 hàng tuần</strong>.</li>

</ul>
<h3>Luật chơi CƠ BẢN</h3>
<ul>
<li>Người chơi chọn ra 6 con số từ 01 - 55, tạo thành dãy số dự thưởng.</li>
<li>Kết quả trúng thưởng chỉ cần trùng khớp với dãy số mở thưởng, không cần theo thứ tự.</li>
<li>Cách xác nhận giải thưởng:
<ul>
<li><strong>Giải Jackpot 1</strong>: Bộ số mở thưởng gồm 6 số được chọn từ 01 - 55 số</li>
<li><strong>Giải Jackpot 2</strong>: Chọn thêm 1 số trong 49 số còn lại, sau khi đã chọn 6 số cho Giải Jackpot 1</li>
</ul>
</li>
</ul>
<p><img class="img-fluid" src="https://lh5.googleusercontent.com/DLYOUhlUQvQ-hUtu-YCOTLt12GfSA3gEwUqTlvuEHpe3LdypFW6jMmq6tq490mEoetrvuRwiBpXPyz2iLPgA8Z2WsPBJeNdwjVFR95D5DdvYBUIRqj8EDevNINTATFOy0hJoSjTzPff96Qb0sg"><span></span></p>
<h3>Luật chơi BAO</h3>
<p><span>Với hình thức Power, người chơi có thể chơi bao theo cách thức chọn&nbsp;</span><strong>tối thiểu 5 số</strong><span>&nbsp;và&nbsp;</span><strong>tối đa 18 số</strong><span>&nbsp;cố định để tham gia dự thưởng như sau:</span></p>
<ul>
<li>Với hình thức<span>&nbsp;</span><strong>Bao 5</strong>, người chơi sẽ lựa chọn<span>&nbsp;</span><strong>5 số cố định</strong><span>&nbsp;</span>trong<span>&nbsp;</span><strong>tổng số 6 số dự thưởng</strong>. Số còn lại sẽ được chọn trong 50 số còn lại (khác với 5 số cố định đã chọn).</li>
<li>Với các hình thức<span>&nbsp;</span><strong>Bao</strong><span>&nbsp;</span>còn lại, người chơi sẽ lựa chọn số lượng con số muốn bao. Dãy số dự thưởng sẽ được tạo nên từ việc thay đổi sự xuất hiện của những số con số này.</li>
</ul>
<p><span>Ví dụ: Người chơi chọn&nbsp;</span><strong>Bao 7</strong><span>&nbsp;với các con số sau đây:&nbsp;</span><strong>01 - 07 - 13 - 21 - 27 - 34 - 44</strong><span>, thì sẽ có 7 bộ số được tạo ra như sau:</span></p>
<ul>
<li>Dãy 1: [01 - 07 - 13 - 21 - 27 - 34]</li>
<li>Dãy 2: [07 - 13 - 21 - 27 - 34 - 44]</li>
<li>Dãy 3: [01 - 07 - 13 - 21 - 27 - 44]</li>
<li>Dãy 4: [01 - 13 - 21 - 27 - 34 - 44]</li>
<li>Dãy 5: [01 - 07 - 21 - 27 - 34 - 44]</li>
<li>Dãy 6: [01 - 07 - 13 - 27 - 34 - 44]</li>
<li>Dãy 7: [01 - 07 - 13 - 21 - 34 - 44]</li>
</ul>
<section class="prizes">
<table class="power table-striped table-hover">
<tbody>
<tr>
<th>Chọn Bao</th>
<th>Chọn số cố định</th>
<th>Tổng dãy số tạo nên</th>
<th>Tổng giá vé (*)</th>
</tr>
<tr>
<td>Bao 5</td>
<td>5 số</td>
<td>50 bộ số</td>
<td>500.000đ</td>
</tr>
<tr>
<td>Bao 7</td>
<td>7 số</td>
<td>7 bộ số</td>
<td>70.000đ</td>
</tr>
<tr>
<td>Bao 8</td>
<td>8 số</td>
<td>28 bộ số</td>
<td>280.000đ</td>
</tr>
<tr>
<td>Bao 9</td>
<td>9 số</td>
<td>84 bộ số</td>
<td>840.000đ</td>
</tr>
<tr>
<td>Bao 10</td>
<td>10 số</td>
<td>210 bộ số</td>
<td>2.100.000đ</td>
</tr>
<tr>
<td>Bao 11</td>
<td>11 số</td>
<td>462 bộ số</td>
<td>4.620.000đ</td>
</tr>
<tr>
<td>Bao 12</td>
<td>12 số</td>
<td>924 bộ số</td>
<td>9.240.000đ</td>
</tr>
<tr>
<td>Bao 13</td>
<td>13 số</td>
<td>1.716 bộ số</td>
<td>17.160.000đ</td>
</tr>
<tr>
<td>Bao 14</td>
<td>14 số</td>
<td>3.003 bộ số</td>
<td>30.030.000đ</td>
</tr>
<tr>
<td>Bao 15</td>
<td>15 số</td>
<td>5.005 bộ số</td>
<td>50.050.000đ</td>
</tr>
<tr>
<td>Bao 18</td>
<td>18 số</td>
<td>18.564 bộ số</td>
<td>185.640.000đ</td>
</tr>
</tbody>
</table>
<em>(*) giá trên chưa bao gồm phí giao dịch</em></section>
<h4>Giá trị giải thưởng được tính như sau</h4>
<section class="prizes">
<table class="power table-striped table-hover">
<tbody>
<tr>
<th colspan="2">Bao 5</th>
</tr>
<tr>
<td>Trùng 2 số</td>
<td>200.000đ</td>
</tr>
<tr>
<td>Trùng 3 số</td>
<td>3,85 triệu</td>
</tr>
<tr>
<td>Trùng 4 số</td>
<td>104 triệu</td>
</tr>
<tr>
<td>Trùng 4 số + số đặc biệt</td>
<td>2x<span>J2</span><span>&nbsp;</span>+ 24 triệu</td>
</tr>
<tr>
<td>Trùng 5 số</td>
<td><span>J1</span><span>&nbsp;</span>+<span>&nbsp;</span><span>J2</span><span>&nbsp;</span>+ 1,92 tỷ</td>
</tr>
</tbody>
</table>
</section>
<section class="prizes">
<table class="power table-striped table-hover">
<tbody>
<tr>
<th colspan="2">Bao 7</th>
</tr>
<tr>
<td>Trùng 3 số</td>
<td>200.000đ</td>
</tr>
<tr>
<td>Trùng 4 số</td>
<td>1,7 triệu</td>
</tr>
<tr>
<td>Trùng 5 số</td>
<td>82,5 triệu</td>
</tr>
<tr>
<td>Trùng 5 số + số đặc biệt</td>
<td>2x<span>J2</span><span>&nbsp;</span>+ 42,5 triệu</td>
</tr>
<tr>
<td>Trùng 6 số</td>
<td>2x<span>J1</span><span>&nbsp;</span>+ 240 triệu</td>
</tr>
<tr>
<td>Trùng 6 số + số đặc biệt</td>
<td><span>J1</span><span>&nbsp;</span>+<span>&nbsp;</span><span>J2</span>x6</td>
</tr>
</tbody>
</table>
</section>
<section class="prizes">
<table class="power table-striped table-hover">
<tbody>
<tr>
<th colspan="2">Bao 8</th>
</tr>
<tr>
<td>Trùng 3 số</td>
<td>500.000đ</td>
</tr>
<tr>
<td>Trùng 4 số</td>
<td>3,8 triệu</td>
</tr>
<tr>
<td>Trùng 5 số</td>
<td>128 triệu</td>
</tr>
<tr>
<td>Trùng 5 số + số đặc biệt</td>
<td><span>J2</span><span>&nbsp;</span>+ 88 triệu</td>
</tr>
<tr>
<td>Trùng 6 số</td>
<td><span>J1</span><span>&nbsp;</span>+ 487,5 triệu</td>
</tr>
<tr>
<td>Trùng 6 số + số đặc biệt</td>
<td><span>J1</span><span>&nbsp;</span>+<span>&nbsp;</span><span>J2</span>x6 + 247,5 triệu</td>
</tr>
</tbody>
</table>
</section>
<section class="prizes">
<table class="power table-striped table-hover">
<tbody>
<tr>
<th colspan="2">Bao 9</th>
</tr>
<tr>
<td>Trùng 3 số</td>
<td>1 triệu</td>
</tr>
<tr>
<td>Trùng 4 số</td>
<td>7 triệu</td>
</tr>
<tr>
<td>Trùng 5 số</td>
<td>177 triệu</td>
</tr>
<tr>
<td>Trùng 5 số + số đặc biệt</td>
<td><span>J2</span><span>&nbsp;</span>+ 137 triệu</td>
</tr>
<tr>
<td>Trùng 6 số</td>
<td><span>J1</span><span>&nbsp;</span>+ 743,5 triệu</td>
</tr>
<tr>
<td>Trùng 6 số + số đặc biệt</td>
<td><span>J1</span><span>&nbsp;</span>+<span>&nbsp;</span><span>J2</span>x6 + 503,5 triệu</td>
</tr>
</tbody>
</table>
</section>
<section class="prizes">
<table class="power table-striped table-hover">
<tbody>
<tr>
<th colspan="2">Bao 10</th>
</tr>
<tr>
<td>Trùng 3 số</td>
<td>1,75 triệu</td>
</tr>
<tr>
<td>Trùng 4 số</td>
<td>11,5 triệu</td>
</tr>
<tr>
<td>Trùng 5 số</td>
<td>230 triệu</td>
</tr>
<tr>
<td>Trùng 5 số + số đặc biệt</td>
<td><span>J2</span><span>&nbsp;</span>+ 190 triệu</td>
</tr>
<tr>
<td>Trùng 6 số</td>
<td><span>J1</span><span>&nbsp;</span>+ 1.009 triệu</td>
</tr>
<tr>
<td>Trùng 6 số + số đặc biệt</td>
<td><span>J1</span><span>&nbsp;</span>+<span>&nbsp;</span><span>J2</span>x6 + 769 triệu</td>
</tr>
</tbody>
</table>
</section>
<section class="prizes">
<table class="power table-striped table-hover">
<tbody>
<tr>
<th colspan="2">Bao 11</th>
</tr>
<tr>
<td>Trùng 3 số</td>
<td>2,8 triệu</td>
</tr>
<tr>
<td>Trùng 4 số</td>
<td>17,5 triệu</td>
</tr>
<tr>
<td>Trùng 5 số</td>
<td>287,5 triệu</td>
</tr>
<tr>
<td>Trùng 5 số + số đặc biệt</td>
<td><span>J2</span><span>&nbsp;</span>+ 247,5 triệu</td>
</tr>
<tr>
<td>Trùng 6 số</td>
<td><span>J1</span><span>&nbsp;</span>+ 1.285 triệu</td>
</tr>
<tr>
<td>Trùng 6 số + số đặc biệt</td>
<td><span>J1</span><span>&nbsp;</span>+<span>&nbsp;</span><span>J2</span>x6 + 1.045 triệu</td>
</tr>
</tbody>
</table>
</section>
<section class="prizes">
<table class="power table-striped table-hover">
<tbody>
<tr>
<th colspan="2">Bao 12</th>
</tr>
<tr>
<td>Trùng 3 số</td>
<td>4,2 triệu</td>
</tr>
<tr>
<td>Trùng 4 số</td>
<td>25,2 triệu</td>
</tr>
<tr>
<td>Trùng 5 số</td>
<td>350 triệu</td>
</tr>
<tr>
<td>Trùng 5 số + số đặc biệt</td>
<td><span>J2</span><span>&nbsp;</span>+ 310 triệu</td>
</tr>
<tr>
<td>Trùng 6 số</td>
<td><span>J1</span><span>&nbsp;</span>+ 1.572,5 triệu</td>
</tr>
<tr>
<td>Trùng 6 số + số đặc biệt</td>
<td><span>J1</span><span>&nbsp;</span>+<span>&nbsp;</span><span>J2</span>x6 + 1.332,5 triệu</td>
</tr>
</tbody>
</table>
</section>
<section class="prizes">
<table class="power table-striped table-hover">
<tbody>
<tr>
<th colspan="2">Bao 13</th>
</tr>
<tr>
<td>Trùng 3 số</td>
<td>6 triệu</td>
</tr>
<tr>
<td>Trùng 4 số</td>
<td>34,8 triệu</td>
</tr>
<tr>
<td>Trùng 5 số</td>
<td>418 triệu</td>
</tr>
<tr>
<td>Trùng 5 số + số đặc biệt</td>
<td><span>J2</span><span>&nbsp;</span>+ 378 triệu</td>
</tr>
<tr>
<td>Trùng 6 số</td>
<td><span>J1</span><span>&nbsp;</span>+ 1.872,5 triệu</td>
</tr>
<tr>
<td>Trùng 6 số + số đặc biệt</td>
<td><span>J1</span><span>&nbsp;</span>+<span>&nbsp;</span><span>J2</span>x6 + 1.632,5 triệu</td>
</tr>
</tbody>
</table>
</section>
<section class="prizes">
<table class="power table-striped table-hover">
<tbody>
<tr>
<th colspan="2">Bao 14</th>
</tr>
<tr>
<td>Trùng 3 số</td>
<td>8,25 triệu</td>
</tr>
<tr>
<td>Trùng 4 số</td>
<td>46,5 triệu</td>
</tr>
<tr>
<td>Trùng 5 số</td>
<td>492 triệu</td>
</tr>
<tr>
<td>Trùng 5 số + số đặc biệt</td>
<td><span>J2</span><span>&nbsp;</span>+ 452 triệu</td>
</tr>
<tr>
<td>Trùng 6 số</td>
<td><span>J1</span><span>&nbsp;</span>+ 2.186 triệu</td>
</tr>
<tr>
<td>Trùng 6 số + số đặc biệt</td>
<td><span>J1</span><span>&nbsp;</span>+<span>&nbsp;</span><span>J2</span>x6 + 1.946 triệu</td>
</tr>
</tbody>
</table>
</section>
<section class="prizes">
<table class="power table-striped table-hover">
<tbody>
<tr>
<th colspan="2">Bao 15</th>
</tr>
<tr>
<td>Trùng 3 số</td>
<td>11 triệu</td>
</tr>
<tr>
<td>Trùng 4 số</td>
<td>60,5 triệu</td>
</tr>
<tr>
<td>Trùng 5 số</td>
<td>572,5 triệu</td>
</tr>
<tr>
<td>Trùng 5 số + số đặc biệt</td>
<td><span>J2</span><span>&nbsp;</span>+ 532,5 triệu</td>
</tr>
<tr>
<td>Trùng 6 số</td>
<td><span>J1</span><span>&nbsp;</span>+ 2.514 triệu</td>
</tr>
<tr>
<td>Trùng 6 số + số đặc biệt</td>
<td><span>J1</span><span>&nbsp;</span>+<span>&nbsp;</span><span>J2</span>x6 + 2.274 triệu</td>
</tr>
</tbody>
</table>
</section>
<section class="prizes">
<table class="power table-striped table-hover">
<tbody>
<tr>
<th colspan="2">Bao 18</th>
</tr>
<tr>
<td>Trùng 3 số</td>
<td>22,75 triệu</td>
</tr>
<tr>
<td>Trùng 4 số</td>
<td>118,3 triệu</td>
</tr>
<tr>
<td>Trùng 5 số</td>
<td>858 triệu</td>
</tr>
<tr>
<td>Trùng 5 số + số đặc biệt</td>
<td><span>J2</span><span>&nbsp;</span>+ 818 triệu</td>
</tr>
<tr>
<td>Trùng 6 số</td>
<td><span>J1</span><span>&nbsp;</span>+ 3.595 triệu</td>
</tr>
<tr>
<td>Trùng 6 số + số đặc biệt</td>
<td><span>J1</span><span>&nbsp;</span>+<span>&nbsp;</span><span>J2</span>x6 + 3.355 triệu</td>
</tr>
</tbody>
</table>
</section>
<p><em><strong>Lưu ý</strong>: Giá trị Jackpot 1 và Jackpot 2 được<span>&nbsp;</span><strong>chia đều</strong><span>&nbsp;</span>theo số bộ số trúng thưởng</em></p></div>`

class Power extends Component {
    constructor(props)
    {
        super(props);
        this.state={seo:{}}
    }
    async componentDidMount(){            
    }
    render() {
        return (
            <div className='p2'>
                <div dangerouslySetInnerHTML={{__html: content}} />
        
            </div>
        );
    }
}

export default Power;
