import React, { Component } from "react";


import { Grid, HardDrive } from "react-feather";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch
} from "@fortawesome/free-solid-svg-icons";
import utils from "../../../utils";
import { Row, Col, Button, Input,CustomInput, Modal } from "reactstrap";
import apiOrder from "../../../api/Order";
import ModalKTMN from '../../../components/Vietluck/ModalKTMN'
import styles from  "../../../styles/_page_ktmn.module.css";

import { ModalImageLucky } from "../../../components/Vietluck/ModalVietluck";
const {getProductOptionIdName,getKeyNameById} = require('../../../utils/productData');
const moment = require('moment')
const ktmnLogo = { 
'HCM': '/images/ktmn/HCM.png',
'DTHAP': '/images/ktmn/dongthap.png',
'CMAU': '/images/ktmn/camau.png',
'BTRE': '/images/ktmn/bentre.png',
'VTAU': '/images/ktmn/vungtau.png',
'BLIEU': '/images/ktmn/baclieu.png',
'DNAI': '/images/ktmn/dongnai.png',
'CTHO': '/images/ktmn/cantho.png',
'STRANG': '/images/ktmn/soctrang.png',
'TNINH': '/images/ktmn/tayninh.png',
'AGIANG': '/images/ktmn/angiang.png',
'BTHUAN': '/images/ktmn/binhthuan.png',
'VLONG': '/images/ktmn/vinhlong.png',
'BDUONG': '/images/ktmn/binhduong.png',
'TVINH': '/images/ktmn/travinh.png',
'LAN': '/images/ktmn/Longan.png',
'BPHUOC': '/images/ktmn/binhphuoc.png',
'HGIANG': '/images/ktmn/haugiang.png',
'TGIANG': '/images/ktmn/tiengiang.png',
'KGIANG': '/images/ktmn/Kiengiang.png',
'DLAT': '/images/ktmn/dalat.png'
};
const nameOfWeek = [
    '',
    'THỨ 2',
    'THỨ 3',
    'THỨ 4',
    'THỨ 5',
    'THỨ 6',
    'THỨ 7',
    'C.NHẬT',
  ];
class KTMN extends Component {
    constructor(props) {
        super(props);
        this.state={
            dateFilter: moment().format('DD/MM/YYYY'),
            optionDateDraw:[],
            dataTickets:[],
            todayAreas:[],
            selectedTicket:null,
            objReadyToBuy:[], // {idTicket, numOfTicket}
            price:{ticket:0,service:0},
            filterNumber:'',
            filterProductOption:null,
            isShow_paymentOk:false
        }
        this.allDrawData=[];
        this.loading=false;
    }
    async componentDidMount() {
    }
    componentDidMount(){
        this.getDateDraw(true);
    }
    getDateDraw=(isFirst=false)=>{
        apiOrder.getKTMNAvailDate((err,res)=>{
            if(!err){
                this.allDrawData=res;
                let allDates=[];
                res.map(e=>{
                    //if(e.ticketRemain>0)
                    if(allDates.indexOf(moment(e.openTime,'DD-MM-YYYY HH:mm:ss').format('DD/MM/YYYY'))==-1){
                        allDates.push(moment(e.openTime,'DD-MM-YYYY HH:mm:ss').format('DD/MM/YYYY'))
                    }
                });
                
                this.setState({
                    dateFilter:allDates[0],
                    optionDateDraw:allDates,
                    
                },()=>{
                    if(isFirst)
                        this.getTickets(null,null)
                    else
                    this.getTickets(null,allDates[0])
                });
               
            }
        })
    }
    getTickets=(productOptionId,date)=>{
        apiOrder.getKTMNAvaiTicket(date,productOptionId,(err,res)=>{
            if(!err){
                
                let allAreas=date?this.allDrawData.filter(e=>moment(e.openTime,'DD-MM-YYYY HH:mm:ss').format('DD/MM/YYYY')==date):this.allDrawData;
                this.setState({
                    todayAreas:allAreas,
                    dataTickets:res,
                });
                
            }
        })
    }
    renderTicket=(item)=>{
        return <a onClick={()=>{this.openTicketDetail(item)}} className={styles.listKienThiet}>
            <div
            className={`${styles.numberList} ${this.state.objReadyToBuy.find(e=>e.idTicket==item.id)&&styles.numberListActive}`}
            >
                <img
                    src={ktmnLogo[getKeyNameById(14,item.productOptionId)]}
                    style={{width:30,height:30,objectFit:'contain'}}
                   
                />
                <span className={this.state.objReadyToBuy.find(e=>e.idTicket==item.id)?styles.txtTicketActive:styles.txtTicket}>{item.ticket}</span>
                <div className={styles.numberThree}>
                <span className={styles.numberThreeText}>{item.maxTicket-item.ticketBuyed}</span>
                

               {this.state.objReadyToBuy.find(e=>e.idTicket==item.id) ?<span 
                className={styles.txtRemain}
               >{this.state.objReadyToBuy.find(e=>e.idTicket==item.id).numOfTicket}</span>:<div className={`${styles.txtRemainHide}`}>{0}</div>}
                </div>
            </div>
            
        </a>
    }
    finishModal=(num)=>{
        /**
         * [
         * idTicket: num
         * numOfTicket:num
         * ]
         * 
         */
        let objReadyToBuy=[].concat(this.state.objReadyToBuy);
        // have ticket
        let idxTicketReady=objReadyToBuy.findIndex(e=>e.idTicket==this.state.selectedTicket.id)
        if(idxTicketReady!=-1){
            if(num==0){
                objReadyToBuy.splice(idxTicketReady,1);
            }
            else{
                objReadyToBuy[idxTicketReady].numOfTicket=num;
            }
        }
        else{
            if(num==0) {
                this.setState({selectedTicket:null});
                return;
            }
            else{
                objReadyToBuy.push({
                    idTicket:this.state.selectedTicket.id,
                    numOfTicket:num
                })
            }
        }
        this.setState({objReadyToBuy:objReadyToBuy,selectedTicket:null},()=>{
            this.calculatePrice();
        })
    }
    calculatePrice=()=>{
        apiOrder.checkKTMNPrice(this.state.objReadyToBuy,(err,res)=>{
            if(err){
                alert(err)
            }
            else{
                this.setState({price:{
                    ticket:res.ticketPrice,
                    service:res.servicePrice
                }})
            }
        })
    }
    openTicketDetail=(item)=>{
       
        if(item.maxTicket-item.ticketBuyed<=0 )return;
        apiOrder.getDetailKTMN(item.id,(err,res)=>{
            if(err){
                alert(err)
            }
            else{
                //update ticket
               
                let newState=[].concat(this.state.dataTickets);
                let newTicket=this.state.dataTickets.findIndex(e=>e.id==item.id);
                newState[newTicket]=res;
                if((res.maxTicket-res.ticketBuyed)<=0){
                    this.setState({dataTickets:newState});
                    return;
                }
                this.setState({dataTickets:newState,selectedTicket:res});
            }
        })
    }
    parseDateOptions=()=>{
        return this.state.optionDateDraw.map(e=>{
            return {
                key:e,
                value:nameOfWeek[ moment(e,'DD/MM/YYYY').isoWeekday()] +' - '+e,
            }
        })
    }
    getTicketFromFilter=()=>{
       let result= this.state.dataTickets.filter(e=> e.ticket.includes(this.state.filterNumber) && (e.maxTicket-e.ticketBuyed)>0)
       if(this.state.filterProductOption){
        result=result.filter(e=>e.productOptionId==this.state.filterProductOption);
       }
       let resultGroupByDate=[];
       this.state.optionDateDraw.map(d=>{
        resultGroupByDate.push({
            title:d,
            data:result.filter(e=>moment(e.openTime,"DD-MM-YYYY HH:mm:ss").format("DD/MM/YYYY")==d)
        })
       })
       return resultGroupByDate.filter(e=>e.data.length>0);
    }
    buyTicket=()=>{
        if(this.state.objReadyToBuy.length==0){
            utils.alert("Quý khách chưa chọn vé nào!");return
        }
        if(this.loading==true)return;
        this.loading=true;
        apiOrder.buyKTMN({dataBuy:this.state.objReadyToBuy,shipment: {
            "type": 2,
            "fullName": this.props.user.fullName,
            "phoneNumber": this.props.user.phoneNumber,
            "idCardNumber": this.props.user.idCardNumber,
            "cityId": this.props.user.cityId,
            "districtId": this.props.user.districtId,
            "address": this.props.user.address
        }}, (err,res)=>{
            this.loading=false;
            if(err){
                utils.alert(err);return;
            }
            else{
                this.setState({isShow_paymentOk:true,objReadyToBuy:[],price:{ticket:0,service:0}});
                return;
            }
        })
    }
    renderTicketGroup=()=>{
        return this.getTicketFromFilter().map(group=>{
            return (<div>
                        <div className={styles.groupTime}>
                        <span>{group.title}</span>    
                        </div>
                        <div className={styles.rowTickets}>
                            {group.data.map(e=>this.renderTicket(e))}
                        </div>
                    </div>)
        })
    }
    render() {
        return (
            <div style={{height:'100%'}}>
            {
            this.state.selectedTicket&&<ModalKTMN
                ticket={this.state.selectedTicket}
                modalVisible={this.state.selectedTicket!=null}
                finished={(num)=>{this.finishModal(num)}}
                objTicketReady={this.state.objReadyToBuy}
            />
            }
            <ModalImageLucky
            isOpen={this.state.isShow_paymentOk}
            toggle={() => {
              this.setState({isShow_paymentOk:false})
              this.props.history.push("/shopping-history?type=1")
              
            }}
          />
            <div className={styles.container}>
            <div className={styles.filterContainer}>
                <div className={styles.rowCenter}>
                    <div style={{paddingRight:10,width:'50%',zIndex:99}}>
                    <Input
                      type="select"
                      style={{ fontSize:13}}
                      onChange={(event) => {
                       // this.handleChangeProductOption(event.target.value);
                        this.setState({dateFilter:event.target.value},()=>{this.getTickets(null,this.state.dateFilter)})
                      }}
                    >
                      {this.parseDateOptions().map(({ key, value }) => (
                        <option key={key.toString()} value={key}>
                          {value}
                        </option>
                      ))}
                    </Input>
                      
                    </div>
                    <div className={styles.inputBox}>
                        <input
                            style={{padding:0,fontSize:13,borderStyle:'none'}}
                            placeholder='Tìm kiếm số đẹp'
                            placeholderTextColor={'gray'}
                            value={this.state.filterNumber} 
                            onChange={(event)=>this.setState({filterNumber:event.target.value})}
                            keyboardType='number-pad'
                        />
                        <FontAwesomeIcon
                        icon={faSearch}
                        style={{ marginRight: 2,martinLeft:2,color:'gray', }}
                        />
                       
                    </div>
                    
                </div>
                <div className={styles.rowArea}>
                        {
                            this.state.todayAreas.map(e=> (<a 
                            className={this.state.filterProductOption==e.ktType.id?styles.btnAreaActive:styles.btnArea}
                           onClick={()=>this.setState({filterProductOption:this.state.filterProductOption==e.ktType.id?null:e.ktType.id})}>
                                <img
                                    src={ktmnLogo[e.ktType.code]}
                                    style={{width:'100%',height:40}}
                                    resizeMode='center'
                                />
                                <div style={{fontSize:10,color:'#000',textAlign:'center'}}>{e.ktType.name.replace('Hồ Chí Minh','HCM')}</div>
                            </a>))
                        }
                       
                    </div>
            </div>
          
            <div className={styles.containerTicket}>
                {
                    this.state.dataTickets.length==0?
                    <div style={{flex:1,margin:5}}>Không có vé</div>
                :
                this.renderTicketGroup()
                }            
            </div>
            <div className={styles.priceContainer}>
                <div style={{borderBottomWidth:1,borderBottomColor:'#BCBABA',paddingBottom:5,marginBottom:10,alignItems:'center'}}>
                    <div style={{justifyContent:'space-between',width:'50%',display:'flex',flexDirection:'row'}}>
                    <span style={{textAlign:'left',color:'#000'}}>Tiền vé: </span>
                    <span style={{textAlign:'right',fontWeight:'bold',color:'#000'}}>{(this.state.price.ticket* 1000).getMoneyFormat()} đ</span>
                    </div>
                    <div style={{justifyContent:'space-between',width:'50%',display:'flex',flexDirection:'row'}}>
                    <span style={{textAlign:'left',color:'#000'}}>Giữ hộ:</span>
                    <span style={{textAlign:'right',color:'#000'}}>{(this.state.price.service* 1000).getMoneyFormat()} đ</span>
                    </div>
                </div>
                <div style={{justifyContent:'space-between',width:'60%',display:'flex',flexDirection:'row',alignSelf:'center'}}>
                <span style={{textAlign:'center',color:'#000',fontWeight:'bold'}}>Tổng cộng: </span>
                <span style={{color:'red',fontWeight:'bold'}}>{((this.state.price.ticket+this.state.price.service)*1000).getMoneyFormat()}đ</span>
                </div>
                <a onClick={this.buyTicket} style={{backgroundColor:'#D62800',borderRadius:20,paddingVertical:10,marginVertical:10,marginTop:5,marginBottom:5}}>
                    <div style={{textAlign:'center',color:'#fff',fontSize:16,fontWeight:'bold'}}>Đặt vé</div>
                </a>
                <a onClick={()=>this.setState({objReadyToBuy:[],price:{ticket:0,service:0}})} >
                    <div style={{color:'gray',textAlign:'center',fontWeight:'bold',fontSize:16}}>Huỷ</div>
                </a>
            </div>
        </div>
            </div>
        );
    }
}

export async function getStaticProps() {
var seo = await utils.fetchSeo("ktmb.json");
return {
    props: {
    seo,
    },
    revalidate: 30,
};
}

  function mapStateToProps(state) {
    return {
        cartData: state.cartData.cart,
        user: state.user,
    };
  }
  
  export default connect(mapStateToProps, {})(KTMN);
