import React, {Component} from 'react';

import {Row, Col, FormGroup, Label, Input} from "reactstrap";

import ButtonLoading from "../../components/FworkTag/ButtonLoading";

import apiAuth from "../../api/Auth";

import utils from "../../utils";
import ValidInput from "../../utils/ValidInput";

class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            step: 1,
            isLoading: false,
            otp: "",
            newPassword: "",
            confirmPassword: "",
            phoneNumber: ""
        }
    }

    handleRequestRecovery() {
        if (this.state.isLoading) return;
        this.setState({isLoading: true});
        apiAuth.requestChangePassword(this.state.phoneNumber, (err, result) => {
            if (err) {
                this.setState({isLoading: false})
            } else {
                this.setState({
                    step: 2,
                    isLoading: false
                })
            }
        })
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleChangePassword() {
        if (this.state.isLoading) return;

        if(ValidInput.testPassword(this.state.newPassword)) {
            return;
        }

        if(this.state.newPassword !== this.state.confirmPassword) {
            utils.alert("Xác nhận mật khẩu không chính xác!");
            return;
        }

        this.setState({isLoading: true});
        apiAuth.changePasswordRecovery({
            phoneNumber: this.state.phoneNumber,
            password: this.state.newPassword,
            otp: this.state.otp
        }, (err, result) => {
            if(err) {
                this.setState({isLoading: false})
            } else {
                utils.alert("Đổi mật khẩu thành công");
                window.location.replace("/auth/sign-in");
            }
        })
    }

    render() {
        return (
            <div className="bootstrap-reset">
                <Col className="p-3">
                    {
                        this.state.step === 1 ?
                            <>
                                <FormGroup>
                                    <Label>Số điện thoại</Label>
                                    <Input
                                        type="numeric"
                                        placeholder="Số điện thoại"
                                        value={this.state.phoneNumber}
                                        name="phoneNumber"
                                        onChange={this.handleChange.bind(this)}
                                    />
                                </FormGroup>
                                <Row className="mt-3 justify-content-center">
                                    <ButtonLoading
                                        loading={this.state.isLoading}
                                        title={"Tiếp tục"}
                                        onClick={this.handleRequestRecovery.bind(this)}
                                    />
                                </Row>
                            </>
                            :
                            <>
                                <FormGroup>
                                    <Label>Số điện thoại</Label>
                                    <Input
                                        type="numeric"
                                        placeholder="Số điện thoại"
                                        readOnly
                                        value={this.state.phoneNumber}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Mật khẩu mới</Label>
                                    <Input
                                        type="password"
                                        placeholder="Mật khẩu mới"
                                        name={'newPassword'}
                                        value={this.state.newPassword}
                                        onChange={this.handleChange.bind(this)}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Xác nhận mật khẩu</Label>
                                    <Input
                                        type="password"
                                        placeholder="Xác nhận mật khẩu"
                                        name={'confirmPassword'}
                                        value={this.state.confirmPassword}
                                        onChange={this.handleChange.bind(this)}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Mã OTP</Label>
                                    <Input
                                        type="numberic"
                                        placeholder="Mã OTP"
                                        name={'otp'}
                                        value={this.state.otp}
                                        onChange={this.handleChange.bind(this)}
                                    />
                                </FormGroup>
                                <Row className="mt-3 justify-content-center">
                                    <ButtonLoading
                                        loading={this.state.isLoading}
                                        title={"Xác nhận"}
                                        onClick={this.handleChangePassword.bind(this)}
                                    />
                                </Row>
                            </>
                    }
                </Col>
            </div>
        );
    }
}

export default ForgotPassword;
