import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {
    Row, Col
} from "reactstrap";
import utils from "../../utils";
import {Phone, Mail, Facebook} from "react-feather";
import {Helmet} from "react-helmet";
import colors from "../../utils/colors";
const logo = require("../../assets/images/logo/dien-toan.png");
const content = `<div style="padding:12px"><style>
table{
    border: #F15922 1px solid;
    width:100%
}
table th{
background-color: #F15922;
    color: #ffffff;
    border-color: #f7a182;
    text-align: center;
    padding: 7px;
    border-width: 1px;
    border-style: solid;
}
    table td{
    border-color: #F15922;
    border-right-color: #f7a182;
        text-align: center;
    padding: 7px;
    border-width: 1px;
    border-style: solid;
}
</style><h4>Vé số MAX 3D+</h4>
<h3>Giới thiệu</h3>
<ul>
<li>Chỉ từ<span>&nbsp;</span><strong>10.000đ</strong>, chọn<span>&nbsp;</span><strong>cặp số có 3 chữ số từ 000-999</strong><span>&nbsp;</span>để có cơ hội trúng thưởng nhiều giải thưởng khác nhau.</li>
<li><strong>Max 3D+</strong><span>&nbsp;</span>quay số mở thưởng vào<span>&nbsp;</span><strong>18h00</strong><span>&nbsp;</span>các<span>&nbsp;</span><strong>ngày thứ 2, thứ 4 và thứ 6 hàng tuần</strong>.</li>
</ul>
<p><strong>MAX 3D+ CƠ BẢN</strong></p>
<ul>
<li>Người chơi chọn<span>&nbsp;</span><strong>2 số</strong><span>&nbsp;</span>có 3 chữ số từ 000 - 999 để tạo thành dãy số dự thưởng.</li>
<li>Kết quả mở thưởng dựa theo dãy số mở thưởng của cách chơi MAX 3D thông thường.</li>
<li>Dãy số trúng thưởng không cần trùng khớp theo thứ tự của dãy số mở thưởng.</li>
</ul>

<ul>
<li style="font-weight: 400;" aria-level="1"><span>Gia tăng tỉ lệ trúng thưởng.</span></li>
<li style="font-weight: 400;" aria-level="1"><span>Cộng gộp nhiều giải thưởng.</span></li>
<li style="font-weight: 400;" aria-level="1"><span>Tiết kiệm thời gian chọn số.</span></li>
<li style="font-weight: 400;" aria-level="1"><span>Giảm thiểu tỷ lệ rủi ro sót số mình muốn chọn.</span></li>
</ul>
<p><strong>VÉ MAX 3D+ ĐẢO SỐ.</strong></p>
<p><span>Bạn sẽ được chọn đảo bộ số thứ nhất hoặc bộ số thứ 2.</span></p>
<p><span>Ví dụ bạn chọn đảo bộ số thứ nhất bộ số đầu tiên mà bạn chọn sẽ được đảo thứ tự các số trong số thứ nhất số thứ hai giữ nguyên, bộ số bạn chọn <strong>123 - 456&nbsp;</strong></span></p>
<p><span>Máy tính sẽ tạo cho các bộ số sau.</span></p>
<h3>&nbsp;</h3>
<table style="border-collapse: collapse; width: 32.1637%; margin-left: auto; margin-right: auto;">
<tbody>
<tr>
<td style="width: 26.892%;">
<p><span>123 - 456</span></p>
</td>
<td style="width: 26.892%;">
<p><span>132 - 456</span></p>
</td>
<td style="width: 26.892%;">
<p><span>213 - 456</span></p>
</td>
</tr>
<tr>
<td style="width: 26.892%;">
<p><span>231 - 456</span></p>
</td>
<td style="width: 26.892%;">
<p><span>321 - 456</span></p>
</td>
<td style="width: 26.892%;">
<p><span>312 - 456</span></p>
</td>
</tr>
</tbody>
</table>
<p><span>Đảo số thứ hai cũng tương tự đảo số thứ nhất.</span></p>
<p><strong>VÉ MAX 3D+ ÔM MỘT HOẶC HAI VỊ TRÍ.</strong></p>
<p><span>Bạn sẽ chọn 1 vị trí trong bộ số để cố định vị trí đó sau đó máy tính sẽ tiến hành thay số mà bạn cố định bằng các số từ 0-9&nbsp;</span></p>
<p><span>Ví dụ bạn chọn ôm 1 vị trí bộ số <strong>*23 - 456</strong> các bộ số được tạo ra.</span></p>
<h3>&nbsp;</h3>
<table style="border-collapse: collapse; margin-left: auto; margin-right: auto;">
<tbody>
<tr>
<td>
<p><span>023 - 456</span></p>
</td>
<td>
<p><span>523 - 456</span></p>
</td>
</tr>
<tr>
<td>
<p><span>123 - 456</span></p>
</td>
<td>
<p><span>623 - 456</span></p>
</td>
</tr>
<tr>
<td>
<p><span>223 - 456</span></p>
</td>
<td>
<p><span>723 - 456</span></p>
</td>
</tr>
<tr>
<td>
<p><span>323 - 456</span></p>
</td>
<td>
<p><span>823 - 456</span></p>
</td>
</tr>
<tr>
<td>
<p><span>423 - 456</span></p>
</td>
<td>
<p><span>923 - 456</span></p>
</td>
</tr>
</tbody>
</table>
<h3>&nbsp;</h3>
<p><span>Chọn ôm 2 vị trí sẽ tương tự như vậy.</span></p>
<section class="prizes">
<table class="max3d table-striped table-hover">
<tbody>
<tr>
<th>Cơ cấu giải thưởng</th>
<th>Dãy số tham gia</th>
<th>Giá trị giải thưởng</th>
</tr>
<tr>
<td>Giải Nhất</td>
<td>Trùng 2 bộ số mở thưởng Giải Nhất</td>
<td>1.000.000.000đ</td>
</tr>
<tr>
<td>Giải Nhì</td>
<td>Trùng 2 trong 4 bộ số mở thưởng Giải Nhì</td>
<td>40.000.000đ</td>
</tr>
<tr>
<td>Giải Ba</td>
<td>Trùng 2 trong 6 bộ số mở thưởng Giải Ba</td>
<td>10.000.000đ</td>
</tr>
<tr>
<td>Giải Tư</td>
<td>Trùng 2 trong 8 bộ số mở thưởng giải Khuyến khích</td>
<td>5.000.000đ</td>
</tr>
<tr>
<td>Giải Năm</td>
<td>Trùng bất kỳ 2 dãy số giải Nhất, Nhì, Ba hoặc Khuyến khích</td>
<td>1.000.000đ</td>
</tr>
<tr>
<td>Giải Sáu</td>
<td>Trùng 1 số trong 2 bộ số mở thưởng Giải Nhất</td>
<td>150.000đ</td>
</tr>
<tr>
<td>Giải Bảy</td>
<td>Trùng bất kỳ 1 số thuộc giải Nhì, Ba hoặc Khuyến Khích</td>
<td>40.000đ</td>
</tr>
</tbody>
</table>
</section>
<div class="alert-warning">
<p><strong>Cơ cấu giải thưởng áp dụng với vé dự thưởng gồm hai số ba chữ số khác nhau.</strong></p>
<ul>
<li style="font-weight: 400;" aria-level="1"><span>Nếu người chơi chọn hai số ba số giống nhau, giá trị giải thưởng sẽ cao gấp đôi giá trị nếu ở bảng trên ( trừ giải Đặc Biệt và Giải Nhất ).</span></li>
<li style="font-weight: 400;" aria-level="1"><span>Trường hợp hai bộ số của người chơi trùng nhiều giải thưởng, người chơi sẽ được lãnh tất cả các giải thưởng.</span></li>
<li style="font-weight: 400;" aria-level="1"><span>Giá trị lĩnh thưởng theo số lần tham gia dự thưởng số trúng thưởng ( 01 lần tham gia dự thưởng mệnh giá 10.000 đồng ) nhân với giá trị thưởng tương ứng với 01 lần tham gia dự thưởng.</span></li>
<li style="font-weight: 400;" aria-level="1"><span>Giá trị giải thưởng sẽ ứng với giá trị bộ số bạn mua ( Bạn mua bộ số giá 10.000 đồng khi trúng giải Đặc Biệt bạn sẽ nhận được 1.000.000.000 đồng, bạn mua bộ số giá 20.000 đồng khi trúng giải Đặc Biệt bạn sẽ nhận được 2.000.000.000 đồng….)</span></li>
</ul>
<h3>&nbsp;</h3>

</div></div>`

class Max3dPlus extends Component {
    constructor(props)
    {
        super(props);
        this.state={seo:{}}
    }
    async componentDidMount(){            
    }
    render() {
        return (
            <div className='p2'>
                <div dangerouslySetInnerHTML={{__html: content}} />
        
            </div>
        );
    }
}

export default Max3dPlus;
