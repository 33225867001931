import React, {Component} from 'react';

import MainLottery from "../MainLottery";
import LotterySelector from "../../../components/Vietluck/LotterySelector";

import {connect} from "react-redux";
import {lotterySet, lotteryChange} from "../../../redux/actions/lotterySelectorAction";
import LoadingPage from "../../../components/LoadingPage";

import moment from "moment";
import {Helmet} from "react-helmet";
import utils from "../../../utils";
const apiLottery = require("../../../api/Lottery");

class LotteryKeno extends Component {

    constructor(props) {
        super(props);
        this.productId = 6;

        this.state = {
            isRandom: false,
            seo:{}
        }
    }

    async  componentDidMount() {
        apiLottery.getTerm(this.productId, (err, result) => {
            if (err) {
                // Alert.alert("Đồng bộ dữ liệu thất bại", "Vui lòng thử lại sau", [{text: "OK", onPress: () => this.props.navigation.goBack()}])
            } else {
                this.props.lotterySet({
                    "productId": this.productId,
                    "termArray": result,
                    "term": [result[0].termValue],
                });
                this.setState({isLoaded: true})
            }
        });
        // let expiry = utils.isExpiredKeno();
        // if(expiry) {
        //     utils.confirm("Đã hết thời gian mua vé KENO. Quý khách vui lòng quay lại sau " + expiry, (check) => {
        //         window.location.replace("/");
        //     });
        // }
        let now=moment();  
        if(now.hours() === 8) {
            if(now.minutes() <0) {
                utils.confirm("Đã hết thời gian mua vé KENO. Quý khách vui lòng quay lại sau 8h00", (check) => {
                    window.location.replace("/");
                });
            }
            return;
        }     
        if(now.hours() === 21) {
            if(now.minutes() > 48) {
                utils.confirm("Đã hết thời gian mua vé KENO. Quý khách vui lòng quay lại sau 8h00", (check) => {
                    window.location.replace("/");
                });
            }
            return;
        }
        if(now.hours() > 21 ||now.hours() <8) {        
                utils.confirm("Đã hết thời gian mua vé KENO. Quý khách vui lòng quay lại sau 8h00", (check) => {
                    window.location.replace("/");
                });
        
            return;
        }
        var seo= await utils.fetchSeo('keno.json');
        this.setState({seo:seo});
    }

    toggle(component) {
        this.setState({
            [component]: !this.state[component]
        });
    }

    handleRandom() {
        this.setState({isRandom: true})
    }

    handleValueChange(value) {
        this.setState({value: value})
    }

    render() {
        if(!this.state.isLoaded) {
            return (
                <LoadingPage/>
            )
        }
        return (
            <MainLottery
                onRandom={this.handleRandom.bind(this)}
            >
            {/* <Helmet>
                    <meta charSet="utf-8" />
                    <title>{this.state.seo.title}</title>
                    {
                        this.state.seo.canonical===''?null: <link rel="canonical" href={this.state.seo.canonical} />
                    }
                   
                    <meta
                        name="keywords"
                        content={this.state.seo.keyword}
                    />
                    <meta
                    name="description"
                    content={this.state.seo.description}
                    />
                    <meta property="og:url" content={this.state.seo.url}/>                    
                    <meta property="og:type" content="website"/>
                    <meta property="og:title" content={this.state.seo.title}/>
                    <meta property="og:description" content={this.state.seo.description}/>                    
                    <meta property="og:image" content={this.state.seo.thumb}/>

                </Helmet> */}
                <LotterySelector
                    productId={this.props.lotterySelector.productId}
                    productOptionId={this.props.lotterySelector.productOptionId}
                    isRandom={this.state.isRandom}
                    doneRandom={() => this.setState({isRandom: false})}
                    onValueChange={this.handleValueChange.bind(this)}
                />
            </MainLottery>
        );
    }
}

function mapStateToProps(state) {
    return {
        lotterySelector: state.lotterySelector
    }
}

export default connect(mapStateToProps, {lotterySet, lotteryChange})(LotteryKeno);
