import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {
    Row, Col
} from "reactstrap";
import utils from "../../utils";
import {Phone, Mail, Facebook} from "react-feather";
import {Helmet} from "react-helmet";
import colors from "../../utils/colors";
const logo = require("../../assets/images/logo/dien-toan.png");
const content = `<div style="padding:12px"><style>
table{
    border: #F15922 1px solid;
    width:100%
}
table th{
background-color: #F15922;
    color: #ffffff;
    border-color: #f7a182;
    text-align: center;
    padding: 7px;
    border-width: 1px;
    border-style: solid;
}
    table td{
    border-color: #F15922;
    border-right-color: #f7a182;
        text-align: center;
    padding: 7px;
    border-width: 1px;
    border-style: solid;
}
</style><h4>Vé số MEGA 6/45</h4>
<div class=" min-vh-100">
<div class="container mb-lg-5 mb-0 pt-lg-0 pt-4">
<div class="border-5 px-3">
<div class="">
<div class=" pl-lg-2 bg-white py-4 mb-3 mb-lg-0">
<div class="h-100">
<div id="__BVID__436" class="tabs articleContentTab">
<div id="__BVID__436__BV_tab_container_" class="tab-content">
<div id="__BVID__437" class="" role="tabpanel" aria-hidden="false" aria-labelledby="__BVID__437___BV_tab_button__">
<div class="container px-lg-3 px-0 pt-4">
<div class="fullDescription">
<div class="vl-content">
<h3>Giới thiệu</h3>
<ul>
<li>Chỉ từ<span>&nbsp;</span><strong>10.000đ</strong>, chọn<span>&nbsp;</span><strong>6 số từ 01-45</strong><span>&nbsp;</span>để có cơ hội trúng thưởng<span>&nbsp;</span><strong>Jackpot từ 12 tỷ đồng</strong>.</li>
<li><strong>MEGA 6/45</strong><span>&nbsp;</span>quay số mở thưởng vào<span>&nbsp;</span><strong>18h00</strong><span>&nbsp;</span>các ngày<span>&nbsp;</span><strong>thứ 4, thứ 6 và Chủ Nhật hàng tuần</strong>.</li>
</ul>
<h3>Luật chơi CƠ BẢN</h3>
<ul>
<li>Người chơi chọn ra 6 con số từ 01 - 45, tạo thành dãy số dự thưởng.</li>
<li>Kết quả trúng thưởng chỉ cần trùng khớp với dãy số mở thưởng, không cần theo thứ tự.</li>
</ul>
<section class="prizes">
<table class="mega table-striped table-hover">
<tbody>
<tr>
<th>Nhóm giải thưởng</th>
<th>Số trúng thưởng</th>
<th>Giải thưởng</th>
</tr>
<tr>
<td>Giải đặc biệt (Jackpot)</td>
<td>Trùng 6 số kết quả</td>
<td>Jackpot tối thiểu là 12 tỷ VNĐ</td>
</tr>
<tr>
<td>Giải Nhất</td>
<td>Trùng 5 số kết quả</td>
<td>10.000.000 VNĐ</td>
</tr>
<tr>
<td>Giải Nhì</td>
<td>Trùng 4 số kết quả</td>
<td>300.000 VNĐ</td>
</tr>
<tr>
<td>Giải Ba</td>
<td>Trùng 3 số kết quả</td>
<td>30.000 VNĐ</td>
</tr>
</tbody>
</table>
</section>
<h3>Luật chơi BAO</h3>
<ul>
<li>Chơi Bao tức là người chơi được chọn số con số mà bản thân muốn, tùy theo khả năng tài chính của người chơi.</li>
<li>Với hình thức Mega, người chơi có thể chơi bao theo cách thức chọn<span>&nbsp;</span><strong>tối thiểu 5 số</strong><span>&nbsp;</span>và<span>&nbsp;</span><strong>tối đa 18 số</strong><span>&nbsp;</span>cố định để tham gia dự thưởng như sau:
<ul>
<li>Với hình thức<span>&nbsp;</span><strong>Bao 5</strong>, người chơi sẽ lựa chọn<span>&nbsp;</span><strong>5 số cố định</strong><span>&nbsp;</span>trong<span>&nbsp;</span><strong>tổng số 6 số dự thưởng</strong>. Số còn lại sẽ được chọn trong 40 số còn lại (khác với 5 số cố định đã chọn).</li>
<li>Với các hình thức<span>&nbsp;</span><strong>Bao</strong><span>&nbsp;</span>còn lại, người chơi sẽ lựa chọn số lượng con số muốn bao. Dãy số dự thưởng sẽ được tạo nên từ việc thay đổi sự xuất hiện của những số con số này.</li>
</ul>
</li>
</ul>
Ví dụ: Người chơi chọn<span>&nbsp;</span><strong>Bao 7</strong><span>&nbsp;</span>với các con số sau đây:<span>&nbsp;</span><strong>01 - 07 - 13 - 21 - 27 - 34 - 44</strong>, thì sẽ có 7 bộ số được tạo ra như sau:
<ul>
<li>Dãy 1: [01 - 07 - 13 - 21 - 27 - 34]</li>
<li>Dãy 2: [07 - 13 - 21 - 27 - 34 - 44]</li>
<li>Dãy 3: [01 - 07 - 13 - 21 - 27 - 44]</li>
<li>Dãy 4: [01 - 13 - 21 - 27 - 34 - 44]</li>
<li>Dãy 5: [01 - 07 - 21 - 27 - 34 - 44]</li>
<li>Dãy 6: [01 - 07 - 13 - 27 - 34 - 44]</li>
<li>Dãy 7: [01 - 07 - 13 - 21 - 34 - 44]</li>
</ul>
<section class="prizes">
<table class="mega table-striped table-hover">
<tbody>
<tr>
<th>Chọn Bao</th>
<th>Chọn số cố định</th>
<th>Tổng dãy số tạo nên</th>
<th>Tổng giá vé (*)</th>
</tr>
<tr>
<td>Bao 5</td>
<td>5 số</td>
<td>40 bộ số</td>
<td>400.000đ</td>
</tr>
<tr>
<td>Bao 7</td>
<td>7 số</td>
<td>7 bộ số</td>
<td>70.000đ</td>
</tr>
<tr>
<td>Bao 8</td>
<td>8 số</td>
<td>28 bộ số</td>
<td>280.000đ</td>
</tr>
<tr>
<td>Bao 9</td>
<td>9 số</td>
<td>84 bộ số</td>
<td>840.000đ</td>
</tr>
<tr>
<td>Bao 10</td>
<td>10 số</td>
<td>210 bộ số</td>
<td>2.100.000đ</td>
</tr>
<tr>
<td>Bao 11</td>
<td>11 số</td>
<td>462 bộ số</td>
<td>4.620.000đ</td>
</tr>
<tr>
<td>Bao 12</td>
<td>12 số</td>
<td>924 bộ số</td>
<td>9.240.000đ</td>
</tr>
<tr>
<td>Bao 13</td>
<td>13 số</td>
<td>1.716 bộ số</td>
<td>17.160.000đ</td>
</tr>
<tr>
<td>Bao 14</td>
<td>14 số</td>
<td>3.003 bộ số</td>
<td>30.030.000đ</td>
</tr>
<tr>
<td>Bao 15</td>
<td>15 số</td>
<td>5.005 bộ số</td>
<td>50.050.000đ</td>
</tr>
<tr>
<td>Bao 18</td>
<td>18 số</td>
<td>18.564 bộ số</td>
<td>185.640.000đ</td>
</tr>
</tbody>
</table>
<em>(*) giá trên chưa bao gồm phí giao dịch</em></section>

<h4>Giá trị giải thưởng được tính như sau</h4>
<section class="prizes">
<table class="mega table-striped table-hover">
<tbody>
<tr>
<th colspan="2">Bao 5</th>
</tr>
<tr>
<td>Trùng 2 số</td>
<td>120.000đ</td>
</tr>
<tr>
<td>Trùng 3 số</td>
<td>2.010.000đ</td>
</tr>
<tr>
<td>Trùng 4 số</td>
<td>31.400.000đ</td>
</tr>
<tr>
<td>Trùng 5 số</td>
<td><span>Jackpot</span><span>&nbsp;</span>+ 390 triệu đồng</td>
</tr>
</tbody>
</table>
</section>
<section class="prizes">
<table class="mega table-striped table-hover">
<tbody>
<tr>
<th colspan="2">Bao 7</th>
</tr>
<tr>
<td>Trùng 3 số</td>
<td>120.000đ</td>
</tr>
<tr>
<td>Trùng 4 số</td>
<td>1.020.000đ</td>
</tr>
<tr>
<td>Trùng 5 số</td>
<td>21,5 triệu</td>
</tr>
<tr>
<td>Trùng 6 số</td>
<td><span>Jackpot</span><span>&nbsp;</span>+ 60 triệu đồng</td>
</tr>
</tbody>
</table>
</section>
<section class="prizes">
<table class="mega table-striped table-hover">
<tbody>
<tr>
<th colspan="2">Bao 8</th>
</tr>
<tr>
<td>Trùng 3 số</td>
<td>300.000đ</td>
</tr>
<tr>
<td>Trùng 4 số</td>
<td>2.280.000đ</td>
</tr>
<tr>
<td>Trùng 5 số</td>
<td>34,8 triệu</td>
</tr>
<tr>
<td>Trùng 6 số</td>
<td><span>Jackpot</span><span>&nbsp;</span>+ 124,5 triệu</td>
</tr>
</tbody>
</table>
</section>
<section class="prizes">
<table class="mega table-striped table-hover">
<tbody>
<tr>
<th colspan="2">Bao 9</th>
</tr>
<tr>
<td>Trùng 3 số</td>
<td>600.000đ</td>
</tr>
<tr>
<td>Trùng 4 số</td>
<td>4,2 triệu</td>
</tr>
<tr>
<td>Trùng 5 số</td>
<td>50,2 triệu</td>
</tr>
<tr>
<td>Trùng 6 số</td>
<td><span>Jackpot</span><span>&nbsp;</span>+ 194,1 triệu</td>
</tr>
</tbody>
</table>
</section>
<section class="prizes">
<table class="mega table-striped table-hover">
<tbody>
<tr>
<th colspan="2">Bao 10</th>
</tr>
<tr>
<td>Trùng 3 số</td>
<td>1.050.000đ</td>
</tr>
<tr>
<td>Trùng 4 số</td>
<td>6,9 triệu</td>
</tr>
<tr>
<td>Trùng 5 số</td>
<td>68 triệu</td>
</tr>
<tr>
<td>Trùng 6 số</td>
<td><span>Jackpot</span><span>&nbsp;</span>+ 269,4 triệu</td>
</tr>
</tbody>
</table>
</section>
<section class="prizes">
<table class="mega table-striped table-hover">
<tbody>
<tr>
<th colspan="2">Bao 11</th>
</tr>
<tr>
<td>Trùng 3 số</td>
<td>1,68 triệu</td>
</tr>
<tr>
<td>Trùng 4 số</td>
<td>10,5 triệu</td>
</tr>
<tr>
<td>Trùng 5 số</td>
<td>88,5 triệu</td>
</tr>
<tr>
<td>Trùng 6 số</td>
<td><span>Jackpot</span><span>&nbsp;</span>+ 351 triệu</td>
</tr>
</tbody>
</table>
</section>
<section class="prizes">
<table class="mega table-striped table-hover">
<tbody>
<tr>
<th colspan="2">Bao 12</th>
</tr>
<tr>
<td>Trùng 3 số</td>
<td>2.520.000đ</td>
</tr>
<tr>
<td>Trùng 4 số</td>
<td>15.120.000đ</td>
</tr>
<tr>
<td>Trùng 5 số</td>
<td>112 triệu</td>
</tr>
<tr>
<td>Trùng 6 số</td>
<td><span>Jackpot</span><span>&nbsp;</span>+ 439,5 triệu</td>
</tr>
</tbody>
</table>
</section>
<section class="prizes">
<table class="mega table-striped table-hover">
<tbody>
<tr>
<th colspan="2">Bao 13</th>
</tr>
<tr>
<td>Trùng 3 số</td>
<td>3,6 triệu</td>
</tr>
<tr>
<td>Trùng 4 số</td>
<td>20,88 triệu</td>
</tr>
<tr>
<td>Trùng 5 số</td>
<td>138,8 triệu</td>
</tr>
<tr>
<td>Trùng 6 số</td>
<td><span>Jackpot</span><span>&nbsp;</span>+ 535,5 triệu</td>
</tr>
</tbody>
</table>
</section>
<section class="prizes">
<table class="mega table-striped table-hover">
<tbody>
<tr>
<th colspan="2">Bao 14</th>
</tr>
<tr>
<td>Trùng 3 số</td>
<td>4.950.000đ</td>
</tr>
<tr>
<td>Trùng 4 số</td>
<td>27,9 triệu</td>
</tr>
<tr>
<td>Trùng 5 số</td>
<td>169,2 triệu</td>
</tr>
<tr>
<td>Trùng 6 số</td>
<td><span>Jackpot</span><span>&nbsp;</span>+ 639,6 triệu</td>
</tr>
</tbody>
</table>
</section>
<section class="prizes">
<table class="mega table-striped table-hover">
<tbody>
<tr>
<th colspan="2">Bao 15</th>
</tr>
<tr>
<td>Trùng 3 số</td>
<td>6,6 triệu</td>
</tr>
<tr>
<td>Trùng 4 số</td>
<td>36,3 triệu</td>
</tr>
<tr>
<td>Trùng 5 số</td>
<td>203,5 triệu</td>
</tr>
<tr>
<td>Trùng 6 số</td>
<td><span>Jackpot</span><span>&nbsp;</span>+ 752,4 triệu</td>
</tr>
</tbody>
</table>
</section>
<section class="prizes">
<table class="mega table-striped table-hover">
<tbody>
<tr>
<th colspan="2">Bao 18</th>
</tr>
<tr>
<td>Trùng 3 số</td>
<td>13,65 triệu</td>
</tr>
<tr>
<td>Trùng 4 số</td>
<td>70,98 triệu</td>
</tr>
<tr>
<td>Trùng 5 số</td>
<td>332,8 triệu</td>
</tr>
<tr>
<td>Trùng 6 số</td>
<td><span>Jackpot</span><span>&nbsp;</span>+ 1,149 triệu</td>
</tr>
</tbody>
</table>
</section>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
<footer id="footer" class="footer myFooter d-none d-lg-block d-none d-lg-block" data-v-67f48eb6="">
<div data-v-67f48eb6="">
<div class="w-100" data-v-67f48eb6="">
<div class="footerMid container" data-v-67f48eb6="">
<div class="row justify-content-between" data-v-67f48eb6="">
<div class="col-lg-5 textGray" data-v-67f48eb6="">&nbsp;</div>
</div>
</div>
</div>
</div>
</footer></div>`

class Mega extends Component {
    constructor(props)
    {
        super(props);
        this.state={seo:{}}
    }
    async componentDidMount(){            
    }
    render() {
        return (
            <div className='p2'>
                <div dangerouslySetInnerHTML={{__html: content}} />
        
            </div>
        );
    }
}

export default Mega;
