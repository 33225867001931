import React, {Component} from 'react';

import {connect} from "react-redux";

import {removeAllCart} from "../../redux/actions/cartAction";
import {userChange} from "../../redux/actions/userAction";
import {
    Row, Col,
    Card, CardHeader, CardBody,
    Label, Input, CustomInput,
    Collapse
} from "reactstrap";

import ButtonLoading from "../../components/FworkTag/ButtonLoading";
import {ModalImageLucky} from "../../components/Vietluck/ModalVietluck";

import {
    User, Phone, CreditCard
} from "react-feather"

import utils from "../../utils";

import apiOrder from "../../api/Order";
import apiAddress from "../../api/Address";
import apiMyAccount from "../../api/MyAccount";
import {withRouter} from "react-router";

const getProductName = require("../../utils/productData").getProductName;

class Payment extends Component {

    constructor(props) {
        super(props);
        this.city = [];
        this.district = [];
        this.isPaymentKeno = window.location.hash === "#keno";
        this.config = {
            "EXPRESS_DELIVERY_FEE": 2,
            "NORMAL_DELIVERY_FEE": 2,
            "KENO_TIME_LIMIT": 3,
            "GLOBAL_TIME_LIMIT": "17:30:04"
        };
        this.state = {
            fullName: this.props.user.fullName,
            phoneNumber: this.props.user.phoneNumber,
            idCardNumber: this.props.user.idCardNumber,
            address: this.props.user.address,
            cityId: this.props.user.cityId,
            districtId: this.props.user.districtId,

            payment_method: 2,
            shipping_fee: 0,
            servicePrice:0,
            total: 0,
            cart_data: [],
            isLoading: false,

            isShow_paymentOk: false,
            orderCodeCreate:'',
            isOpen_addressChange: false
        };
    }

    componentDidMount() {
        if (utils.isEmpty(this.props.user.authToken) || !this.props.user.isActive) {
            utils.confirm("Vui lòng đăng nhập", (check) => {
                window.location.replace("/auth/sign-in")
            });
            return;
        }

        // if (utils.isEmpty(this.props.user.fullName) || utils.isEmpty(this.props.user.phoneNumber) || utils.isEmpty(this.props.user.idCardNumber)) {
        //     utils.confirm("Vui lòng cập nhật thông tin trước khi thanh toán", (check) => {
        //         window.location.replace("/profile")
        //     });
        //     return;
        // }

        this.setState({
            cart_data: this.isPaymentKeno ? this.props.cartData.filter(dt => dt.productId === 6) : this.props.cartData.filter(dt => dt.productId !== 6)
        }, () => {
            this.getPrice()
        });

        apiAddress.getListCity((err, result) => {
            if (err) {

            } else {
                this.city = result;
                this.toggle("re_render");
            }
        })

        if (!utils.isEmpty(this.props.user.cityId)) {
            apiAddress.getListDistrictByCity(this.state.cityId, (err, result) => {
                if (err) {

                } else {
                    this.district = result;
                    this.toggle("re_render")
                }
            })
        }

        apiOrder.getConfig((err, result) => {
            if (err) {
                // TODO
            } else {
                this.config = result;
                this.toggle("re_render");
            }
        })
    }

    toggle(component) {
        this.setState({
            [component]: !this.state[component]
        });
    }

    calculateTotal() {
        let total = 0;
        this.state.cart_data.forEach((dt) => {
            total += dt.totalPrice
        });
        this.setState({
            total: total
        })
    };
    getPrice=()=>{
        let totalPrice = this.state.shipping_fee + this.state.total;
        let ticketArray = [];

        this.state.cart_data.forEach(dt => {
            if (dt.productOptionKenoTypeId) {
                dt.value.forEach(dat => {
                    let numGen = this.genNumberKenoType(dat.number, dt.productOptionId, dt.productOptionKenoTypeId);
                    let value = [];
                    for (let i = 0; i < numGen.length; i++) {
                        // let value = [{number: numGen[i], price: dat.price}];
                        value.push({number: numGen[i], price: dat.price});
                        if (((i + 1) % 6) === 0 && i !== 0) {
                            ticketArray.push({
                                kenoType:dt.productOptionKenoTypeId,
                                productId: dt.productId,
                                productOptionId: dt.productOptionId,
                                value: value,
                                termArray: dt.termArray,
                            });
                            value = [];
                        }
                    }
                    if (value.length) {
                        ticketArray.push({
                            kenoType:dt.productOptionKenoTypeId,
                            productId: dt.productId,
                            productOptionId: dt.productOptionId,
                            value: value,
                            termArray: dt.termArray,
                        });
                    }
                });
            } else {
                ticketArray.push({
                    productId: dt.productId,
                    productOptionId: dt.productOptionId,
                    value: dt.value,
                    termArray: dt.termArray,
                });
            }
            // console.log(totalPrice);
            // totalPrice += dt.totalPrice;
        });        

        let data = {
            "ticketArray": ticketArray,
            "totalPrice": totalPrice,            
            "shipment": {
                "type": this.state.payment_method,
                "fullName": this.state.fullName,
                "phoneNumber": this.state.phoneNumber,
                "idCardNumber": this.state.idCardNumber,
                "cityId": this.state.cityId,
                "districtId": this.state.districtId,
                "address": this.state.address
            }
        };
        apiOrder.checkPrice(data, (err, result) => {
            if (err) {
                //this.setState({isLoading: false});
            } else {
                this.setState({total:result.totalPrice,servicePrice:result.servicePrice,orderCodeCreate:result.orderCode})
            }
        })
    }
    returnLotteryName = (productId) => {
        return getProductName(productId);
    };

    onPaymentMethodSelect(event) {
        let method = parseInt(event.target.value);
        let shipping_fee = 0;
        if (method === 0) {
            shipping_fee = this.config.EXPRESS_DELIVERY_FEE;
        }
        if (method === 1) {
            shipping_fee = this.config.NORMAL_DELIVERY_FEE;
        }
        this.setState({payment_method: method, shipping_fee: shipping_fee}, () => {
            this.calculateTotal();
        })
    };

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleCity(event) {
        let city = event.target.value;
        if (city !== this.state.cityId) {
            this.setState({cityId: city, districtId: ""});
            apiAddress.getListDistrictByCity(city, (err, result) => {
                if (err) {
                    // TODO
                } else {
                    this.district = result;
                    this.toggle("re_render");
                }
            });
        }
    };

    handleRefresh = () => {
        apiMyAccount.getUserInfoSomeKey((err, result) => {
            if (err) {
                // TODO
            } else {
                this.props.userChange(result);
            }
        });
    };

    handlePayment() {
        if (this.state.isLoading)
            return;
        // Nếu loại thanh toán là khác giữ hộ
        if (this.state.payment_method !== 2) {
            if (utils.isEmpty(this.state.address) || utils.isEmpty(this.state.cityId) || utils.isEmpty(this.state.districtId)) {
                utils.alert("Vui lòng điền địa chỉ giao hàng");
                return;
            }
        }
        let totalPrice = this.state.shipping_fee + this.state.total;
        let ticketArray = [];

        this.state.cart_data.forEach(dt => {
            if (dt.productOptionKenoTypeId) {
                dt.value.forEach(dat => {
                    let numGen = this.genNumberKenoType(dat.number, dt.productOptionId, dt.productOptionKenoTypeId);
                    let value = [];
                    for (let i = 0; i < numGen.length; i++) {
                        // let value = [{number: numGen[i], price: dat.price}];
                        value.push({number: numGen[i], price: dat.price});
                        if (((i + 1) % 6) === 0 && i !== 0) {
                            ticketArray.push({
                                kenoType:dt.productOptionKenoTypeId,
                                productId: dt.productId,
                                productOptionId: dt.productOptionId,
                                value: value,
                                termArray: dt.termArray,
                            });
                            value = [];
                        }
                    }
                    if (value.length) {
                        ticketArray.push({
                            kenoType:dt.productOptionKenoTypeId,
                            productId: dt.productId,
                            productOptionId: dt.productOptionId,
                            value: value,
                            termArray: dt.termArray,
                        });
                    }
                });
            } else {
                ticketArray.push({
                    productId: dt.productId,
                    productOptionId: dt.productOptionId,
                    value: dt.value,
                    termArray: dt.termArray,
                });
            }
            // console.log(totalPrice);
            // totalPrice += dt.totalPrice;
        });

        if (totalPrice > this.props.user.availableBalance) {
            // window.alert("Tài khoản không đủ để thanh toán", "Nạp tiền ngay?", [{text: "Nạp ngay", onPress: () => this.props.navigation.navigate("Recharge")}])
            // utils.alert("Tài khoản không đủ để thanh toán");
            utils.confirm("Tài khoản không đủ để thanh toán.\r\nNạp tiền ngay?", (check) => {
                window.location.replace("/recharge");
            });
            return;
        }

        let data = {
            "ticketArray": ticketArray,
            "totalPrice": totalPrice,
            "shipment": {
                "type": this.state.payment_method,
                "fullName": this.state.fullName,
                "phoneNumber": this.state.phoneNumber,
                "idCardNumber": this.state.idCardNumber,
                "cityId": this.state.cityId,
                "districtId": this.state.districtId,
                "address": this.state.address
            },
            orderCode:this.state.orderCodeCreate
        };
        this.setState({isLoading: true});

        apiOrder.createOrder(data, (err, result) => {
            if (err) {
                this.setState({isLoading: false});
            } else {
                this.handleRefresh();
                this.setState({isLoading: false});
                this.props.removeAllCart();
                this.toggle("isShow_paymentOk")
            }
        })

    }

    genNumberKenoType(number, productOptionId, productOptionKenoTypeId) {
        if (number.length === 0) return [];
        let result = [];
        let arrCombination = utils.generateCombination(productOptionId - 70, productOptionKenoTypeId);

        arrCombination.forEach(combination => {
            let rs = [];
            for (let i = 1; i <= productOptionId - 70; i++) {
                rs.push(number[combination[i] - 1]);
            }
            result.push(rs);
        });
        return result;
    };

    render() {
        return (
            <div className="bootstrap-reset h-100 cart-page">
                <ModalImageLucky
                    isOpen={this.state.isShow_paymentOk}
                    toggle={() => {
                        this.toggle("isOpen_paymentOk");
                        this.props.history.push("/shopping-history?status=2#1")
                    }}
                />
                
                <div className="h-100 overflow-hidden d-flex flex-column">
                <div style={{color:'red',padding:10}}>
                    Lưu ý: Quý khách kiểm tra kỹ và đảm bảo thông tin sau là chính xác. Thông tin này sẽ được ghi vào mặt sau của vé khi vé được in ra.
                </div>
                    <Row className="h-100 overflow-auto">
                        <Col className="p-3">
                            <Card className="border m-0 mb-3">
                                <CardHeader className="border-bottom font-weight-bold text-center">
                                    Thông tin khách hàng
                                </CardHeader>
                                <CardBody>
                                    <div className="my-1">
                                        <User fill="red" size={20} color="red" className={"mr-2"}/>
                                        <span>{this.state.fullName}</span>
                                    </div>
                                    <div className="my-1">
                                        <Phone size={20} color="red" fill="red" className={"mr-2"}/>
                                        <span>{this.state.phoneNumber}</span>
                                    </div>
                                    <div className="my-1">
                                        <CreditCard size={20} color="white" fill="red" className={"mr-2"}/>
                                        <span>{this.state.idCardNumber}</span>
                                    </div>
                                </CardBody>
                            </Card>
                            <Row className="mb-3">
                                <Col>
                                    <Label className="font-weight-bold">Hình thức nhận vé:</Label>
                                    {/* eslint eqeqeq: "off" */}
                                    <CustomInput
                                        id={2}
                                        value={2}
                                        type={"radio"}
                                        name="payment_method"
                                        label="Đại lý giữ hộ"
                                        checked={this.state.payment_method == 2}
                                        onChange={this.onPaymentMethodSelect.bind(this)}
                                    />
                                    {/* <CustomInput
                                        id={1}
                                        value={1}
                                        type={"radio"}
                                        name="payment_method"
                                        label="Ship vé"
                                        checked={this.state.payment_method == 1}
                                        onChange={this.onPaymentMethodSelect.bind(this)}
                                    />
                                    <CustomInput
                                        id={0}
                                        value={0}
                                        type={"radio"}
                                        name="payment_method"
                                        label="Ship nhanh"
                                        checked={this.state.payment_method == 0}
                                        onChange={this.onPaymentMethodSelect.bind(this)}
                                    /> */}
                                </Col>
                            </Row>
                            <Collapse isOpen={this.state.payment_method !== 2}>
                                <Card className="border m-0 mb-3">
                                    <CardHeader className="border-bottom font-weight-bold text-center">Địa chỉ giao
                                                                                                       hàng</CardHeader>
                                    <CardBody>
                                        <div>
                                            <Label>Địa chỉ</Label>
                                            <Input
                                                type="text"
                                                name="address"
                                                value={this.state.address}
                                                onChange={this.handleChange.bind(this)}
                                            />
                                        </div>
                                        <div>
                                            <Label>Tỉnh / TP</Label>
                                            <Input
                                                type="select"
                                                name="cityId"
                                                onChange={this.handleCity.bind(this)}
                                                value={this.state.cityId}
                                            >
                                                {
                                                    this.city.map(({name, value}) => (
                                                        <option key={value} value={value}>
                                                            {name}
                                                        </option>
                                                    ))
                                                }
                                            </Input>
                                        </div>
                                        <div>
                                            <Label>Quận / Huyện</Label>
                                            <Input
                                                type="select"
                                                name="districtId"
                                                value={this.state.districtId}
                                                onChange={this.handleChange.bind(this)}
                                            >
                                                {
                                                    this.district.map(({name, value}) => (
                                                        <option key={value} value={value}>
                                                            {name}
                                                        </option>
                                                    ))
                                                }
                                            </Input>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Collapse>
                            <Row>
                                <Col>
                                    <Row className="font-weight-bold border-bottom">
                                        <Col className="text-left">
                                            <span>Sản phẩm</span>
                                        </Col>
                                        <Col className="text-right">
                                            <span>Tổng cộng</span>
                                        </Col>
                                    </Row>
                                    {
                                        this.state.cart_data.map((data, ind) => (
                                            <Row key={ind} className="py-1">
                                                <Col className="text-left">
                                                    <span>{this.returnLotteryName(data.productId)}</span>
                                                </Col>
                                                <Col className="text-right">
                                                    <span>{(data.totalPrice * 1000).getMoneyFormat()}&nbsp;₫</span>
                                                </Col>
                                            </Row>
                                        ))
                                    }
                                    <Row className="border-bottom py-1">
                                        <Col>
                                            <span>Phí giữ hộ</span>
                                        </Col>
                                        <Col className="text-right">
                                            <span>{(this.state.servicePrice * 1000).getMoneyFormat()}&nbsp;₫</span>
                                        </Col>
                                    </Row>
                                    <Row className="border-bottom border-top py-1">
                                        <Col>
                                            <span>Tạm tính</span>
                                        </Col>
                                        <Col className="text-right">
                                            <span>{(this.state.total * 1000).getMoneyFormat()}&nbsp;₫</span>
                                        </Col>
                                    </Row>
                                    {/* <Row className="border-bottom py-1">
                                        <Col>
                                            <span>Phí giao hàng</span>
                                        </Col>
                                        <Col className="text-right">
                                            <span>{(this.state.shipping_fee * 1000).getMoneyFormat()}&nbsp;đ</span>
                                        </Col>
                                    </Row> */}
                                    <Row className="font-weight-bold py-1">
                                        <Col>
                                            <span>Tổng cộng</span>
                                        </Col>
                                        <Col className="text-right">
                                            <span
                                                className="text-danger"
                                            >{((this.state.shipping_fee + this.state.total) * 1000).getMoneyFormat()}&nbsp;₫</span>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                    <Row>
                        <ButtonLoading
                            loading={this.state.isLoading}
                            className="w-100 py-2 border-radius-none"
                            color="warning"
                            onClick={this.handlePayment.bind(this)}
                            title="Thanh toán"
                        />
                    </Row>
                </div>
               
                   
                   
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        cartData: state.cartData.cart,
        user: state.user
    }
}

export default withRouter(connect(mapStateToProps, {removeAllCart, userChange})(Payment));
