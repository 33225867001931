// Sidebar
export const SIDEBAR_VISIBILITY_TOGGLE = "SIDEBAR_VISIBILITY_TOGGLE";
export const SIDEBAR_VISIBILITY_SHOW = "SIDEBAR_VISIBILITY_SHOW";
export const SIDEBAR_VISIBILITY_HIDE = "SIDEBAR_VISIBILITY_HIDE";
export const SIDEBAR_STICKY_TOGGLE = "SIDEBAR_STICKY_TOGGLE";
export const SIDEBAR_STICKY_ENABLE = "SIDEBAR_STICKY_ENABLE";
export const SIDEBAR_STICKY_DISABLE = "SIDEBAR_STICKY_DISABLE";

// Layout
export const LAYOUT_BOXED_TOGGLE = "LAYOUT_BOXED_TOGGLE";
export const LAYOUT_BOXED_ENABLE = "LAYOUT_BOXED_ENABLE";
export const LAYOUT_BOXED_DISABLE = "LAYOUT_BOXED_DISABLE";

// Theme
export const THEME_TOGGLE = "THEME_TOGGLE";

// User
export const USER_CLEAR = "USER_CLEAR";
export const USER_CHANGE = "USER_CHANGE";

//
export const LOTTERY_CHANGE_NUMBER = "lotteryChangeNumber";
export const LOTTERY_CHANGE_PRICE = "lotteryChangePrice";
export const LOTTERY_SET = "lotterySet";
export const LOTTERY_CHANGE_TERM_ARRAY = "lotteryChangeTermArray";
export const LOTTERY_CHANGE = "lotteryChange";

// Cart
export const ADD_TO_CART = 'addToCart';
export const REMOVE_FROM_CART = 'removeFromCart';
export const REMOVE_ALL_CART = 'removeAllCart';
export const REMOVE_KENO_CART = 'removeKenoCart';
export const REMOVE_OTHER_CART = 'removeOtherCart';
export const LOAD_CART = 'loadCart';

// CONFIG
export const CHANGE_LOTTERY_CONFIG = "changeLotteryConfig";
export const CHANGE_TC = 'ChangeTC';
export const CHANGE_POSiTION ='changePosition'